import { put } from '@/services/http'
// import { getUserIdentification, getUserToken } from '@/services/authService'

export function requestResetPassword(username) {
  return put(`/api/v1/support/request-reset-password?username=${username}`, {}, {
    headers: {
    }
  })
}
export function verifyActionKey(key) {
  return put(`/api/v1/support/verify-action-key?key=${key}`, {}, {
    headers: {
    }
  })
}
export function resetPassword(key, data) {
  return put(`/api/v1/support/reset-password?key=${key}`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}