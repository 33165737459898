import { post, put } from '@/services/http'
import { getUserToken } from '@/services/authService'

export function login(username, password) {
  return post('/api/v1/contacts/login', {username, password}, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function logout() {
  return put(`/api/v1/contacts/logout`, {}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}