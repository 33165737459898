<template>
  <v-container
  v-if="isDataReady"
  fluid>
    <v-row v-if="$parent.isRole(['SUPERVISOR', 'CLIENT'])" dense no-gutters>
        <v-col>
          <p class="process-repair-tab-section-subtitle text-center">{{$t('pages.repair.partsAreInServiceOptional', {'0': $parent.isRole(['SUPERVISOR']) ? 'the' : 'your'})}}</p>
        </v-col>
    </v-row>
    <v-row dense no-gutters>
        <v-col v-if="$parent.isRole(['ADMIN', 'SERVICE'])">
          <p class="process-repair-tab-section-title">{{$t('pages.repair.partsInService')}}</p>
        </v-col>
        <v-col v-else-if="$parent.isRole(['SUPERVISOR', 'CLIENT'])">
          <p class="process-repair-tab-section-title">{{$t('pages.repair.partsInfo')}}</p>
        </v-col>
    </v-row>
    <v-form
    ref="form"
    v-model="isFormValid">
      <v-row dense no-gutters>
        <v-col>
          <main-data-table
          :headers="getHeaders"
          :items="newData.repairParts"
          number-pages
          :page.sync="page"
          :size="-1"
          :items-per-page-options="[-1]"
          :total-elements="-1"
          hide-default-footer>
          
            <template v-slot:[`item.imageUrl`]="{ item }">
              <previewable-image
              v-model="item.imageUrl"
              height="65"
              width="50"
              class="py-2"/>
            </template>
            <template v-slot:[`item.repairStatus`]="{ item }">
                <text-field-autocomplete 
                v-if="!disabled && !isDiagnosticStatus(item, ['IT_IS_WORKING']) && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.repairStatusObj"
                normal
                itemText="value"
                itemValue="key"
                :label="$t('textFields.selectStatus')"
                :items="repairStatuses"
                :disabled="disabled || item.diagnosticInfo.status === 'IT_IS_WORKING' || item.repairStatus === 'REJECTED'"
                required/>
                <span v-else>
                    {{item.repairStatusObj ? $t(item.repairStatusObj.value) : ''}}
                </span>
            </template>
            <template 
            v-if="$parent.isRole(['ADMIN', 'SERVICE'])"
            v-slot:[`item.repairComment`]="{ item }">
                <div class="py-2">
                  <text-area
                  v-if="!disabled && !isDiagnosticStatus(item, ['IT_IS_WORKING']) && isRepairApprovalStatus(item, ['APPROVED'])"
                  v-model="item.repairComment"
                  solo-normal
                  :disabled="item.diagnosticInfo.status === 'IT_IS_WORKING'"
                  no-resize/> 
                  <span v-else>
                    {{item.repairComment}}
                  </span>
                </div>
            </template>
            <template 
            v-if="$parent.isRole(['ADMIN', 'SERVICE'])"
            v-slot:[`item.action`]="{ item }">
                <div 
                v-if="isRepairApprovalStatus(item, ['APPROVED']) && !isDiagnosticStatus(item, ['IT_IS_WORKING'])"
                class="d-flex justify-start">  
                  <primary-btn
                  @click="setChangedDialogItemsBySave(item)"
                  :loading="isLoading"
                  :label="$t('btns.save')"
                  color="#4285f4"
                  class="mx-2"
                  :disabled="disabled || !item.repairStatusObj"
                  small-text
                  :width="50"
                  :height="30"/>
                </div>
            </template>
          </main-data-table>
        </v-col>
      </v-row>
    </v-form>
    <v-row 
    v-if="$parent.isRole(['ADMIN', 'SERVICE'])"
    dense class="mt-5">
      <v-col
      cols="11">
        <p class="text-center"
        style="font-weight: bold">
          {{$t('common.pressContinueWhenReady')}}
        </p>
        <process-repair-quick-btns
        :loading.sync="isLoading"
        :config="getQuickBtnsConfig"
        on-success-repair-status-message="dialogs.messages.success.toTransitBackStep"/>
      </v-col>
      <v-col
      cols="1">
        <primary-btn
        @click="setChangedDialogItemsBySaveAll"
        :loading="isLoading"
        :width="120"
        :height="50"
        color="#4285f4"
        :disabled="disabled || !isFormValid"
        :label="$t('btns.saveAll')"/>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="newData.repairParts.length === 0"
    :loading.sync="isLoading"
    class="px-4 mt-4"
    name="export.title.repairTab"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import TextArea from '@/components/text-fields/TextArea'
import DateUtil from '@/utils/DateUtil'
import MainDataTable from '@/components/common/MainDataTable'
import PreviewableImage from '@/components/common/PreviewableImage'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import { SERVICE_STATUSES, getObjectServiceStatusByKey, getServiceStatusByKey } from '@/utils/statusesUtil'
import { updateAllPartsServiceData } from '@/repositories/repairRepo'
import TextFieldAutocomplete from '@/components/text-fields/TextFieldAutocomplete'
import { execHttpRequestThrowable } from '@/services/http'
import { cloneObj, compareArrays, copyObj } from '@/utils/StringUtil'
import { exportRepairTabXlsx } from '@/repositories/exportRepo'

export default {
  components: {
    ProcessRepairQuickBtns,
    TextArea,
    TextFieldAutocomplete,
    MainDataTable,
    PreviewableImage,
    PrimaryBtn,
    ExportXlsxRow
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      isFormValid: false,
      headers: [
        {
          text: 'headers.image',
          align: 'start',
          sortable: true,
          value: 'imageUrl'
        },{
          text: 'headers.category',
          align: 'start',
          sortable: true,
          value: 'category'
        },{
          text: 'headers.partName',
          align: 'start',
          sortable: true,
          value: 'partName'
        }, {
          text: 'headers.serialNumber',
          align: 'start',
          sortable: true,
          value: 'serialNumber'
        }, {
          text: 'headers.serviceTag',
          align: 'start',
          sortable: true,
          value: 'serviceTag'
        },{
          text: 'headers.repairStatus',
          align: 'start',
          sortable: true,
          value: 'repairStatus'
        }, {
          text: 'headers.repairComment',
          align: 'start',
          sortable: true,
          value: 'repairComment'
        },{
          text: 'headers.action',
          align: 'start',
          sortable: true,
          value: 'action'
        }
      ],
      repairStatuses: SERVICE_STATUSES,
      page: 1,
      isLoading: false,
      newData: null,
      isDataReady: false
    }
  },
  computed: {
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.$parent.isRole(['SUPERVISOR', 'SUSTAINABILITY_MANAGER'])) {
          if (h.value === 'repairComment' ||
          h.value === 'action') continue
        }

        if (this.$parent.isRole(['CLIENT'])) {
            if (h.value === 'action') continue
        }

        headers.push(h)
      }

      return headers
    },
    repairProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'AllInProgress',
          events: {
            'click': () => this.setChangedDialogItemsByStatus('IN_PROGRESS')
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'AllReplaced',
          events: {
            'click': () => this.setChangedDialogItemsByStatus('REPLACED')
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'AllRepaired',
          events: {
            'click': () => this.setChangedDialogItemsByStatus('REPAIRED')
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'AllNonRepairable',
          events: {
            'click': () => this.setChangedDialogItemsByStatus('NON_REPAIRABLE')
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    },
    getExporter() {
      return async () => await exportRepairTabXlsx(this.$route.params.id)
    }
  },
  methods: {
    async tryToUpdateAllPartsServiceData(parts) {
      const data = parts.map(p => {
        return {
          repairPartId: p.id,
          status: p.repairStatusObj.key,
          comment: p.repairComment
        }
      })
      const messages = {
        _200: this.$t(parts.length > 0 ? 'dialogs.messages.success.repairStatusesSavedSuccessfully' : 'dialogs.messages.success.repairStatusSavedSuccessfully')
      }
      const r = await execHttpRequestThrowable(async () => await updateAllPartsServiceData(this.$route.params.id, data),
      messages,
      v => this.isLoading = v)
      const ids = parts.map(p => p.id)
      //Update server parts
      this.repairProp.repairParts
        .filter(p => ids.includes(p.id))
        .forEach((p, i) => {
          cloneObj(parts[i], p)
        })
      this.newData.repairParts
        .filter(p => ids.includes(p.id))
        .forEach((p, i) => {
          cloneObj(parts[i], p)
        })

      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItemsByStatus(s) {
      const filteredParts = this.newData.repairParts.filter(rp => rp.diagnosticInfo.status !== 'IT_IS_WORKING' && rp.repairStatus !== 'REJECTED')
      const newParts = filteredParts.map(p => {
        const newObject = copyObj(p)
        newObject.repairStatusObj = getObjectServiceStatusByKey(s)
        return newObject
      })
      const ids = newParts.map(p => p.id)
      const oldParts = this.repairProp.repairParts.filter(rp => ids.includes(rp.id))

      this.setChangedDialogItems(oldParts, newParts, async () => await this.tryToUpdateAllPartsServiceData(newParts))
    },
    setChangedDialogItemsBySave(p) {
      const old = this.repairProp.repairParts.filter(rp => rp.id === p.id)[0]
      const _new = p
      this.setChangedDialogItems([old], [_new], async () => await this.tryToUpdateAllPartsServiceData([_new]))
    },
    setChangedDialogItemsBySaveAll() {
      const newParts = this.newData.repairParts.filter(rp => rp.diagnosticInfo.status !== 'IT_IS_WORKING' && rp.repairStatus !== 'REJECTED')
      const ids = newParts.map(p => p.id)
      const oldParts = this.repairProp.repairParts.filter(rp => ids.includes(rp.id))
      this.setChangedDialogItems(oldParts, newParts, async () => await this.tryToUpdateAllPartsServiceData(newParts))
    },
    setChangedDialogItems(oldParts, newParts, action) {
      const props = [
        {
          field: 'headers.repairStatus',
          prop: 'repairStatusObj.key',
          getter: getServiceStatusByKey
        }, {
          field: 'headers.repairComment',
          prop: 'repairComment'
        }
      ]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareArrays(oldParts, newParts, props, false),
        action,
        true
      )
    },
    getDateNewYorkFormat(date) {
      return DateUtil.getDateNewYorkFormat(date)
    },
    getRepairStatus(k) {
      return getServiceStatusByKey(k)
    },
    isDiagnosticStatus(i, s) {
      return s.includes(i.diagnosticInfo.status)
    },
    isInitialStatus(i, s) {
      return s.includes(i.initialApproval)
    },
    isRepairApprovalStatus(i, s) {
      return s.includes(i.repairApproval)
    }
  },
  mounted() {
    this.newData = {
      isAllowedToContinue: this.repairProp.isAllowedToContinue,
      repairParts: this.repairProp.repairParts
        .filter(rp => rp.initialApproval === 'APPROVED')
        .map((rp, i) => {
          rp.repairStatusObj = getObjectServiceStatusByKey(rp.repairStatus)
          rp.rowNumber = i + 1
          return copyObj(rp)
        })
    }
    this.isDataReady = true
  }
}
</script>
<style>

</style>