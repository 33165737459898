<template>
  <v-text-field
  v-model="model"
  :rules="rules"
  class="sts-web-system-label"
  :label="$t(label)"
  :type="show ? 'text' : 'password'"
  @keypress.enter="$emit('enter')"
  :append-icon="`mdi-eye${show ? '-off' : ''}`"
  @click:append="show = !show">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-lock
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    required: Boolean,
    label: {
      type: String,
      default: () => {
        return 'textFields.yourPassword'
      }
    },
    value: String,
    validate: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    },

  },
  data() {
    return {
      rules: [
        (v) => {
          if (!this.validate) return true
          if (this.required) {
            return !!v || this.$t('invalidValue.required')
          }
          return true
        },
        (v) => {
          if (!this.validate || !v) return true
          return v.length > 7 || this.$t('invalidValue.invalidPassword')
        },
        (v) => {
          if (!this.validate || !v) return true
          return new RegExp(/.*\d.*/).test(v) || this.$t('invalidValue.invalidPassword')
        },
        (v) => {
          if (!this.validate || !v) return true
          return new RegExp(/.*[!@#$%^&*()=_+[\]{};:'"\\|/.,<>`~-].*/).test(v) || this.$t('invalidValue.invalidPassword')
        },
        (v) => {
          if (!this.validate || !v) return true
          return new RegExp(/.*[A-ZА-Я].*/).test(v) || this.$t('invalidValue.invalidPassword')
        }
      ],
      show: false
    }
  },
  methods: {

  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>