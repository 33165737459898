<template>
  <v-text-field
  v-model="model"
  :rules="rules"
  class="sts-web-system-label"
  :label="$t(label)"
  type="password"
  :disabled="disabled"
  @keypress.enter="$emit('enter')">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-lock
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    required: Boolean,
    label: {
      type: String,
      default: () => {
        return 'textFields.confirmPassword'
      }
    },
    value: String,
    disabled: Boolean,
    password: {
      type: String,
      required: true
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => {
          if (this.required) {
            return v !== null && v !== undefined || this.$t('invalidValue.required')
          }
          return true
        },
        (v) => { 
          if (v && v.length > 0) {
            return v === this.password || this.$t('invalidValue.passwordNotMatched')
          }
          return true
        }
      ]
    }
  },
  methods: {
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>