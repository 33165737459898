var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pt-8",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"d-flex align-end",attrs:{"cols":"1"}},[_c('p',{staticClass:"text-input-label"},[_vm._v(" "+_vm._s(_vm.$t('other.show'))+" ")]),_c('v-select',{staticClass:"text-input text-input-border-color mb-3 mx-2 select-medium",staticStyle:{"width":"78px"},attrs:{"items":_vm.itemsPerPageOptions,"hide-details":"auto","solo":"","flat":"","dense":""},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}}),_c('p',{staticClass:"text-input-label"},[_vm._v(" "+_vm._s(_vm.$t('other.entries'))+" ")])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticStyle:{"position":"relative","top":"-12px"}},[_c('label',{staticClass:"text-input-label"},[_vm._v(" "+_vm._s(_vm.$t('textFields.searchLabel'))+" ")]),_c('v-text-field',{staticClass:"text-input text-input-border-color select-medium",attrs:{"hide-details":"auto","solo":"","flat":"","dense":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchParts.apply(null, arguments)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)])],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{attrs:{"number-pages":"","headers":_vm.headers,"items":_vm.items,"page":_vm.page,"size":_vm.size,"loading":_vm.isLoading,"onUpdateSize":_vm.tryToFetchPartsByCriteria,"total-elements":_vm.totalElements,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('previewable-image',{staticClass:"py-2",attrs:{"height":"50","width":"100","contain":""},model:{value:(item.imageUrl),callback:function ($$v) {_vm.$set(item, "imageUrl", $$v)},expression:"item.imageUrl"}})]}},{key:"item.serviceCategories",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('text-field-autocomplete-chips',{attrs:{"label":_vm.$t('textFields.serviceCategories'),"items":_vm.allServiceCategories,"item-value":"id","item-text":"key"},model:{value:(item.serviceCategories),callback:function ($$v) {_vm.$set(item, "serviceCategories", $$v)},expression:"item.serviceCategories"}}),_c('primary-btn',{staticClass:"ml-4",attrs:{"label":_vm.$t('btns.save'),"color":"#4285f4","loading":_vm.isLoading,"width":40,"height":30},on:{"click":function($event){return _vm.tryToUpdateServiceCategoriesByPartId(item)}}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"detail-btn rounded-0",attrs:{"width":"70","height":"30"},on:{"click":function($event){return _vm.routeTo(("edit/part/" + (item.id)))}}},[_vm._v(" "+_vm._s(_vm.$t('btns.edit'))+" ")])],1)]}},{key:"item.update-state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('text-field-switch',{attrs:{"loading":_vm.isLoading},on:{"change":function($event){return _vm.tryToUpdatePartState(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"dense":"","no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"d-flex align-end",attrs:{"cols":"3"}},[_c('p',{staticClass:"text-input-label"},[_vm._v(" "+_vm._s(_vm.$t('other.showing'))+" "+_vm._s(((_vm.page - 1) * _vm.size) + 1)+" "+_vm._s(_vm.$t('other.to'))+" "+_vm._s(((_vm.page - 1) * _vm.size) + _vm.size > _vm.totalElements ? _vm.totalElements : ((_vm.page - 1) * _vm.size) + _vm.size)+" "+_vm._s(_vm.$t('other.of'))+" "+_vm._s(_vm.totalElements)+" "+_vm._s(_vm.$t('other.entries'))+" ")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{ref:"pag",attrs:{"length":_vm.pageLength,"disabled":_vm.isLoading,"color":"#4285f4"},on:{"input":_vm.tryToFetchPartsByCriteria},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('export-xlsx-row',{staticClass:"px-4 mt-4",attrs:{"width":150,"height":50,"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"name":"export.title.parts","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }