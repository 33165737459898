<template>
  <v-dialog
  v-model="model"
  fullscreen
  persistent
  @click:outside="closeDialog">
    <v-card
    style="display: grid; grid-template-rows: 6.7% auto;"
    color="white">
      <div class="confirm-action-dialog-header confirm-action-dialog-header-title d-flex justify-space-between align-center px-5">
        <span>{{$t('dialogs.titles.clientAction')}}</span>
      </div>

      <div 
      class="d-flex justify-center align-center"
      style="height: 100%;">
        <div>
          <div class="d-flex justify-space-around align-center">
            <outlined-extended-btn
            v-for="(b, i) in btnsF" 
            :key="i"
            :label="$t(b.label)"
            color="#33b5e5"
            :loading="isLoading"
            :width="400"
            :height="90"
            :icon="b.icon"
            :sub-label="$t(b.subLabel)"
            :tip="b.tip"
            inner-class="ma-4"
            @click="b.action"/>
          </div>
          <div class="d-flex justify-center align-center">
            <outlined-extended-btn
            v-for="(b, i) in btnsS" 
            :key="i"
            :label="$t(b.label)"
            color="#33b5e5"
            :loading="isLoading"
            :width="400"
            :height="90"
            :icon="b.icon"
            :sub-label="$t(b.subLabel)"
            class="my-1"
            :tip="b.tip"
            inner-class="ma-4"
            @click="b.action"/>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import OutlinedExtendedBtn from '@/components/btns/OutlinedExtendedBtn'
import { routeFromRoot } from '@/services/routeService'
export default {
  components: {
    OutlinedExtendedBtn
  },
  props: {
    value: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  data() {
    return {
      isLoading: false,
      btnsF: [{
          label: 'common.clientAction.home.title',
          subLabel: 'common.clientAction.home.subtitle',
          tip: 'common.clientAction.home.title',
          icon: {
            value: 'mdi-view-dashboard-outline', 
            alignment: 'left',
            size: 40
          },
          action: () => {
            this.routeTo('active-repairs')
            this.closeDialog()
          }
        }, {
          label: 'common.clientAction.newRepairRequest.title',
          subLabel: 'common.clientAction.newRepairRequest.subtitle',
          tip: 'common.clientAction.newRepairRequest.title',
          icon: {
            value: 'mdi-account-hard-hat', 
            alignment: 'left',
            size: 40
          },
          action: () => {
            this.routeTo('new-repair')
          }
        }
      ],
      btnsS: [{
          label: 'common.clientAction.sparePartsRequest.title',
          subLabel: 'common.clientAction.sparePartsRequest.subtitle',
          tip: 'common.clientAction.sparePartsRequest.title',
          icon: {
            value: 'mdi-chat-question', 
            alignment: 'left',
            size: 40
          },
          action: () => {
            this.routeTo('part-request/new')
          }
        }, {
          label: 'common.clientAction.remoteSupportRequest.title',
          subLabel: 'common.clientAction.remoteSupportRequest.subtitle',
          tip: 'common.clientAction.remoteSupportRequest.title',
          icon: {
            value: 'mdi-face-agent', 
            alignment: 'left',
            size: 40
          },
          action: () => {
            this.routeTo('remote-support/new')
          }
        }
      ]
    }
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  methods: {
    closeDialog() {
      this.model = false
    },
    routeTo(l) {
      routeFromRoot(l)
    }
  }
}
</script>
<style>
.confirm-action-dialog-header {
  width: 100%;
  height: 70px;
  background-color: #33b5e5;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.144);
  border-radius: 0px!important;
}
.confirm-action-dialog-header-title {
  font-family: 'Titillium Web', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.confirm-action-dialog-content {
  font-family: 'Titillium Web', sans-serif;
  color: black;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
</style>