<template>
  <div>
    <v-row dense>
      <v-col>
        <p class="process-repair-tab-section-title">{{$t(title)}}</p>
        <p class="font-weight-bold">{{$t(subtitle)}}</p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <text-field-value 
        :label="$t('textFields.requestId')" 
        v-model="dataProp.requestId" 
        disabled/>
      </v-col>
      <v-col>
        <v-row 
        v-if="isRole(['ADMIN'])"
        dense>
          <v-col cols="8">
            <text-field-autocomplete
            :label="$t('textFields.status')" 
            itemText="value"
            itemValue="key"
            v-model="entityStatus"
            :items="statuses"/>
          </v-col>
          <v-col cols="3" class="d-flex justify-center align-center">
            <primary-btn
            @click="tryToUpdateEntityStatusByAdmin"
            color="#ff3547"
            :label="$t('textFields.changeStatus')" 
            :width="100"
            :loading="loading"
            :disabled="!entityStatus"
            small-text
            :height="30"/>
          </v-col>
        </v-row>
        <text-field-value
        v-else-if="!isRole(['ADMIN']) && entityStatus"
        :label="$t('textFields.status')"
        :value="$t(entityStatus.value)" 
        disabled/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <text-field-value 
        :label="$t('textFields.creationDateTime', {'0': getTimeZoneInNumber(new Date())})"
        :value="getDateNewYorkFormat(dataProp.createdAt)"
        disabled/>
      </v-col>
      <v-col>
        <text-field-value 
        :label="$t('textFields.lastChangeDateTime', {'0': getTimeZoneInNumber(new Date())})"
        :value="getDateNewYorkFormat(dataProp.updatedAt)"
        disabled/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextFieldAutocomplete from '@/components/text-fields/TextFieldAutocomplete'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import DateUtil from '@/utils/DateUtil'
import { getUserRole } from '@/services/authService'
import { execHttpRequestThrowable } from '@/services/http'
export default {
  components: {
    TextFieldValue,
    TextFieldAutocomplete,
    PrimaryBtn
  },
  inject: [
    'tryToFetchData',
    'updateEntityStatus'
  ],
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: () => {
        return ''
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    data: {
      type: Object,
      required: true
    },
    statusesFetcher: {
      type: Function,
      required: true
    },
    statusGetter: {
      type: Function,
      required: true
    }
  },
  computed: {
    dataProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getRole() {
      return getUserRole()
    }
  },
  data() {
    return {
      statuses: this.statusesFetcher(),
      entityStatus: null
    }
  },
  watch: {
    'data.status': {
      handler(v) {
        if (!this.entityStatus) {
          this.entityStatus = this.statusGetter(v.key)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async tryToUpdateEntityStatusByAdmin() {
      await execHttpRequestThrowable(async () => await this.updateEntityStatus(this.$route.params.id, this.entityStatus.key), {}, v => this.loadingProp = v)
      this.tryToFetchData()
      this.$emit('status-updated-on-admin')
    },
    getDateNewYorkFormat(date) {
      return DateUtil.getDateNewYorkFormat(date)
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    },
    isRole(roles) {
      return roles.includes(this.getRole)
    }
  }
}
</script>
<style>

</style>