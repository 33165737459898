///////////////////////////////
/////Process Repair
///////////////////////////////
import DetailsTabRepair from '@/components/process-repair-tabs/DetailsTab'
import ShippingDetailsTabRepair from '@/components/process-repair-tabs/ShippingDetailsTab'
import WaitingForPickUpTabRepair from '@/components/process-repair-tabs/WaitingForPickUpTab'
import InTransitTab from '@/components/process-repair-tabs/InTransitTab'
import WaitingDiagnosticsTab from '@/components/process-repair-tabs/WaitingDiagnosticsTab'
import DiagnosticsTab from '@/components/process-repair-tabs/DiagnosticsTab'
import RepairApprovalTab from '@/components/process-repair-tabs/RepairApprovalTab'
import RepairTab from '@/components/process-repair-tabs/RepairTab'
import TransitBackTab from '@/components/process-repair-tabs/TransitBackTab'
import CompletedTabRepair from '@/components/process-repair-tabs/CompletedTab'
import HistoryTabRepair from '@/components/process-repair-tabs/HistoryTab'


///////////////////////////////
/////Process Part Request
///////////////////////////////
import DetailsTabPartRequest from '@/components/process-part-request-tabs/DetailsTab'
import ShippingDetailsTabPartRequest from '@/components/process-part-request-tabs/ShippingDetailsTab'
import TransportArrangementTabPartRequest from '@/components/process-part-request-tabs/TransportArrangementTab'
import WaitingConfirmationTabPartRequest from '@/components/process-part-request-tabs/WaitingConfirmationTab'
import HistoryTabPartRequest from '@/components/process-part-request-tabs/HistoryTab'

///////////////////////////////
/////Process Remote Support
///////////////////////////////
import DetailsTabRemoteSupport from '@/components/process-remote-support-tabs/DetailsTab'
import ServiceReviewTabRemoteSupport from '@/components/process-remote-support-tabs/ServiceReview'
import WaitingCompletionTabRemoteSupport from '@/components/process-remote-support-tabs/WaitingCompletionTab'
import HistoryTabRemoteSupport from '@/components/process-remote-support-tabs/HistoryTab'

const comps = {
  'Repair_DetailsTab': DetailsTabRepair,
  'Repair_ShippingDetailsTab': ShippingDetailsTabRepair,
  'Repair_WaitingForPickUpTab': WaitingForPickUpTabRepair,
  'Repair_InTransitTab': InTransitTab,
  'Repair_WaitingDiagnosticsTab': WaitingDiagnosticsTab,
  'Repair_DiagnosticsTab': DiagnosticsTab,
  'Repair_RepairApprovalTab': RepairApprovalTab,
  'Repair_RepairTab': RepairTab,
  'Repair_TransitBackTab': TransitBackTab,
  'Repair_CompletedTab': CompletedTabRepair,
  'Repair_HistoryTab': HistoryTabRepair,

  'PartRequest_DetailsTab': DetailsTabPartRequest,
  'PartRequest_ShippingDetailsTab': ShippingDetailsTabPartRequest,
  'PartRequest_TransportArrangementTab': TransportArrangementTabPartRequest,
  'PartRequest_WaitingConfirmationTab': WaitingConfirmationTabPartRequest,
  'PartRequest_HistoryTab': HistoryTabPartRequest,

  'RemoteSupport_DetailsTab': DetailsTabRemoteSupport,
  'RemoteSupport_ServiceReviewTab': ServiceReviewTabRemoteSupport,
  'RemoteSupport_WaitingCompletionTab': WaitingCompletionTabRemoteSupport,
  'RemoteSupport_HistoryTab': HistoryTabRemoteSupport
}


export default {
  install: (Vue) => {
    for (const [k, v] of Object.entries(comps)) {
      Vue.component(k, v)
    }
  }
}