<template>
  <v-card
  width="460"
  min-height="765"
  class="rounded-0 primary-background-color"
  elevation="5">
    <v-row
    class="px-8"
    dense
    no-gutters>
      <v-col class="pt-8">
        <p class="card-title">{{$t('pages.contact.addressDetailsTitle')}}</p>
      </v-col>
    </v-row>
    <v-form
    v-if="isDataReady"
    ref="form"
    v-model="isFormValidated">
      <v-row dense no-gutters class="px-8 mt-2">
        <v-col>
          <text-field-value 
          :label="$t('textFields.companyName')"
          v-model="newAddressData.companyName"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-value 
          :label="$t('textFields.country')"
          v-model="newAddressData.country"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-value 
          :label="$t('textFields.state')"
          v-model="newAddressData.province"/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-value 
          :label="$t('textFields.city')"
          v-model="newAddressData.city"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-value 
          :label="$t('textFields.postalCode')"
          v-model="newAddressData.postalCode"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-value 
          :label="$t('textFields.street')"
          v-model="newAddressData.street"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-value 
          :label="$t('textFields.building')"
          v-model="newAddressData.building"/>
        </v-col>
      </v-row>
    </v-form>
    <v-row dense no-gutters class="px-8 pt-10">
      <v-col class="d-flex justify-center align-center">
        <primary-btn
        :label="$t('btns.save')"
        color="#4285f4"
        @click="setChangedDialogItems"
        :loading="isLoading"
        :disabled="!isFormValidated"
        :width="100"
        :height="40"/>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { editAddress } from '@/repositories/addressRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getUserIdentification } from '@/services/authService'
import { cloneObj, compareObjects, copyObj } from '@/utils/StringUtil'
export default {
  components: {
    TextFieldValue,
    PrimaryBtn
  },
  props: {
    address: Object
  },
  computed: {
    addressProp: {
      get: function() {
        return this.$props.address
      },
      set: function(v) {
        this.$emit('update:address', v)
      }
    }
  },
  data() {
    return {
      isFormValidated: false,
      isLoading: false,
      newAddressData: null,
      isDataReady: false
    }
  },
  methods: {
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.addressDataSavedSuccessfully'
      }
      await execHttpRequestThrowable(
        async () => await editAddress(this.$route.params.username !== undefined ? this.$route.params.username : getUserIdentification() , this.newAddressData),
        messages,
        v => this.isLoading = v)
      cloneObj(this.newAddressData, this.addressProp)
    },
    setChangedDialogItems() {
      const old = this.addressProp
      const _new = this.newAddressData

      const props = [
        {
          field: 'textFields.companyName',
          prop: 'companyName'
        }, {
          field: 'textFields.country',
          prop: 'country'
        }, {
          field: 'textFields.state',
          prop: 'province'
        }, {
          field: 'textFields.city',
          prop: 'city'
        }, {
          field: 'textFields.postalCode',
          prop: 'postalCode'
        }, {
          field: 'textFields.street',
          prop: 'street'
        }, {
          field: 'textFields.building',
          prop: 'building'
        }, 
      ]
      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    }
  },
  mounted() {
    this.newAddressData = copyObj(this.addressProp)
    this.isDataReady = true
  }
}
</script>
<style>

</style>