var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('p',{staticClass:"process-repair-tab-section-title"},[_vm._v(_vm._s(_vm.$t('pages.remoteSupport.repairStatusChangeHistory')))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('main-data-table',{attrs:{"number-pages":"","headers":_vm.headers,"items":_vm.items,"page":_vm.page,"size":_vm.size,"loading":_vm.isLoading,"onUpdateSize":_vm.tryToFetchHistoryByCriteria,"onPerviousBtnClick":_vm.tryToFetchHistoryByCriteria,"onNextBtnClick":_vm.tryToFetchHistoryByCriteria,"total-elements":_vm.totalElements},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.createdAt))+" ")])]}},{key:"item.oldStatus",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatus(item.oldStatus).value))+" ")])]}},{key:"item.newStatus",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatus(item.newStatus).value))+" ")])]}}],null,true)})],1)],1),_c('export-xlsx-row',{staticClass:"px-4 mt-4",attrs:{"width":150,"height":50,"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"name":"export.title.remoteSupportHistoryTab","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }