<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-img
        v-ripple
        v-bind="attrs"
        v-on="on"
        class="previewable-img"
        :src="srcModel"
        :alt="alt"
        contain
        :height="height"
        :width="width"
        @click="preview"/>
      </template>
      <span>
        {{$t('toolTips.preview')}}
      </span>
    </v-tooltip>
    
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    height: {
      type: [Number, String],
      default: () => {
        return 50
      }
    },
    width: {
      type: [Number, String],
      default: () => {
        return 100
      }
    },
    alt: {
      type: String,
      default: () => {
        return null
      }
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    srcModel: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  methods: {
    preview() {
      this.$imagePreviewDialog.open(this.srcModel)
    }
  }
}
</script>
<style>
.previewable-img {
  cursor: pointer;
}
</style>