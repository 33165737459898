import Vue from 'vue'
import { fetchNumberOfActionRequiredRepair } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'

class ActionRequiredPlugin {
  allNumber
  partsNumber
  constructor() {
    this.allNumber = 0
    this.partsNumber = 0
  }
  setAll(n) {
    this.allNumber = n
  }
  getAll() {
    return this.allNumber
  }
  setParts(n) {
    this.partsNumber = n
  }
  getParts() {
    return this.partsNumber
  }
  async fetch(categories) {
    const r = await execHttpRequestThrowable(async () => await fetchNumberOfActionRequiredRepair(categories))
    if (!categories || categories.length > 1) {
      this.setAll(r.response.data)
    }
    else if (categories.length === 1 && categories[0] === 'PARTS') {
      this.setParts(r.response.data)
    }
    //TODO:
    // else if (categories.length === 1 && categories[0] === 'REPAIR') {
    //   this.setRepair(r.response.data)
    // }
  }
  startFetching(categories) {
    return setInterval(async () => await this.fetch(categories), 10000)
  }
  clearAll() {
    this.allNumber = 0
    this.partsNumber = 0
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$actionRequired = Vue.observable(new ActionRequiredPlugin())
  }
}