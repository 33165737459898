<template>
  <v-card
  width="460"
  min-height="765"
  class="rounded-0 primary-background-color pb-3"
  elevation="5">
    {{isPathChanged}}
    <v-row
    class="px-8"
    dense
    no-gutters>
      <v-col class="pt-8">
        <div
        v-if="isCreating"
        class="new-user-title-box d-flex justify-center align-center">
          <v-icon dark class="mr-2">
            mdi-lead-pencil
          </v-icon>
          <span>{{$t('pages.contact.newContact')}}</span>
        </div>
        <p v-else class="card-title">{{$t('pages.contact.accountDetailsTitle')}}</p>
      </v-col>
    </v-row>
    <v-form 
    ref="form"
    v-model="isFormValidated">
      <v-row
      dense no-gutters
      :class="`px-8 mt-${isCreating ? 6 : 2}`">
        <v-col>
          <text-field-username 
          v-model="newContactData.username"
          required/>
        </v-col>
      </v-row>
      <passwords-rows
      v-if="isCreating"
      :password.sync="newContactData.password"
      :confirm-password.sync="newContactData.confirmPassword"
      required/>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-first-name 
          v-model="newContactData.firstName"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-last-name 
          v-model="newContactData.lastName"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-phone 
          v-model="newContactData.phone"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-email
          :label="$t('textFields.email')" 
          v-model="newContactData.email"
          required/>
        </v-col>
      </v-row>
      <backup-emails-rows
      v-if="isEditing"
      :emails="getBackupEmails"
      @add="addNewBackUpEmail"
      @remove="removeNewBackUpEmail"/>
      <v-row v-if="isCreating" dense no-gutters class="px-8 mt-4">
        <v-col>
          <text-field-autocomplete 
          v-model="newContactData.role"
          required
          item-text="value"
          item-value="key"
          :label="$t('textFields.selectRole')"
          :items="roles"/>
        </v-col>
      </v-row>
      <passwords-rows
      v-if="isEditing"
      :password.sync="newContactData.password"
      :confirm-password.sync="newContactData.confirmPassword"
      show-confirm-on-password-input/>
      <v-row dense no-gutters class="px-8 pt-10">
        <v-col class="d-flex justify-center align-center">
          <primary-btn
          :disabled="!isFormValidated"
          :label="$t(`btns.${isCreating ? 'create' : 'save'}`)"
          @click="isCreating ? tryToCreateContact() : setChangedDialogItems()"
          color="#4285f4"
          :loading="isLoading"
          :width="isCreating ? 120 : 100"
          :height="isCreating ? 50 : 40"/>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import TextFieldUsername from '@/components/text-fields/TextFieldUsername'
import TextFieldFirstName from '@/components/text-fields/TextFieldFirstName'
import TextFieldLastName from '@/components/text-fields/TextFieldLastName'
import TextFieldPhone from '@/components/text-fields/TextFieldPhone'
import TextFieldEmail from '@/components/text-fields/TextFieldEmail'
import TextFieldAutocomplete from '@/components/text-fields/TextFieldAutocomplete'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import PasswordsRows from '@/components/common/PasswordsRows'
import BackupEmailsRows from '@/components/common/BackupEmailsRows'
import { createContact, editContact } from '@/repositories/contactRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { routeFromRoot } from '@/services/routeService'
import { getUserIdentification, setUserToken } from '@/services/authService'
import { DynamicNotifier } from '@/utils/DynamicNotifier'
import { ROLES } from '@/utils/rolesUtil'
import { copyObj, compareObjects, cloneObj } from '@/utils/StringUtil'
export default {
  components: {
    TextFieldUsername,
    TextFieldFirstName,
    TextFieldLastName,
    TextFieldPhone,
    TextFieldEmail,
    TextFieldAutocomplete,
    PrimaryBtn,
    PasswordsRows,
    BackupEmailsRows
  },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  computed: {
    contactProp: {
      get: function() {
        return this.$props.contact
      },
      set: function(v) {
        this.$emit('update:contact', v)
      }
    },
    isPathChanged() {
      if (this.$route.path !== this.currentPath) this.setData()

      return ''
    },
    isCreating() {
      return this.$route.path.includes('/create/')
    },
    isEditing() {
      return !this.isCreating
    },
    getBackupEmails() {
      return this.newContactData.backupEmails
    }
  },
  data() {
    return {
      isFormValidated: false,
      newContactData: null,
      roles: ROLES,

      isLoading: false,
      currentPath: null
    }
  },
  methods: {
    async tryToSaveData() {
      const data = {
        adminUsername: getUserIdentification(),
        oldUsername: this.contactProp.username,
        newUsername: this.newContactData.username,
        password: this.newContactData.password,
        firstName: this.newContactData.firstName,
        lastName: this.newContactData.lastName,
        phone: this.newContactData.phone,
        email: this.newContactData.email,
        backupEmails: this.getBackupEmails
      }
      const messages = {
        onSuccessProps: { '0': this.newContactData.username },
        _200: 'dialogs.messages.success.userDataSavedSuccessfully',
        _409: 'dialogs.messages.failed.usernameAlreadyExists'
      }
      const r = await execHttpRequestThrowable(async () => await editContact(data), messages, v => this.isLoading = v)
      if (this.contactProp.username === getUserIdentification()) {
        setUserToken(r.response.data)
      }

      const isUsernameUpdated = this.newContactData.username !== this.contactProp.username

      this.newContactData.password = ''
      this.newContactData.confirmPassword = ''
      cloneObj(this.newContactData, this.contactProp)

      if (isUsernameUpdated && this.$route.path.includes('edit/contact'))
        routeFromRoot(`edit/contact/${this.newContactData.username}`)

      if (this.$route.path.includes('profile')) {
        this.$contactHolder.set({
          id: this.contactProp.id,
          firstName: this.contactProp.firstName,
          lastName: this.contactProp.lastName,
          phone: this.contactProp.phone,
          email: this.contactProp.email,
          backupEmails: this.contactProp.backupEmails
        })
      }

      DynamicNotifier.notify('contactEvent')
      DynamicNotifier.notify('usernameEvent')
    },
    async tryToCreateContact() {
      const data = {
        adminUsername: getUserIdentification(),
        username: this.newContactData.username,
        password: this.newContactData.password,
        firstName: this.newContactData.firstName,
        lastName: this.newContactData.lastName,
        phone: this.newContactData.phone,
        email: this.newContactData.email,
        permission: this.newContactData.role.key
      }
      const messages = {
        failedTitle: this.$t('dialogs.titles.failed'),
        _409: 'dialogs.messages.failed.usernameAlreadyExists'
      }
      await execHttpRequestThrowable(async () => await createContact(data), messages, v => this.isLoading = v)
      routeFromRoot('contacts')
    },
    setChangedDialogItems() {
      const old = this.contactProp
      const _new = this.newContactData

      const props = [
        {
          field: 'textFields.yourUsername',
          prop: 'username'
        }, {
          field: 'textFields.firstName',
          prop: 'firstName'
        }, {
          field: 'textFields.lastName',
          prop: 'lastName'
        }, {
          field: 'textFields.phone',
          prop: 'phone'
        }, {
          field: 'textFields.email',
          prop: 'email'
        }, {
          field: {
            value: 'textFields.backupEmailNo',
            params: null
          },
          prop: 'backupEmails[].value'
        }, {
          field: 'textFields.userPassword',
          prop: 'password',
          getter: () => this.$t('textFields.changingPassword')
        },
      ]
      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    },
    addNewBackUpEmail() {
      this.getBackupEmails.push({
        id: null,
        value: ''
      })
    },
    removeNewBackUpEmail(e) {
      this.newContactData.backupEmails = this.getBackupEmails.filter(be => be !== e)
    },
    setData() {
      this.currentPath = this.$route.path
      this.contactProp.password = ''
      this.contactProp.confirmPassword = ''
      this.newContactData = copyObj(this.contactProp)

      if (this.$refs.form) this.$refs.form.resetValidation()
    }
  },
  mounted() {
    if (this.isEditing) this.$refs.form.validate()
  }
}
</script>
<style>

</style>