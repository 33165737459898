import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { router } from './plugins/vue-router'
import { i18n } from './plugins/vue-i18n'
import changesDialog from '@/plugins/dialogs/changes-dialog'
import successDialog from '@/plugins/dialogs/success-dialog'
import failedDialog from '@/plugins/dialogs/failed-dialog'
import confirmActionDialog from '@/plugins/dialogs/confirm-action-dialog'
import auth from '@/plugins/auth'
import contactHolder from '@/plugins/contact-holder'
import snackBar from '@/plugins/dialogs/snack-bar'
import actionRequired from '@/plugins/action-required'
import imagePreviewDialog from '@/plugins/dialogs/image-preview'
import feedback from '@/plugins/feedback'
import loadProcessWorkflowTabs from '@/plugins/components/process-tabs/loadProcessWorkflowTabs'

Vue.config.productionTip = false
Vue
 .use(changesDialog)
 .use(successDialog)
 .use(failedDialog)
 .use(imagePreviewDialog)
 .use(confirmActionDialog)
 .use(auth)
 .use(contactHolder)
 .use(snackBar)
 .use(feedback)
 .use(actionRequired)
 .use(loadProcessWorkflowTabs)

export const app = new Vue({
  i18n,
  vuetify,
  router,
  render: h => h(App)
})
app.$mount('#app')