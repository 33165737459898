var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.$changesDialog.model),callback:function ($$v) {_vm.$set(_vm.$changesDialog, "model", $$v)},expression:"$changesDialog.model"}},[_c('v-card',{attrs:{"color":"white","width":"100%"}},[_c('div',{staticClass:"changes-dialog-header changes-dialog-header-title d-flex justify-space-between align-center px-5"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.$changesDialog.title)))]),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-row',{staticClass:"px-5 pt-5",attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.getItems,"page":_vm.page,"size":_vm.size,"itemsPerPageOptions":_vm.itemsPerPageOptions,"hide-default-footer":"","height":300},scopedSlots:_vm._u([(_vm.isRow)?{key:"header.number",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}:null,{key:"header.field",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.newValue",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},(_vm.isRow)?{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])]}}:null,{key:"item.field",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getField(item)))+" ")])]}},{key:"item.newValue",fn:function(ref){
var item = ref.item;
return [(!_vm.isImage(item.newValue))?_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.newValue))+" ")]):_c('div',{staticClass:"d-flex justify-center px-4 my-4"},[_c('img',{staticClass:"img-thumbnail",attrs:{"src":item.newValue}})])]}}],null,true)})],1)],1),_c('div',{staticClass:"mt-5",staticStyle:{"width":"100%","height":"1px","background-color":"rgba(0,0,0,.2)"}}),_c('div',{staticClass:"d-flex justify-center align-center pa-4"},[_c('outlined-btn',{staticClass:"mx-2",attrs:{"label":_vm.$t('btns.close'),"color":"#33b5e5","loading":_vm.isLoading,"width":100,"height":45},on:{"click":_vm.closeDialog}}),_c('primary-btn',{staticClass:"mx-2",attrs:{"label":_vm.$t('btns.applyChanges'),"color":"#33b5e5","loading":_vm.isLoading,"width":150,"height":45,"disabled":_vm.getItems.length === 0},on:{"click":_vm.execAction}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }