<template>
  <v-container 
  v-if="isDataFetched"
  fluid>
    <changes-dialog
    v-if="changesDialog.model"
    v-model="changesDialog.model"
    :action="changesDialog.action"
    :header-title="changesDialog.headerTitle"
    :items="changesDialog.items"/>
    <v-row dense>
        <v-col>
          <v-card 
          v-if="$parent.isRole(['ADMIN', 'SERVICE'])"
          width="100%"
          
          elevation="4"
          class="pa-5">
      <!--  <v-row v-if="newData.courierArrivingTime" dense>
              <v-col cols="6">
                <h5 class="sts-web-system-label">{{$t('textFields.courierArrivingTime')}}</h5>
                <h2 class="sts-web-system-label">{{newData.courierArrivingTime}}</h2>
              </v-col>
            </v-row>
            <v-row v-if="newData.note && newData.note.length > 0" dense class="mt-10">
              <v-col cols="2">
                <text-area
                :label="$t('textFields.note') + ' ' + $t('textFields.optional')"
                v-model="newData.note"
                outlined-normal
                readonly
                />
              </v-col>
            </v-row> -->
            <v-row dense>
              <v-col class="d-flex justify-center align-center">
                <process-repair-quick-btns 
                :loading.sync="isLoading"
                on-success-repair-status-message="dialogs.messages.success.toWaitingDiagnosticsStep"
                :config="getQuickBtnsConfig"/>
              </v-col>
            </v-row>
          </v-card>
          <v-card 
          v-else-if="$parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          class="px-6 py-6"
          width="100%"
          elevation="4">
            <p 
            class="in-transit-tab-header">
              {{$t('pages.repair.expectDeliveryConfirmation', {'0': getDateNewYorkFormat(newData.estimateDeliveryDate)})}}
            </p>

       <!-- <div v-if="getRole === 'CLIENT' || getRole === 'SUSTAINABILITY_MANAGER'">
              <v-row dense class="mt-10">
                <v-col cols="2">
                  <text-field-time
                  v-model="newData.courierArrivingTime"
                  :readonly="getRole === 'SUSTAINABILITY_MANAGER'"
                  clear
                  normal
                  :disabled="disabled"
                  :label="$t('textFields.courierArrivingTime') + ' ' + $t('textFields.optional')"
                  />
                </v-col>
              </v-row>
              <v-row dense class="mt-10">
                <v-col cols="2">
                  <text-area
                  :label="$t('textFields.note') + ' ' + $t('textFields.optional')"
                  v-model="newData.note"
                  outlined-normal
                  :disabled="disabled"
                  :readonly="getRole === 'SUSTAINABILITY_MANAGER'"
                  />
                </v-col>
              </v-row>
              <v-row v-if="getRole === 'CLIENT'" dense>
                <v-col cols="2" class="d-flex justify-center">
                  <primary-btn 
                  @click="setChangedDialogItems"
                  :width="100"
                  :height="45"
                  :loading="isLoading"
                  :disabled="disabled"
                  color="#4285f4"
                  :label="$t('btns.save')"/>
                </v-col>
              </v-row>
            </div> -->
          </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DateUtil from '@/utils/DateUtil'
// import TextFieldTime from '@/components/text-fields/TextFieldTime'
// import TextArea from '@/components/text-fields/TextArea'
import ChangesDialog from '@/components/dialogs/ChangesDialog'
//import { updateTransitInfoData } from '@/repositories/repairRepo'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
export default {
  components: {
    // TextFieldTime,
    // TextArea,
    ChangesDialog,
    ProcessRepairQuickBtns
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  computed: {
    repairProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'ShipmentArrived',
          props: {
            'disabled': this.disabled
          },
          events: 'AsContinue'
        }
      ]
    }
  },
  data() {
    return {
      isLoading: false,
      newData: {
        estimateDeliveryDate: null,
        courierArrivingTime: null,
        note: ''
      },
      //Deprecated - replace with $changesDialog
      changesDialog: {
        model: false,
        action: null,
        items: [],
        headerTitle: 'dialogs.titles.changes'
      },
      isDataFetched: false
    }
  },
  methods: {
    // async tryToUpdateTransitInfo() {
    //   try {
    //     this.isLoading = true
    //     let newDate = null
    //     if (this.newData.courierArrivingTime) {
    //       const time = this.newData.courierArrivingTime.split(':')
    //       newDate = DateUtil.getByHoursAndMinutes(time[0], time[1])
    //     }
    //     const data = {
    //       courierArrivingTime: newDate,
    //       note: this.newData.note
    //     }
    //     const r = await execHttpRequest(async () => await updateTransitInfoData(this.$route.params.id, data))
    //     if (r.isSuccessed) {
    //       this.repairProp.transitInfo.courierArrivingTime = newDate
    //       this.repairProp.transitInfo.note = String(this.newData.note)
    //       this.openSuccessDialog(this.$t('dialogs.messages.success.dataSavedSuccessfully'))
    //     }
    //     else this.openFailedDialog(r.message)
    //   }
    //   finally {
    //     this.isLoading = false
    //   }
    // },
    // setChangedDialogItems() {
    //   this.changesDialog.items = []
    //   this.changesDialog.action = null

    //   const ot = this.repairProp.transitInfo
    //   const nt = this.newData

    //   const oDate = new Date(ot.courierArrivingTime)

    //   let nDate = new Date()
    //   if (this.newData.courierArrivingTime) {
    //     const time = this.newData.courierArrivingTime.split(':')
    //     nDate = DateUtil.getByHoursAndMinutes(time[0], time[1])
    //   }

    //   if (!StringUtil.isEqual(oDate.getHours(), nDate.getHours()) || !StringUtil.isEqual(oDate.getMinutes(), nDate.getMinutes())) this.changesDialog.items.push({
    //     // oldValue: ot.courierArrivingTime ? DateUtil.getTime(oDate) : '',
    //     field: 'textFields.courierArrivingTime',
    //     newValue: nt.courierArrivingTime ? DateUtil.getTime(nDate) : ''
    //   })
    //   if (!StringUtil.isEqual(ot.note, nt.note)) this.changesDialog.items.push({
    //     // oldValue: ot.note,
    //     field: 'textFields.note',
    //     newValue: nt.note
    //   })

    //   this.changesDialog.action = this.tryToUpdateTransitInfo
    //   this.changesDialog.model = true
    // },
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    openSuccessDialog(message) {
      this.$successDialog.open(null, message, null)
    },
    openFailedDialog(message) {
      this.$failedDialog.open(null, message, null)
    }
  },
  mounted() {
    this.newData = {
      estimateDeliveryDate: this.repairProp.pickUpShippingDetails.estimateDeliveryDate.replace(/T.+/, '').concat('T00:00:00.000+00:00'),
      courierArrivingTime: this.repairProp.transitInfo.courierArrivingTime ? DateUtil.getTime(this.repairProp.transitInfo.courierArrivingTime) : null,
      note: this.repairProp.transitInfo.note ? String(this.repairProp.transitInfo.note) : ''
    }
    this.isDataFetched = true
  }
}
</script>
<style>
.in-transit-tab-header {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1px;
  color: black;
}
</style>