<template>
  <v-text-field
  v-if="!normal"
  v-model="model"
  :rules="rules"
  :class="`sts-web-system-label ${disabled ? 'v-input--is-readonly' : ''}`"
  :label="label"
  :disabled="disabled"
  :readonly="readonly">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
  <v-text-field
  v-else
  v-model="model"
  :rules="rules"
  :class="`sts-web-system-label normal-text-field ${disabled ? 'v-input--is-readonly' : ''}`"
  :label="label"
  :disabled="disabled"
  flat
  solo
  hide-details="auto"
  dense
  style="height: 50px;"
  :readonly="readonly">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    required: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    label: String,
    value: String,
    normal: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true,
        (v) => new RegExp(/^\d{1,}\/\d{1,}\/\d{1,}$/).test(v) || this.$t('invalidValue.invalidDimensions')
      ]
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
.sts-web-system-label  > div.v-input__control > div.v-input__slot > div.v-text-field__slot > input {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: black;
}
.normal-text-field.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 38px;
  border: 1px solid #49505742;
}
</style>