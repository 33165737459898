<template>
  <v-app class="primary-background-color">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  computed: {
    
  },
  data() {
    return {
    }
  },
  mounted() {
    this.$auth.process()
  },
  destroyed() {
  }
};
</script>
<style>
.primary-background-color {
  background-color: #fbfbfb !important;
}
html::-webkit-scrollbar {
  width: 10px;
}
html::-webkit-scrollbar-thumb {
  background: radial-gradient(#ffffff60, #d4d4d4ea);
  border-radius: 0px;
}
html::-webkit-scrollbar-track {
  background: #ffffff;
}
</style>
