<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        number-pages
        :headers="getHeaders"
        :items="items"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :onUpdateSize="tryToFetchRepairs"
        :onPerviousBtnClick="tryToFetchRepairs"
        :onNextBtnClick="tryToFetchRepairs"
        :total-elements="totalElements"
        class="pa-4">
          <template v-slot:[`header.id`]="{ header }">
              {{$t(header.text)}}  
          </template>
          <template v-slot:[`header.status`]="{ header }">
              {{$t(header.text)}}
          </template>
          <template v-slot:[`header.createdAt`]="{ header }">
              {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.completedAt`]="{ header }">
              {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.clientUsername`]="{ header }">
              {{$t(header.text)}}
          </template>
          <template v-slot:[`header.details`]="{ header }">
              {{$t(header.text)}}
          </template>


          <template v-slot:[`item.id`]="{ item }">
              <span>
                {{item.number}}
              </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
              <span>
                {{$t(getStatus(item.status))}}
              </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.createdAt)}}
              </span>
          </template>
          <template v-slot:[`item.completedAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.updatedAt)}}
              </span>
          </template>
          <template 
          v-if="getRole !== 'CLIENT'"
          v-slot:[`item.clientUsername`]="{ item }">
              <span>
                {{item.clientUsername}}
              </span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
              <route-btn
              :href="`process-repair/${item.id}`"
              label="btns.viewDetails"
              :width="120"
              :height="30"/>
          </template>
        </main-data-table>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4"
    name="export.title.closedRepairs"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import { fetchClosedRepair } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getRepairStatusByKey } from '@/utils/statusesUtil'
import DateUtil from '@/utils/DateUtil'
import RouteBtn from '@/components/btns/RouteBtn'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import MainDataTable from '@/components/common/MainDataTable'
import { exportClosedRepairsXlsx } from '@/repositories/exportRepo'
import { routeFromRoot } from '@/services/routeService'
import { getUserRole, isLanguageEn } from '@/services/authService'
export default {
  components: {
    MainDataTable,
    ExportXlsxRow,
    RouteBtn
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.requestId',
          align: 'start',
          sortable: true,
          value: 'id'
        },{
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        }, {
          text: 'headers.creationDateTime',
          align: 'start',
          sortable: true,
          value: 'createdAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.completionDate',
          align: 'start',
          sortable: true,
          value: 'completedAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.clientName',
          align: 'start',
          sortable: true,
          value: 'clientUsername'
        }, {
          text: 'headers.details',
          align: 'start',
          sortable: true,
          value: 'details'
        }
      ],
      items: [],
      totalElements: 0,
      page: 1,
      size: 10,
      isLoading: false
    }
  },
  computed: {
    isEN() {
      return isLanguageEn()
    },
    getRole() {
      return getUserRole()
    },
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.getRole === 'CLIENT' && h.value === 'clientUsername') continue

        headers.push(h)
      }

      return headers
    },
    getExporter() {
      return exportClosedRepairsXlsx
    }
  },
  methods: {
    async tryToFetchRepairs() {
      this.items = []
      const r = await execHttpRequestThrowable(async () => await fetchClosedRepair(this.size, this.page - 1),
      {},
      v => this.isLoading = v)
      this.pageable = r.response.data
      this.totalElements = this.pageable.totalElements
      this.items = this.pageable.content
    },
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    getStatus(k) {
      return getRepairStatusByKey(k)
    },
    routeTo(link) {
      routeFromRoot(link)
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  async mounted() {
    await this.tryToFetchRepairs()

    this.$feedback.fill("Closed Repairs Page", this.$route.path)
  }
}
</script>
<style>

.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
</style>