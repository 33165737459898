<template>
  <div>
    {{isPathChanged}}
    <v-row
    v-if="isDataReady"
    dense
    no-gutters>
      <v-col :class="`d-flex justify-center align-center ${dialog ? '' : 'pt-10'}`">
        <v-card
        width="460"
        class="rounded-0 primary-background-color"
        elevation="5">
          <v-row
          dense
          no-gutters>
            <v-col>
              <div class="new-part-title-box d-flex justify-center align-center">
                <span v-if="isCreating">{{$t('pages.part.addPart')}}</span>
                <span v-else>{{$t('pages.part.editPart')}}</span>
              </div>
            </v-col>
          </v-row>
          <v-form 
          ref="form"
          v-model="isFormValidated">
            <v-row
            v-if="!dialog"
            dense
            no-gutters
            class="px-4">
              <v-col
              class="d-flex justify-end">
                <text-field-switch
                v-model="newPart.enabled"
                label="textFields.enabledDisabled"
                required/>
              </v-col>
            </v-row>
           <v-row dense no-gutters class="px-4 mt-6">
            <v-col>
              <text-field-autocomplete 
              v-model="newPart.category"
              required
              item-text="text"
              item-value="value"
              :label="$t('textFields.selectCategory')"
              :items="categories"/>
            </v-col>
          </v-row>
          <v-row dense no-gutters class="px-4 my-4">
            <v-col>
              <text-field-value
              v-model="newPart.number"
              :label="$t('textFields.partNumber')"
              required/>
            </v-col>
          </v-row>
          <v-row dense no-gutters class="px-4 my-4">
            <v-col>
              <text-field-value
              v-model="newPart.model"
              :label="$t('textFields.partModel')"
              required/>
            </v-col>
          </v-row>
          <v-row dense no-gutters class="px-4 my-4">
            <v-col>
              <text-field-value
              v-model="newPart.manufacturer"
              :label="$t('textFields.manufacturer')"
              required/>
            </v-col>
          </v-row>
          <v-row dense no-gutters class="px-4 my-4 mt-6">
            <v-col>
              <text-area
              v-model="newPart.description"
              :label="$t('textFields.shortDescription')"
              no-resize
              required/>
            </v-col>
          </v-row>
          <v-row dense no-gutters class="px-4 my-4">
            <v-col>
              <text-field-file
              id="input-part-file"
              :label="$t('textFields.uploadYourFile')"
              accept="image/*"
              :value="files"
              @input="setFiles"/>
            </v-col>
          </v-row>
          <div class="d-flex justify-center px-4 my-4">
            <img
            v-if="(files && files.length === 1) || selectedFileURL"
            :src="selectedFileURL"
            class="img-thumbnail"
            />
          </div>
          <v-row dense no-gutters class="px-4 my-4 mt-6">
            <v-col>
              <text-field-autocomplete-chips
              v-model="newPart.serviceCategories"
              :label="$t('textFields.serviceCategories')"
              :items="allServiceCategories"
              item-value="id"
              item-text="key"
              />
            </v-col>
          </v-row>
          <div 
          class="my-8"
          style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
         </v-form>
          <v-row 
          dense 
          no-gutters 
          justify="center"
          class="px-4 pb-4">
            <v-col 
            v-if="!isCreating" 
            cols="6"
            class="d-flex justify-center align-center">
              <primary-btn
              @click="routeTo('parts')"
              color="#00bcd4"
              :label="$t('btns.backToList')"
              :width="180"
              :height="50"/>
            </v-col>
            <v-col 
            :cols="isCreating ? 12 : 3"
            class="d-flex justify-center align-center">
              <primary-btn
              @click="isCreating ? tryToSaveData() : setChangedDialogItems()"
              :disabled="!isFormValidated || (isCreating && files.length === 0)"
              :label="$t('btns.save')"
              :loading="isLoading"
              color="#4285f4"
              :width="100"
              :height="50"/>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TextFieldAutocomplete from '@/components/text-fields/TextFieldAutocomplete'
import TextFieldSwitch from '@/components/text-fields/TextFieldSwitch'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextArea from '@/components/text-fields/TextArea'
import TextFieldFile from '@/components/text-fields/TextFieldFile'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import TextFieldAutocompleteChips from '@/components/text-fields/TextFieldAutocompleteChips'
import { routeFromRoot } from '@/services/routeService'
import { fetchPart, savePart } from '@/repositories/partRepo'
import { execHttpRequestThrowable, get } from '@/services/http'
import { getUserIdentification } from '@/services/authService'
import { fetchServiceCategoriesByKeys } from '@/repositories/serviceCategoryRepo'
import { cloneObj, compareObjects, copyObj } from '@/utils/StringUtil'

export default {
  components: {
    TextFieldAutocomplete,
    TextFieldAutocompleteChips,
    TextFieldSwitch,
    TextFieldValue,
    TextArea,
    TextFieldFile,
    PrimaryBtn
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      isFormValidated: false,
      categories: [
        {
          text: 'Handgeld',
          value: 'HANDHELD'
        }, {
          text: 'RPM',
          value: 'RPM'
        }, {
          text: 'Utility/Comm',
          value: 'UTILITY_COMM'
        }, {
          text: 'Other',
          value: 'OTHER'
        }
      ],
      allServiceCategories: [],
      files: [],
      oldFiles: [],
      selectedFileURL: null,
      currentPath: '',
      isCreating: false,
      oldPart: null,
      newPart: null,
      isLoading: false,
      isDataReady: false
    }
  },
  computed: {
    isPathChanged() {
      if (this.currentPath !== this.$route.path) this.setCurrentPath()
      return ''
    }
  },
  methods: {
    async tryToSaveData() {
      const data = new FormData()
      data.append("id", this.$route.params.id !== undefined ? this.$route.params.id : -1)
      data.append("multipartFile", this.files[0])
      data.append("category", this.newPart.category.value)
      data.append("number", this.newPart.number)
      data.append("model", this.newPart.model)
      data.append("manufacturer", this.newPart.manufacturer)
      data.append("description", this.newPart.description)
      data.append("enabled", this.dialog ? true : this.newPart.enabled)
      data.append("serviceCategories", JSON.stringify(this.newPart.serviceCategories))
      
      if (this.dialog) {
        data.append('identificator', getUserIdentification())
      }
      
      const r = await execHttpRequestThrowable(async () => await savePart(data), {}, v => this.isLoading = v)
      if (this.$route.params.id !== undefined) {
        cloneObj(this.newPart, this.oldPart)
        this.oldFiles = [...this.files]
        this.openSuccessDialog(this.$t('dialogs.messages.success.partSavedSuccessfully'))
      }
      else {
        if (this.dialog) this.$emit('part', r.response.data)
        else routeFromRoot('parts')
      }
    },
    async tryToFetchServiceCategoriesByKeys() {
      const r = await execHttpRequestThrowable(async () => await fetchServiceCategoriesByKeys(['REPAIR', 'PARTS']))
      this.allServiceCategories = r.response.data
    },
    async setChangedDialogItems() {
      const old = this.oldPart
      const _new = this.newPart

      const props = [
        {
          field: 'textFields.selectCategory',
          prop: 'category.text'
        }, {
          field: 'textFields.partNumber',
          prop: 'number'
        }, {
          field: 'textFields.partModel',
          prop: 'model'
        }, {
          field: 'textFields.manufacturer',
          prop: 'manufacturer'
        }, {
          field: 'textFields.shortDescription',
          prop: 'description'
        }, {
          field: 'textFields.enabledDisabled',
          prop: 'enabled',
          getter: (s) => this.$t(`textFields.${s ? 'enabled' : 'disabled'}`)
        }, {
          field: {
            value: 'textFields.serviceCategories',
            params: null
          },
          prop: 'serviceCategories[].key'
        }, 
        //TODO: think about this!
        // {
        //   field: 'textFields.uploadYourFile',
        //   prop: 'imageUrl',
        //   overridenCondition: async () => await FileUtil.isByteArraysEqual(this.files[0], this.oldFiles[0])
        // },
      ]
      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    },
    getCategoryByKey(k) {
      const c = this.categories.filter(c => c.value === k)

      if (c.length > 0) return {
        text: c[0].text,
        value: c[0].value
      }
      else return null
    },
    async tryToFetchPart() {
      const r = await execHttpRequestThrowable(async () => await fetchPart(this.$route.params.id), {}, v => this.isLoading = v)
      const p = r.response.data
      p.serviceCategories = JSON.parse(p.serviceCategories)  
      this.oldPart = copyObj(p)
      this.oldPart.category = this.getCategoryByKey(p.category)
      this.newPart = copyObj(this.oldPart)

      const fr = new FileReader()

      fr.onload = () => {
        const file = new File([fr.result], p.imageName, {
          type: p.imageMime
        })

        this.oldPart.imageUrl = window.URL.createObjectURL(file)

        this.oldFiles = [file]
        this.setFiles([file])
      }

      const response = await get(p.imageUrl, {
        responseType: 'blob'
      })
      fr.readAsArrayBuffer(response.data)
    },
    setFiles(fArray) {
      this.files = [...fArray]

      this.selectedFileURL = window.URL.createObjectURL(this.files[0])
      this.newPart.imageUrl = String(this.selectedFileURL)
    },
    async setCurrentPath() {
      this.currentPath = this.$route.path
      if (this.$route.params.id) {
        this.isCreating = false
        await this.tryToFetchPart()
      }
      else {
        this.isCreating = true
        this.newPart = {
          id: null,
          image: '',
          category: null,
          number: '',
          model: '',
          manufacturer: '',
          description: '',
          serviceCategories: [],
          enabled: this.dialog
        }
        this.files = [],
        this.oldFiles = [],
        this.selectedFileURL = null
        if (this.$refs.form) this.$refs.form.resetValidation()
      }

      this.isDataReady = true
    },
    openSuccessDialog(m) {
      this.$successDialog.open(null, m, null)
    },
    routeTo(link) {
      routeFromRoot(link)
    }
  },
  async mounted() {
    await this.tryToFetchServiceCategoriesByKeys()
  },
  created() {
    this.$feedback.fill("Process Part Page", this.$route.path)
  }
}
</script>
<style>
.new-part-title-box {
  width: 100%;
  height: 50px;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
  background-color: #0277bd!important;
  background: linear-gradient(40deg,#45cafc,#303f9f)!important;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  width: 100%;
  height: auto;
}
</style>