<template>
  <v-container
  v-if="isDataReady"
  fluid>
    <v-row dense>
        <v-col v-if="$parent.isRole(['ADMIN', 'SERVICE'])">
          <p class="process-repair-tab-section-title">{{$t('pages.repair.pickUpDetails')}}</p>
        </v-col>
        <v-col v-else-if="$parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])">
          <p class="process-repair-tab-section-title">{{$t('pages.repair.transitBackShippingDetails')}}</p>
        </v-col>
    </v-row>
    <v-form
    v-model="isFormValid">
      <v-row dense>
        <v-col class="mx-2">
          <text-field-value
          v-model="newData.data.courierName"
          :label="$t('textFields.courierName')"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          required/>
        </v-col>
        <v-col class="mx-2">
          <text-field-date
          v-model="newData.data.pickUpDate" 
          :label="$t('textFields.pickUpDate')"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          :max="newData.data.estimateDeliveryDate"
          required/>
        </v-col>
        <v-col class="mx-2">
          <text-field-date
           v-model="newData.data.estimateDeliveryDate" 
          :label="$t('textFields.estimateDeliveryDate')"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          :min="newData.data.pickUpDate"
          required/>
        </v-col>
        <v-col class="mx-2">
          <text-field-value
          v-model="newData.data.trackingNumber" 
          :label="$t('textFields.trackingNumber')"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          required/>
        </v-col>
      </v-row>
    </v-form>
    <v-row 
    dense 
    class="my-10">
      <v-col>
        <p class="text-center"
        style="font-weight: bold">
          {{$t('common.pressContinueWhenReady')}}
        </p>
        <process-repair-quick-btns 
        v-if="$parent.isRole(['ADMIN', 'SERVICE'])" 
        :config="getQuickBtnsConfig"
        :loading.sync="isLoading"
        on-success-repair-status-message="dialogs.messages.success.toWaitingCompletion"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextFieldDate from '@/components/text-fields/TextFieldDate'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import { updateTransitBackShippingData } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import StringUtil, { cloneObj, compareObjects, copyObj } from '@/utils/StringUtil'
export default {
  components: {
    TextFieldValue,
    TextFieldDate,
    ProcessRepairQuickBtns
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      isFormValid: false,
      isLoading: false,
      newData: null,
      isDataReady: false
    }
  },
  methods: {
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.transitBackDataSavedSuccessfully'
      }

      const r = await execHttpRequestThrowable(async () => await updateTransitBackShippingData(this.$route.params.id, this.newData.data),
      messages,
      v => this.isLoading = v)
      cloneObj(this.newData.data, this.repairProp.transitBackShippingDetails)
      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItems() {
      const old = this.repairProp.transitBackShippingDetails
      const _new = this.newData.data
      const props = [
        {
          field: 'textFields.courierName',
          prop: 'courierName'
        }, {
          field: 'textFields.pickUpDate',
          prop: 'pickUpDate'
        }, {
          field: 'textFields.estimateDeliveryDate',
          prop: 'estimateDeliveryDate'
        }, {
          field: 'textFields.trackingNumber',
          prop: 'trackingNumber'
        }
      ]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    }
  },
  computed: {
    repairProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'Save',
          events: {
            'click': this.setChangedDialogItems
          },
          props: {
            'disabled': this.disabled || !this.isFormValid
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    },
  },
  mounted() {
    const data = this.repairProp.transitBackShippingDetails
    data.pickUpDate = data.pickUpDate ? data.pickUpDate.replaceAll(/T.*$/g, '') : ''
    data.estimateDeliveryDate = data.estimateDeliveryDate ? String(data.estimateDeliveryDate).replaceAll(/T.*$/g, '') : ''
    this.newData = {
      isAllowedToContinue: this.repairProp.isAllowedToContinue,
      data: copyObj(data)
    }

    if (this.$parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])) {
      StringUtil.ifEmpty(this.newData.data.courierName, () => this.newData.data.courierName = this.$t('textFields.notSetYet'))
      StringUtil.ifEmpty(this.newData.data.pickUpDate, () => this.newData.data.pickUpDate = this.$t('textFields.notSetYet'))
      StringUtil.ifEmpty(this.newData.data.estimateDeliveryDate, () => this.newData.data.estimateDeliveryDate = this.$t('textFields.notSetYet'))
      StringUtil.ifEmpty(this.newData.data.trackingNumber, () => this.newData.data.trackingNumber = this.$t('textFields.notSetYet'))
    }

    this.isDataReady = true
  }
}
</script>
<style>

</style>