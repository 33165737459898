<template>
  <v-autocomplete
  v-model="model"
  :rules="rules"
  :label="label"
  :placeholder="placeholder"
  :item-text="itemText"
  :item-value="itemValue"
  return-object
  hide-details="auto"
  :items="items"
  :disabled="disabled"
  small-chips
  multiple
  clearable>
    <template v-slot:[`item`]="{ item }">
      <span>{{$t(item[itemValue ? itemText : 'name'])}}</span>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    label: String,
    placeholder: String,
    items: {
      type: Array,
      required: true
    },
    value: Array,
    required: Boolean,
    itemText: String,
    itemValue: String,
    disabled: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true
      ]
    }
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  methods: {
  }
}
</script>
<style>

</style>