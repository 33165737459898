<template>
  <!-- 
    flat
    solo
  -->
  <v-autocomplete
  v-model="model"
  :rules="rules"
  :label="$t(label)"
  :placeholder="placeholder"
  class="normal-text-field"
  :items="clients"
  :item-text="itemText"
  :item-value="itemValue"
  :return-object="!itemValue"
  :disabled="disabled"
  @update:search-input="tryToFetchOnSearch"
  dense
  hide-details="auto"
  clearable>
    <template v-slot:append-item>
      <div
      v-if="!isAll"
      v-intersect.quite="tryToFetchOnScroll" 
      class="teal--text"/>
    </template>
  </v-autocomplete>
</template>
<script>
import { fetchClientsByCriteria } from '@/repositories/clientRepo'
import { execHttpRequestThrowable } from '@/services/http'
export default {
  props: {
    label: String,
    placeholder: String,
    value: Object,
    required: Boolean,
    itemText: String,
    itemValue: String,
    disabled: Boolean,
    serviceIdentification: {
      type: String,
      default: () => {
        return null
      }
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => {
          if (this.required) {
            return !!v || this.$t('invalidValue.required')
          }
          else return true
        }
      ],
      word: '',
      page: 0,
      size: 1000,
      clients: [],
      isAll: 0,
      timeoutId: null
    }
  },
  methods: {
    async tryToFetchOnSearch(v) {
      this.clients = []
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      
      this.timeoutId = setTimeout(async () => {
        this.page = 0
        this.isAll = false
        this.word = v
        await this.tryToFetchClientsByCriteria(v)
        this.timeoutId = null
      }, 500)
    },
    async tryToFetchOnScroll(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.tryToFetchClientsByCriteria(this.word)
      }
    },
    async tryToFetchClientsByCriteria(word) {
      if (this.isAll) return
      const r = await execHttpRequestThrowable(async () => await fetchClientsByCriteria(this.page, this.size, null, word, this.serviceIdentification))
      ++this.page
      const pageable = r.response.data
      this.clients = [...this.clients, ...pageable.content]
      this.isAll = pageable.totalElements === this.clients.length
    }
  }
}
</script>
<style>
/* TODO: set height, so the clients are fetched on scroll!
.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
  max-height: 150px !important;
}
*/
</style>