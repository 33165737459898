<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        number-pages
        :headers="getHeaders"
        :items="items"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :onUpdateSize="tryToFetchRequests"
        :onPerviousBtnClick="tryToFetchRequests"
        :onNextBtnClick="tryToFetchRequests"
        :total-elements="totalElements"
        class="pa-4">
          <template v-slot:[`header.createdAt`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.updatedAt`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.clientUsername`]="{ header }">
              {{$t(header.text)}}
          </template>
          <template v-slot:[`header.details`]="{ header }">
              {{$t(header.text)}}
          </template>


          <template v-slot:[`item.status`]="{ item }">
              <span>
                {{$t(getStatusByRequestType(item.requestType, item.status)).toUpperCase()}}
              </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.createdAt)}}
              </span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.updatedAt)}}
              </span>
          </template>
          <template 
          v-if="getRole !== 'CLIENT'"
          v-slot:[`item.clientUsername`]="{ item }">
              <span>
                {{item.clientUsername}}
              </span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
              <route-btn
              :href="getHrefByRequestType(item.requestType, item.id)"
              label="btns.viewDetails"
              badge="1"
              :width="120"
              :height="30"/>
          </template>
        </main-data-table>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4"
    name="export.title.actionRequiredRepairs"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import DateUtil from '@/utils/DateUtil'
import MainDataTable from '@/components/common/MainDataTable'
import RouteBtn from '@/components/btns/RouteBtn'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import { fetchActionRequiredRequest } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getRepairStatusByKey, getPartRequestStatusByKey } from '@/utils/statusesUtil'
import { getUserRole, isLanguageEn } from '@/services/authService'
import { routeFromRoot } from '@/services/routeService'
import { exportActionRequiredRepairsXlsx } from '@/repositories/exportRepo'

export default {
  components: {
    MainDataTable,
    RouteBtn,
    ExportXlsxRow
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.requestId',
          align: 'start',
          sortable: true,
          value: 'requestId'
        },{
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        }, {
          text: 'headers.category',
          align: 'start',
          sortable: true,
          value: 'requestType'
        }, {
          text: 'headers.creationDateTime',
          align: 'start',
          sortable: true,
          value: 'createdAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.lastChangeDateTime',
          align: 'start',
          sortable: true,
          value: 'updatedAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.clientName',
          align: 'start',
          sortable: true,
          value: 'clientUsername'
        }, {
          text: 'headers.details',
          align: 'start',
          sortable: true,
          value: 'details'
        }
      ],
      items: [],
      totalElements: 0,
      size: 10,
      page: 1,
      isLoading: false
    }
  },
  computed: {
    getRole() {
      return getUserRole()
    },
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.getRole === 'CLIENT' && h.value === 'clientUsername') continue

        headers.push(h)
      }

      return headers
    },
    isEN() {
      return isLanguageEn()
    },
    getExporter() {
      return exportActionRequiredRepairsXlsx
    },
    sort() {
      if (this.getRole === 'SUPERVISOR' || this.getRole === 'ADMIN') return '&sort=requestType,DESC&sort=createdAt,DESC'
      else return '&sort=createdAt,DESC'
    }
  },
  methods: {
    async tryToFetchRequests() {
      this.items = []
      const r = await execHttpRequestThrowable(async () => await fetchActionRequiredRequest(this.size, this.page - 1, this.sort),
      {},
      v => this.isLoading = v)
      const p = r.response.data
      this.totalElements = p.totalElements
      this.items = p.content
    },
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    getStatusByRequestType(requestType, key) {
      switch (requestType) {
        case 'REPAIR': return getRepairStatusByKey(key)
        case 'PARTS': return getPartRequestStatusByKey(key)
        default: return null
      }
    },
    getHrefByRequestType(requestType, id) {
      switch (requestType) {
        case 'REPAIR': return `process-repair/${id}`
        case 'PARTS': return `process-part-request/${id}`
        default: return null
      }
    },
    routeTo(link) {
      routeFromRoot(link)
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  async mounted() {
    await this.tryToFetchRequests()

    this.$feedback.fill("Action Required Repairs Page", this.$route.path)
  }
}
</script>
<style>

.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
</style>