<template>
  <v-dialog
  v-model="$successDialog.model"
  width="500"
  @click:outside="closeDialog">
    <v-card
    color="white"
    width="100%">
      <div class="success-dialog-header success-dialog-header-title d-flex justify-space-between align-center px-5">
        <span>{{$t($successDialog.title)}}</span>
        <v-btn
        icon
        dark
        @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="d-flex justify-center align-center pt-4">
        <v-icon size="90" color="#61c461">
          mdi-check-circle-outline
        </v-icon>
      </div>
      <div class="d-flex justify-center align-center px-4 pt-4">
        <p class="success-dialog-content text-center">
          {{$t($successDialog.message)}}
        </p>
      </div>
      <div 
      class="mt-5"
      style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
      <v-row dense no-gutters class="pa-4 pb-6" justify="center">
        <v-col class="d-flex justify-center align-center">
          <outlined-btn
          :label="$t('btns.ok')"
          color="#61c461"
          :width="100"
          :height="45"
          @click="closeDialog"/>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import OutlinedBtn from '@/components/btns/OutlinedBtn'
// import { DynamicNotifier } from '@/utils/DynamicNotifier'
export default {
  components: {
    OutlinedBtn
  },
  computed: {
    
  },
  methods: {
    closeDialog() {
      if (this.$successDialog.action) this.$successDialog.action()
      this.$successDialog.model = false
      // if (this.reload) DynamicNotifier.notify('repairStatusTokenEvent')
    }
  },
  beforeDestroy() {
    this.closeDialog()
  }
}
</script>
<style>
.success-dialog-header {
  width: 100%;
  height: 70px;
  background-color: #61c461;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.144);
  border-radius: 0px!important;
}
.success-dialog-header-title {
  font-family: 'Titillium Web', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.success-dialog-content {
  font-family: 'Titillium Web', sans-serif;
  color: black;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
</style>