export const es = {
    common: {
      rowsPerPage: 'Filas por página',
      clientAction: {
        home: {
          title: 'Inicio',
          subtitle: 'Página de inicio de STS'
        },
        newRepairRequest: {
          title: 'Nueva solicitud de reparación',
          subtitle: 'Realizar una nueva solicitud de reparación de piezas/dispositivos portátiles'
        },
        sparePartsRequest: {
          title: 'Solicitud de repuestos',
          subtitle: 'Hacer una nueva solicitud de repuestos'
        },
        remoteSupportRequest: {
          title: 'Solicitud de soporte remoto',
          subtitle: 'Hacer una nueva solicitud de soporte remoto'
        }
      },
      pressContinueWhenReady: 'Cuando esté listo, presione "Continuar" para que el proceso avance',
      serviceCategories: {
        'REPAIR': 'Solicitud de reparación',
        'PARTS': 'Solicitud de pieza',
        'REMOTE_SUPPORT': 'Soporte remoto'
      }
    },
    pages: {
      baseLayout: {
        systemTitle: 'STS - Cuenta {0}'
      },
      contact: {
        newContact: 'Nuevo Usuario',
        users: 'Usuarios',
        userList: 'Lista de Usuarios',
        profile: 'Perfil',
        accountDetailsTitle: 'DETALLES DE LA CUENTA',
        addressDetailsTitle: 'DETALLES DE LA DIRECCIÓN',
        clientServiceDetailsTitle: '{0} Detalles',
        helloUser: 'Hola, {0}',
        userDetails: 'Detalles de Usuario',
        logout: 'Cerrar sesión'
      },
      part: {
        parts: 'Piezas',
        newPart: 'Pieza Nueva',
        addPart: 'Agregar Pieza',
        editPart: 'EDITAR PIEZA'
      },
      repair: {
        newRepair: 'Nueva Reparación',
        actionRequired: 'Acción Solicitada',
        activeRepairs: 'Reparaciones Activas',
        closedRepair: 'Reparaciones Cerradas',
        reports: 'Reportes',
        generalReport: 'Reporte General',
        repairInProgress: 'LA REPARACIÓN AÚN ESTÁ EN CURSO!',
        repairCompleted: 'LA REPARACIÓN COMPLETADA!',
        completedRepairDetails: 'DETALLES DE LA REPARACIÓN COMPLETADA',
        expectDeliveryConfirmation: 'Espere confirmación de entrega hasta {0} (Envío estimado + dos días)',
        repairRequestDetails: 'DETALLES DE LA SOLICITUD DE REPARACIÓN',
        partsForApproval: 'Piezas para aprobación',
        partsInfo: 'Información de las piezas',
        partsAreInService: 'Sus piezas están ahora en servicio y esperando la aprobación para su reparación. El estado de cada Pieza se puede encontrar a continuación:',
        partsAreInServiceOptional: 'Ahora {0} Piezas están en el servicio de reparación. El estado actual de cada Pieza se puede encontrar a continuación:',
        partsAreInDiagnostic: 'Ahora sus Piezas están siendo diagnosticadas. Cuando finalice el diagnóstico, se le notificarán los resultados.',
        repairStatusChangeHistory: 'HISTORIAL DE CAMBIOS DE ESTADO DE LA REPARACIÓN',
        partsForRepairApproval: 'APROBACIÓN DE PIEZAS PARA REPARACIÓN',
        partsAreInWaitingApproval: 'Ahora sus Piezas están esperando la aprobación para su reparación. El estado actual de cada Pieza se puede encontrar a continuación:',
        partsInService: 'Piezas en servicio',
        contactDetails: 'DETALLES DE CONTACTO',
        addressDetails: 'DETALLES DE LA DIRECCIÓN',
        packageDetails: 'DETALLES DEL PAQUETE',
        addPackageInfo: 'Agregue información para cada paquete. Agregar nuevo paquete con el botón verde',
        pickUpDetails: 'DETALLES DE RECOLECCIÓN',
        transitBackShippingDetails: 'DETALLES DE ENVÍO DE REGRESO EN TRÁNSITO',
        startDiagnosticDate: 'FECHA DE INICIO DE DIÁGNOSTICO',
        estimatedDiagnosticsDateBelow: 'En base a la carga de trabajo del servicio - los datos de los diagnósticos estimados se enumeran a continuación:',
        dataAutoSavedLocally: 'Los datos se guardan automáticamente y localmente',
        dontSendRejectedParts: 'Verifique en el paso anterior si todas sus Piezas fueron aprobadas. No envíe Piezas que no hayan sido aprobadas.',
        tabs: {
          details: 'Detalles',
          shippingDetails: 'Detalles de envío',
          waitingForPickUp: 'Esperando para Recoger',
          inTransit: 'En tránsito',
          waitingDiagnostics: 'Diagnósticos en Espera',
          diagnostics: 'En Diagnóstico',
          repairApproval: 'Aprobacion de Reparación',
          repair: 'En Servicio',
          transitBack: 'Tránsito de Regreso',
          completed: 'Completado',
          history: 'Historial'
        },
        toolTipRequiredTextFields: '¡Los campos con * deben completarse para todas las Piezas!',
        updateDataShippingDetailsTab: 'Se le enviará una empresa de mensajería para que se lleve las Piezas/Dispositivos Portátiles. Actualice su POC y los detalles de la dirección de envío, si es necesario, e ingrese información sobre los paquetes que desea enviar',
        newRepairDescription: 'Por favor, seleccione de los menús desplegables las Piezas que desea Reparar y describa sus problemas. El número de serie es un campo obligatorio. Ingrese "-" o "N/A" si la Pieza no tiene un número de serie. El botón "Enviar" se activará cuando complete los campos obligatorios.',
        waitingForPickUpDescription: 'Presione Continuar cuando esté completamente listo',
        pleaseApproveOrRejectDetailTab: 'Por favor, Aprueba o Rechace cada elemento y presiona Continuar cuando estés listo con todos.',
        pleaseConfirmDeliveryCompletedTab: 'Por favor, presione "Confirmar Entrega" cuando reciba las Piezas Reparadas y los Dispositivos Portátiles.',
        deleteRepair: '¿Está seguro de que desea eliminar esta solicitud?'
      },
      partRequest: {
        menu: 'Solicitud de Pieza',
        repairCompleted: 'LA SOLICITUD DE PIeza ESTÁ COMPLETADA!',
        completedRepairDetails: 'DETALLES DE LA SOLICITUD DE PIEZA COMPLETADA',
        newPartRequest: 'Solicitud de Pieza nueva',
        partRequestList: 'Lista de Solicitudes de Piezas',
        repairRequestDetails: 'DETALLES DE LA SOLICITUD DE PIEZAS',
        repairStatusChangeHistory: 'HISTORIAL DE CAMBIOS DE ESTADO DE LAS PIEZAS',
        pleaseApproveOrRejectDetailTab: 'Por favor, Apruebe o Rechace cada elemento y presione Continuar cuando esté listo con todos ellos.',
        iDontFindMyPart: 'Si no encuentra la Pieza que busca, puede describirla rellenando los campos siguientes:',
        deletePartRequest: '¿Está seguro de que desea eliminar esta Solicitud de Pieza?'
      },
      remoteSupport: {
        menu: 'Soporte Remoto',
        repairWaitingCompletion: '¡EL SOPORTE REMOTO ESTÁ ESPERANDO TERMINARSE!',
        repairCompleted: '¡EL SOPORTE REMOTO ESTÁ COMPLETADO!',
        newRemoteSupport: 'Nueva Solicitud',
        remoteSupportList: 'Lista de Solicitudes',
        cardTitle: 'Crear Nuevo Soporte Remoto',
        toolTipClickToExpand: 'Haga clic para Ampliar',
        toolTipClickToCollapse: 'Haga clic para Minimizar',
        repairRequestDetails: 'DETALLES DE SOPORTE REMOTO',
        dataForApproval: 'Soporte para Aprobación',
        pleaseApproveOrRejectDetailTab: 'Por favor, Apruebe o Rechace el Soporte y presione Continuar cuando esté listo.',
        repairStatusChangeHistory: 'HISTORIAL DE CAMBIOS DE ESTADO DE LOS SOPORTES REMOTOS',
        reviewData: 'REVISAR DATOS',
        tabs: {
          serviceReview: 'Revisión del Servicio'
        }
      },
      requestAccount: {
        cardTitle: 'Solicitar una Cuenta',
        approvedCardTitle: 'Solicitud de Cuenta Aprobada',
        rejectedCardTitle: 'Solicitud de Cuenta Rechazada'
      },
      mail: {
        settings: 'Configuraciones',
        mailNotification: 'Notificación por Correo',
        settingRoleSubtitle: 'Use esta pantalla para controlar los correos enviados a cada rol. El proceso se divide en pasos. Para cada paso, puede determinar qué función recibe notificación por correo para cambio de estado. Debe presionar Guardar después de los cambios.'
      },
      stsDescription: {
        title: 'Descripción de Piezas de la NSDD',
        subtitle: 'NSDD Parts proporciona una herramienta en línea para que los Países Socios de la NSDD soliciten Reparaciones de Equipos Portátiles, Reparaciones de Piezas de Monitores de Portal de Radiación (RPM) y otro Tipo de Asistencias.'
      },
      stsDisclaimer: {
        title: 'Descargo de Responsabilidad',
        subtitle: 'Todas las solicitudes se revisarán para determinar si se pueden respaldar contractualmente y si tienen un alcance económico.'
      },
      resetPassword: {
        cardTitle: 'Restablecer la Contraseña'
      }
    },
    textFields: {
      selectRole: 'Seleccionar rol',
      userPassword: `Contraseña del Usuario`,
      changingPassword: 'Cambiando Contraseña!',
      companyName: 'Nombre de la Empresa',
      country: 'País',
      state: 'Estado',
      city: 'Ciudad',
      postalCode: 'Código Postal',
      street: 'Dirección',
      streetDetails: 'Detalles de la Dirección',
      building: 'Información del Edificio',
      email: 'Correo Electrónico',
      secondaryEmail: 'Correo Electrónico Secundario',
      clientName: 'Nombre del Cliente',
      serviceName: 'Nombre del Servicio',
      username: 'Nombre de Usuario',
      contactPerson: 'Persona de Contacto',
      contactEmail: 'Correo electrónico de Contacto',
      address: 'Dirección',
      pricePerHour: 'Precio por Hora (USD)',
      noData: 'Sin Datos',
      requestId: 'Número de Solicitud',
      countryCode: 'Código de País',
      status: 'Estado',
      selectStatus: 'Seleccionar Estado',
      changeStatus: 'Cambiar Estado',
      creationDateTime: 'Fecha/Hora de Creación ({0})',
      lastChangeDateTime: 'Última Fecha/Hora de Cambio ({0})',
      creationDateTimeUTC: 'Fecha/Hora de Creación (UTC)',
      lastChangeDateTimeUTC: 'Última Fecha/Hora de Cambio (UTC)',
      dimensions: 'Dimensiones Ancho/Alto/Largo (cm)',
      weight: 'Peso (kg)',
      courierName: 'Nombre del Servicio de Mensajería',
      pickUpDate: 'Fecha de Recolección',
      estimateDeliveryDate: 'Fecha de Entrega Estimada',
      trackingNumber: 'Número de Seguimiento',
      courierArrivingTime: 'Hora de llegada del Mensajero',
      note: 'Nota',
      optional: '(Opcional)',
      notSetYet: '¡Aún No Configurado!',
      diagnosticDate: 'Fecha de Diagnóstico',
      clientLabel: 'Cliente:',
      statusLabel: 'Estado:',
      serviceLabel: 'Servicio:',
      categoryLabel: 'Categoría:',
      partLabel: 'Pieza:',
      requestNumberLabel: 'Número de Solicitud:',
      serialNumberLabel: 'Número de Serie:',
      serviceTagLabel: 'Marcas del Servicio:',
      creationDateFromLabel: 'Fecha de Creación desde:',
      creationDateToLabel: 'Fecha de Creación hasta:',
      searchLabel: 'Búsqueda:',
      setNameOrValueProperty: `Establecer la Propiedad 'Nombre' o 'Valor'!`,
      firstName: 'Nombres',
      lastName: 'Apellidos',
      yourPassword: 'Su Contraseña',
      confirmPassword: 'Confirmar la Contraseña',
      newPassword: 'Nueva Contraseña',
      phone: 'Celular',
      yourUsername: 'Su Usuario',
      locked: 'Bloqueado',
      unlocked: 'Desbloqueado',
      selectCategory: 'Seleccione Categoría',
      partNumber: 'Número de Pieza',
      partModel: 'Modelo de la Pieza',
      manufacturer: 'Fabricante',
      shortDescription: 'Descripción Breve',
      uploadYourFile: 'Cargue el Archivo',
      itemCategoryLabel: 'Tipo de Posición:',
      quantityLabel: 'Cantidad:',
      clients: 'Clientes',
      partName: 'Nombre de la Pieza',
      title: 'Título',
      description: 'Descripción',
      preferredDateUTC: 'Fecha Preferida (UTC)',
      preferredDateLocal: 'Fecha Preferida ({0})',
      preferredTimeUTC: 'Hora Preferida (UTC)',
      preferredTimeLocal: 'Hora Preferida ({0})',
      backupEmailNo: 'Correo Electrónico de Respaldo ({0})',
      removed: 'Remoto',
      clientNo: 'Cliente ({0})',
      approval: 'Aprobación',
      dimensionsNo: 'Dimensiones ({0})',
      weightNo: 'Peso ({0})',
      diagnosticStartDate: 'Fecha de inicio de Diagnóstico',
      content: 'Contenido',
      enabledDisabled: 'Habilitar/Deshabilitar',
      enabled: 'Activado',
      disabled: 'Desactivado',
      serviceCategories: 'Categorías del Sistema',
      durationInHours: 'Duración en Horas',
      completionDate: 'Fecha de Terminación ({value})',
      summary: 'Resumen'
    },
    placeholder: {
      selectClient: 'Seleccionar Cliente',
      selectStatus: 'Seleccionar Estado',
      selectService: 'Seleccionar Servicio',
      selectCategory: 'Seleccionar Categoría',
      requestNumber: 'Número de Solicitud',
      serialNumber: 'Número de Serie',
      serviceTag: 'Estiqueta de Servicio',
      selectPart: 'Seleccionar Pieza'
    },
    btns: {
      create: 'Crear',
      save: 'Guardar',
      saveAll: 'Salvar Todos',
      close: 'Cerrar',
      request: 'Solicitud',
      applyChanges: 'Aplicar Cambios',
      submitRequest: 'Enviar Solicitud',
      ok: 'OK',
      confrimDelivery: 'Confirmar Envío',
      reject: 'Rechazo',
      approve: 'Aprobación',
      shipmentArrived: 'El Envío ha llegado',
      allInProgress: 'Todo en Progreso',
      allReplaced: 'Todo Reemplazado',
      allRepaired: 'Todo Reparado',
      allNonRepairable: 'Todo No Reparable',
      rejectAll: 'Rechazar Todo',
      approveAll: 'Aprochar Todo',
      approveMarked: 'Aprobat Marcado',
      submitForApproval: 'Presentar para Aprobación',
      continue: 'Continuar',
      setDiagnosticDate: 'Fecha de Diagnóstico Establecida',
      packagesPickedUp: 'Paquetes Recolectados',
      search: 'Búsqueda',
      reset: 'Reiniciar',
      cancel: 'Cancelar',
      edit: 'Editar',
      backToList: 'Vuelve a la Lista',
      viewDetails: 'Ver Detalles',
      addPart: 'AÑADIR PIEZA',
      delete: 'Eliminar',
      deleteAll: 'Eliminar Todos',
      exportXLSX: 'Exportar a XLSX',
      chooseFile: 'Elija el Archivo',
      newRepairRequest: 'Nueva Solicitud de Reparación',
      remoteSupport: 'Soporte Remoto',
      newPartRequest: 'Solicitud de Pieza Nueva',
      home: 'Inicio',
      send: 'Enviar',
      complete: 'Completo',
      addEmail: 'Agregar Correo Electrónico',
      continueEditing: 'Continúa Editando'
    },
    roles: {
      client: 'Cliente',
      service: 'Servicio',
      supervisor: 'Supervisor',
      admin: 'Administrador',
      sustainabilityManager: 'Gerente de Sostenibilidad'
    },
    invalidValue: {
      required: 'Requerido!',
      _2LettersOnly: '¡El valor debe contener solo 2 letras!',
      _2LettersAtLeast: '¡El valor debe contener al menos 2 letras!',
      _10DigitsAtLeast: '¡El valor debe contener al menos 10 dígitos!',
      invalidDimensions: 'El valor debe estar en la Plantilla Especificada: Ejemplo - 100/100/100!',
      invalidEmail: 'Correo Electrónico Inválido',
      valueMustBeDigit: '¡El valor debe ser un dígito!',
      invalidPassword: '¡El valor debe contener al menos 8 símbolos, al menos 1 dígito, al menos 1 letra mayúscula, al menos 1 símbolo especial!',
      passwordNotMatched: 'Las Contraseñas no Coinciden',
      maxNumberRestriction: 'El número debe ser menor o igual a {0}',
      maxSymbols: 'El número de caracteres debe ser hasta {value}',
      minymbols: 'El número de caracteres debe ser al menos {value}',
    },
    dialogs: {
      titles: {
        success: 'Éxito',
        failed: 'Fallido',
        changes: 'Cambios',
        forgottenPassword: 'Restablecer la Contraseña',
        retrieveUsername: 'Recuperar Mombre de usuario',
        clientAction: 'Por favor elige una acción',
        validate: 'Validar',
        delete: 'Borrar',
        imagePreview: 'Imagen previa {value}'
      },
      subtitles: {
        createRepairWarning: 'La Solicitud de Reparación no se puede Modificar después de enviarla. ¿Agregó todas las piezas para reparar y la Descripción de sus problemas?',
        deletingItemOfNewRepairWarning: 'Esto va a eliminar el elemento. ¿Está seguro?',
        deletingItemsOfNewRepairWarning: 'Esto va a eliminar los elementos. ¿Está seguro?',
        requestNewPartWarning: 'La Solicitud de Piezas No se puede Cambiar una vez que la envía. ¿Ha agregado todas las Piezas solicitadas?'
      },
      messages: {
        success: {
          userDataSavedSuccessfully: 'Guardó Correctamente los Datos del Usuario: {0}',
          addressDataSavedSuccessfully: 'Guardaste con Éxito los Datos de la Dirección',
          clientDataSavedSuccessfully: 'Guardaste con Éxito los Datos del Cliente',
          serviceDataSavedSuccessfully: 'Guardaste con Éxito los Datos del Servicio',
          confirmedPackageReceivedBackSuccessfully: '¡Confirmó con Éxito que los paquetes fueron recibidos!',
          firstDetailsCompleted: 'Su decisión se envía con Éxito. Ahora es el momento de que el Cliente y el Servicio de Reparación organicen el transporte.',
          repairStatusSetToRejected: 'Su decisión se envía con Éxito. La solicitud ahora está en estado Completada.',
          approvalDecisionSaved: '¡Guardó con Éxito su decisión de Aprobación!',
          rejectionDecisionSaved: '¡Guardó con Éxito su decisión de Rechazo!',
          dataSavedSuccessfully: '¡Datos Guardados con Éxito!',
          toWaitingDiagnosticsStep: `¡Ha cambiado correctamente el estado a 'EN ESPERA DE DIAGNÓSTICO'!`,
          toWaitingForPickUpStep: `¡Ha cambioado correctamente el estado a 'ESPERANDO PARA RECOLECTAR'!`,
          toTransitBackStep: `¡Ha cambioado correctamente el estado a 'TRÁNSITO DE REGRESO'!`,
          toWaitingCompletion: `¡Ha cambioado correctamente el estado a 'ESPERANDO COMPLETAR'!`,
          toDiagnostics: `Ha cambioado correctamente el estado a 'DIAGNÓSTICO'!`,
          toTransportArranged: `¡Ha cambiado correctamente el estado a 'TRANSPORTE ORGANIZADO'!`,
          toTransit: `Ha cambiado correctamente el estado a 'TRÁNSITO'!`,
          toSettingTrackingNumber: `¡Ha cambioado correctamente el estado a 'ESTABLECIENDO NÚMERO DE SEGUIMIENTO'!`,
          proceedingToNextStepSuccessfully: '¡Está procediendo con Éxito al siguiente paso!',
          repairStatusSavedSuccessfully: '¡Ha guardado correctamente el estado de la reparación!',
          repairStatusesSavedSuccessfully: '¡Ha guardado con Éxito los estados de reparación!',
          pickUpDataSavedSuccessfully: '¡Ha guardado con Éxito los datos de Recolección!',
          serviceReviewDataSavedSuccessfully: '¡Ha guardado correctamente los datos de revisión del servicio!',
          transitBackDataSavedSuccessfully: '¡Guardó con Éxito los datos de organización del transporte de vuelta!',
          diagnosticDateSavedSuccessfully: '¡Configuró con Éxito la Fecha de Diagnóstico!',
          transportArrangingDateSavedSuccessfully: '¡Ha guardado con Éxito los Datos de Organización del Transporte!',
          partSavedSuccessfully: '¡Se guardó la Pieza con Éxito!',
          mailNotificationSettingsSavedSuccessfully: '¡Los Datos de Notificación de Correo se Guardaron con Éxito!',
          resetPasswordMailSend: '¡Se ha enviado un enlace de restablecimiento de contraseña a las direcciones de correo electrónico que solicitó!',
          requestUsernameMailSend: '¡Se le ha enviado un correo electrónico con los nombres de usuario asociados con su correo electrónico!',
          requestAccountMailSend: 'Se han enviado correos electrónicos a los supervisores con respecto a su solicitud. Si su solicitud es Aprobada y Confirmada, ¡se le enviará un correo electrónico con información sobre su Nueva Cuenta!',
          feedbackRequestedSuccessfully: 'Comentarios Solicitados con Éxito!',
          repairDeletedSuccessfully: '¡Reparación Eliminada Exitosamente!',
          partRequestDeletedSuccessfully: '¡Solicitud de Pieza Eliminada Exitosamente!',
          remoteSupportFirstDetailsCompleted: 'Su decisión se envía con Éxito. Ahora es el momento de que el Servicio de Reparación revise la Solicitud.',
          completeRemoteSupportRequest: '¡La solicitud está Completa!',
          waitingCompletion: '¡La solicitud ahora debería estar Completa!'
        },
        failed: {
          usernameAlreadyExists: '¡El Contacto con este Nombre de Usuario ya Existe!',
          actionNotProvided: '¡No se proporciona una acción! {0}',
          mailNotificationSettingsSavingError: '¡Error al guardar la configuración de notificación de correo!',
          userNotFound: 'No se puede encontrar un Usuario con las Credenciales dadas',
          userNotFoundUsernameAndPassword: 'Nombre de Usuario/Contraseña No coinciden',
          userNotFoundByUsername: 'No se puede encontrar un Usuario con el Nombre de Usuario dado',
          usernameNotFoundByEmail: '¡No se puede encontrar un Nombre de Usuario con el correo electrónico proporcionado!',
          internalErrorOccurred: '¡Ocurrió un error en el Navegador!',
          serverErrorOccurred: 'Ocurrió un error en el Servidor',
          badRequest: '¡La solicitud No se envió correctamente al Servidor!',
          accessForbidden: '¡Usted No tiene Autoridad para este Servicio!',
          resourceNotFound: '¡No se encontró la información solicitada!',
          methodNotAllowed: '¡La Solicitud no utiliza el método correcto que se esperaba!',
          actionKeyExpired: '¡La Solicitud ha Caducado!',
          actionKeyNotFound: '¡No se encontró ninguna Clave generada para Su Solicitud!',
          noLongerLoggedInTheSystem: 'Su Sesión ha Caducado, ¡inicie sesión nuevamente!',
          clientWithNameAlreadyExists: '¡Ya Existe un Cliente con este Nombre!',
          serviceWithNameAlreadyExists: 'EL SERVICIO con este nombre ya existe!',
          maxUploadSizeExceeded: '¡Se superó el límite de tamaño de archivo de 1 MB que se puede cargar!'
        }
      }
    },
    snackbar: {
      success: {
        serviceCategoriesSavedSuccessfully: 'Categorías de servicios guardadas correctamente',
        initialApprovalSaved: '¡Se ha guardado el estado de Aprobación Inicial "{value}"!'
      }
    },
    initialStatuses: {
      approved: 'Aprobado',
      rejected: 'Rechazado',
      waitingApproval: 'Esperando Aprovación'
    },
    repairStatus: {
      itIsWorking: 'En Funcionamiento',
      forRepair: 'Para Reparación',
      forReplace: 'Para Remplazo',
      nonRepairable: 'Irreparable'
    },
    serviceStatus: {
      inProgress: 'En progreso',
      replaced: 'Sustituido',
      repaired: 'Reparado',
      nonRepairable: 'Irreparable',
      rejected: 'Rechazado'
    },
    diagnosticsStatuses: {
      itIsWorking: 'Está funcionando',
      needsRepairing: 'Necesita Reparación',
      needsReplacing: 'Necesita Reemplazar',
      nonRepairable: 'Irreparable'
    },
    partRequestStatuses: {
      initial: 'Esperando Aprovación',
      done: 'Entregado',
      inOneWayShipping: 'Organizar el Transporte',
      inWaitingForPickUp: 'Esperando Mensajero',
      waitingConfirmation: 'Enviado',
      rejected: 'Rechazado'
    },
    remoteSupportStatuses: {
      initial: 'Inicial',
      serviceReview: 'Revisión del Servicio',
      waitingCompletion: 'Esperando Finalización',
      rejected: 'Rechazado',
      done: 'Hecho'
    },
    mainStatuses: {
      none: 'NINGUNO',
      new: 'NUEVO',
      inShippingDetails: 'DETALLES DE ENVÍO',
      settingTrackingNumber: 'CONFIGURACIÓN DEL NÚMERO DE SEGUIMIENTO',
      inWaitingForPickUp: 'ESPERANDO PARA RECOLETAR',
      inPickUpArranged: 'TRANSPORTE ORGANIZADO',
      transit: 'EN TRÁNSITO',
      inWaitingDiagnostics: 'EN ESPERA DE DIAGNÓSTICO',
      inDiagnostics: 'DIAGNÓSTICO',
      inRepairApproval: 'APROBACIÓN DE REPARACIÓN',
      inService: 'SERVICIO',
      inTransitBack: 'TRÁNSITO DE REGRESO',
      inWaitingCompleted: 'ESPERANDO COMPLETAR',
      completed: 'Completado',
      rejected: 'RECHAZADO'
    },
    headers: {
      mark: 'Etiquetado',
      image: 'Imagen',
      category: 'Categoría',
      partName: 'Nombre de la Pieza',
      serialNumber: 'Número de Serie',
      serviceTag: 'Marcas del Servicio',
      problemDescription: 'Describa el Problema',
      comment: 'Comentarios',
      repairComment: 'Reparación Comentario',
      initialApproval: 'Aprobación Inicial',
      status: 'Estado',
      supervisorStatus: 'Estado del Supervisor',
      approval: 'Aprobación',
      repairStatus: 'Estado de la Reparación',
      repairApproval: 'Reparación Aprobada',
      needHours: 'Horas Requeridas',
      totalCost: 'Costo Total (USD)',
      sparePartPrice: 'Precio del Repuestos (USD)',
      diagnosticPrice: 'Precio del Diagnóstico (USD)',
      repairPrice: 'Precio de Reparación (USD)',
      partsPrice: 'Precio de Oiezas (USD)',
      diagnosticPriceTotal: 'Precio Total de los Diagnósticos (USD)',
      repairPriceTotal: 'Precio Total de las Reparaciónes (USD)',
      partsPriceTotal: 'Precio Total de las Piezas (USD)',
      totalCostOfAll: 'Precio Total de todos los Gastos (USD)',
      totalPrice: 'Precio Total (USD)',
      date: 'Fecha ({0})',
      dateUTC: 'Fecha (UTC)',
      changedBy: 'Cambiado por',
      role: 'Rol',
      oldStatus: 'Antiguo Estado',
      newStatus: 'Nuevo Estado',
      action: 'Acción',
      userId: 'ID del Usuario',
      username: 'Usuario',
      details: 'Detalles',
      itemCategory: 'Categoría del Artículo',
      partNumber: 'Numero de Piezas',
      model: 'Modelo',
      manufacturer: 'Fabricante',
      title: 'Título',
      description: 'Descripción',
      requestId: 'Número de Solicitud',
      creationDateTime: 'Fecha/Hora de la Creación ({0})',
      lastChangeDateTime: 'Última Fecha/Hora del Cambio ({0})',
      creationDateTimeUTC: 'Fecha/Hora de la Creación (UTC)',
      lastChangeDateTimeUTC: 'Última Fecha/hora del Cambio (UTC)',
      clientName: 'Nombre del Cliente',
      actionRequired: 'Acción Solicitada',
      completionDate: 'Fecha de Terminación ({0})',
      completionDateUTC: 'Fecha de Terminación (UTC)',
      deleteRow: 'Eliminar Fila',
      client: 'Cliente',
      service: 'Servicio',
      supervisor: 'Supervisor',
      admin: 'Administrador',
      sustainabilityManager: 'Gerente de Sostenibilidad',
      oldValue: 'Antiguo Valor',
      newValue: 'Nuevo Valor',
      row: 'Fila',
      number: '#',
      quantity: 'Cantidad',
      parts: 'Piezas',
      fullName: 'Nombre Completo',
      phone: 'Celular',
      email: 'Correo Electrónico',
      addRemovePackage: 'Agregar/Quitar Paquete',
      field: 'Campo',
      enabledDisabled: 'Habilitar/Deshabilitar',
      serviceCategories: 'Categorías del Sistema'
    },
    other: {
      show: 'Muestre',
      showing: 'los resultados',
      to: 'a',
      of: 'de',
      entries: 'Entradas',
      from: 'De',
      forgottenCredentials: 'Credenciales Olvidadas',
      forgottenPasswordLink: 'Restablecer la Contraseña',
      retrieveUsername: 'Recuperar Nombre de Usuario',
      accountRequestLink: 'Solicitar una Nueva Cuenta',
      months: {
        january: 'Enero',
        february: 'Febrero',
        march: 'Marzo',
        april: 'Abril',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Diciembre'
      }
    },
    export: {
      title: {
        activeRepairs: 'Reparaciones Activas',
        closedRepairs: 'Reparaciones Cerradas',
        generalReport: 'Informe General',
        actionRequiredRepairs: 'Acción Requerida Reparaciones',
        users: 'Usuarios',
        parts: 'PIezas',
        partRequest: 'Solicitud de Pieza',
        remoteSupport: 'Soporte Remoto',
        repairDetailTab: 'Pestaña Detalle de Reparación',
        repairDiagnosticsTab: 'Pestaña Diagnóstico de Reparación',
        repairApprovalTab: 'Pestaña Aprobación de Reparación',
        repairTab: 'Pestaña de Reparación',
        repairHistoryTab: 'Pestaña Historial de Reparaciones',
        partRequestDetailTab: 'Pestaña de Detalles de Solicitud de Piezas',
        partRequestHistoryTab: 'Pestaña Historial de Solicitudes de Piezas',
        remoteSupportHistoryTab: 'Pestaña Historial de Soporte Remoto'
      }
    },
    toolTips: {
      preview: 'Avance'
    }
  }