const REPAIR_STATUSES = [
  {
    key: 'NONE',
    value: 'mainStatuses.none'
  }, {
    key: 'NEW',
    value: 'mainStatuses.new'
  }, {
    key: 'IN_SHIPPING_DETAILS',
    value: 'mainStatuses.inShippingDetails'
  }, {
    key: 'IN_WAITING_FOR_PICK_UP',
    value: 'mainStatuses.inWaitingForPickUp'
  }, {
    key: 'IN_PICK_UP_ARRANGED',
    value: 'mainStatuses.inPickUpArranged'
  }, {
    key: 'IN_TRANSIT',
    value: 'mainStatuses.transit'
  }, {
    key: 'IN_WAITING_DIAGNOSTICS',
    value: 'mainStatuses.inWaitingDiagnostics'
  }, {
    key: 'IN_DIAGNOSTICS',
    value: 'mainStatuses.inDiagnostics'
  }, {
    key: 'IN_REPAIR_APPROVAL',
    value: 'mainStatuses.inRepairApproval'
  }, {
    key: 'IN_SERVICE',
    value: 'mainStatuses.inService'
  }, {
    key: 'IN_TRANSIT_BACK',
    value: 'mainStatuses.inTransitBack'
  }, {
    key: 'IN_WAITING_COMPLETED',
    value: 'mainStatuses.inWaitingCompleted'
  }, {
    key: 'COMPLETED',
    value: 'mainStatuses.completed'
  }, {
    key: 'REJECTED',
    value: 'mainStatuses.rejected'
  }
]
export function getAvailableRepairStatuses() {
  return REPAIR_STATUSES.filter(f => f.key !== 'NONE')
}
export function getRepairStatusByKey(k) {
  const s = REPAIR_STATUSES.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}
export function getObjectStatusByKey(k) {
  const s = REPAIR_STATUSES.filter(s => s.key === k)

  if (s.length > 0) return {
    key: String(s[0].key),
    value: String(s[0].value)
  }
  else return null
}

export const DIAGNOSTICS_STATUSES = [
  {
    key: 'IT_IS_WORKING',
    value: 'diagnosticsStatuses.itIsWorking'
  }, {
    key: 'FOR_REPAIR',
    value: 'diagnosticsStatuses.needsRepairing'
  }, 
  // {
  //   key: 'FOR_REPLACE',
  //   value: 'diagnosticsStatuses.needsReplacing'
  // },
  {
    key: 'NON_REPAIRABLE',
    value: 'diagnosticsStatuses.nonRepairable'
  }
]
export function getDiagnosticStatusByKey(k) {
  const s = DIAGNOSTICS_STATUSES.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}
export function getObjectDiagnosticStatusByKey(k) {
  const s = DIAGNOSTICS_STATUSES.filter(s => s.key === k)

  if (s.length > 0) return {
    key: String(s[0].key),
    value: String(s[0].value)
  }
  else return null
}

export const SERVICE_STATUSES = [
  {
    key: 'REPAIRED',
    value: 'serviceStatus.repaired'
  }, {
    key: 'NON_REPAIRABLE',
    value: 'serviceStatus.nonRepairable'
  }, {
    key: 'REPLACED',
    value: 'serviceStatus.replaced'
  }, {
    key: 'REJECTED',
    value: 'serviceStatus.rejected'
  }, {
    key: 'IN_PROGRESS',
    value: 'serviceStatus.inProgress'
  }
]
export function getServiceStatusByKey(k) {
  const s = SERVICE_STATUSES.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}
export function getObjectServiceStatusByKey(k) {
  const s = SERVICE_STATUSES.filter(s => s.key === k)

  if (s.length > 0) return {
    key: String(s[0].key),
    value: String(s[0].value)
  }
  else return null
}

export const INITIAL_STATUS = [
  {
    key: 'APPROVED',
    value: 'initialStatuses.approved'
  }, {
    key: 'REJECTED',
    value: 'initialStatuses.rejected'
  }, {
    key: 'WAITING_APPROVAL',
    value: 'initialStatuses.waitingApproval'
  }
]
export function getTextByKeyInitialStatus(k) {
  const s = INITIAL_STATUS.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}

const PART_REQUEST_STATUS = [
  {
    key: 'NONE',
    value: 'mainStatuses.none'
  }, {
    key: 'INITIAL',
    value: 'partRequestStatuses.initial'
  }, {
    key: 'IN_ONE_WAY_SHIPPING',
    value: 'partRequestStatuses.inOneWayShipping'
  }, {
    key: 'IN_WAITING_FOR_PICK_UP',
    value: 'partRequestStatuses.inWaitingForPickUp'
  }, {
    key: 'WAITING_CONFIRMATION',
    value: 'partRequestStatuses.waitingConfirmation'
  }, {
    key: 'REJECTED',
    value: 'partRequestStatuses.rejected'
  }, {
    key: 'DONE',
    value: 'partRequestStatuses.done'
  }
]
export function getObjectPartRequestStatusByKey(k) {
  const s = PART_REQUEST_STATUS.filter(s => s.key === k)

  if (s.length > 0) return {
    key: String(s[0].key),
    value: String(s[0].value)
  }
  else return null
}

export function getPartRequestStatusByKey(k) {
  const s = PART_REQUEST_STATUS.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}

export function getAvailablePartRequestStatuses() {
  return PART_REQUEST_STATUS.filter(f => f.key !== 'NONE')
}


export const REMOTE_SUPPORT_STATUS = [
  {
    key: 'NONE',
    value: 'mainStatuses.none'
  }, {
    key: 'INITIAL',
    value: 'remoteSupportStatuses.initial'
  }, {
    key: 'SERVICE_REVIEW',
    value: 'remoteSupportStatuses.serviceReview'
  }, {
    key: 'WAITING_COMPLETION',
    value: 'remoteSupportStatuses.waitingCompletion'
  }, {
    key: 'REJECTED',
    value: 'remoteSupportStatuses.rejected'
  }, {
    key: 'DONE',
    value: 'remoteSupportStatuses.done'
  }
]
export function getObjectRemoteSupportStatusByKey(k) {
  const s = REMOTE_SUPPORT_STATUS.filter(s => s.key === k)

  if (s.length > 0) return {
    key: String(s[0].key),
    value: String(s[0].value)
  }
  else return null
}

export function getRemoteSupportStatusByKey(k) {
  const s = REMOTE_SUPPORT_STATUS.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}

export function getAvailableRemoteSupportStatuses() {
  return REMOTE_SUPPORT_STATUS.filter(f => f.key !== 'NONE')
}