<template>
  <div>
    <process-tabs
    v-model="tab"
    :tabs="getTabs"
    :status="status"
    :role="getRole"/>
    <process-content
    v-if="isTabSelected"
    v-model="model"
    :tab="getTabs[tab]"
    :role="getRole"
    :disabled="disabled"/>
  </div>
</template>
<script>
import ProcessTabs from '@/components/common/process-tabs/ProcessTabs'
import ProcessContent from '@/components/common/process-tabs/ProcessContent'
export default {
  components: {
    ProcessTabs,
    ProcessContent
  },
  props: {
    value: Object,
    tabs: {
      type: Array,
      required: true
    },
    status: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  },
  watch: {
    'status': {
      handler() {
        this.tab = this.findSelectedTab(this.getTabs, this.status.key, this.getRole)
      },
      immediate: true
    },
    'tab': {
      handler() {
        if (this.isTabSelected) {
          this.disabled = !this.findByStatus(this.getTabs[this.tab], this.status.key, this.getRole)
        }
      },
      immediate: true
    }
  },
  computed: {
    //repair, part_request...
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    },
    getRole() {
      return this.role
    },
    getTabs() {
      return this.filterByRole(this.tabs, this.getRole)
    },
    isTabSelected() {
      return this.tab !== -1
    }
  },
  data: () => {
    return {
      tab: -1,
      disabled: false
    }
  },
  methods: {
    //Using by children
    filterByRole(tabs, r) {
      const filterTabs = []
      for (const t of tabs) {
        let isPushed = false
        if (t.items) {
          for (const i of t.items) {
            if (i.roles.includes(r)) {
              isPushed = true
              break
            }
          }
        }
        else {
          if (t.roles.includes(r)) isPushed = true
        }

        if (isPushed) filterTabs.push(t)
      }

      return filterTabs
    },
    //Using by children
    findByStatus(tab, status, role) {
      if (tab.items) {
        for (const it of tab.items.filter(f => f.roles.includes(role))) {
          if (it.statuses.includes(status)) return true
        }
        return false
      }
      else {
        return tab.roles.includes(role) && tab.statuses.includes(status)
      }
    },
    //Using by children
    findSelectedTab(tabs, status, role) {
      for (let i = 0; i < tabs.length; i++) {
        const t = tabs[i]
        if (t.items) {
          for (const it of t.items.filter(f => f.roles.includes(role))) {
            if (it.statuses.includes(status)) {
              return i
            }
          }
        }
        else {
          if (t.statuses.includes(status) && t.roles.includes(role)) return i
        }
      }

      return -1
    }
  }
}
</script>
<style>

</style>