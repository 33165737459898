<template>
  <div>
    <p class="text-center font-weight-bold">
      {{$t(title)}}
    </p>
    <div
    v-if="isDataReady"
    class="d-flex justify-center align-center">
      <component
      v-for="(b, i) in config"
      :key="i"
      :is="b.component"
      :loading="loadingProp"
      v-bind="b.props"
      v-on="b.events"/>
    </div>
  </div>
</template>
<script>
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import OutlinedBtn from '@/components/btns/OutlinedBtn'
import { getBtnDataByName } from '@/data/process-repair-quick-btns-config'
import { execHttpRequestThrowable } from '@/services/http'
export default {
  inject: [
    'tryToFetchData',
    'updateEntityStatus'
  ],
  components: {
    PrimaryBtn,
    OutlinedBtn
  },
  props: {
    loading: Boolean,
    config: {
      type: Array,
      required: true
    },
    onSuccessRepairStatusMessage: {
      type: String,
      default: () => {
        return null
      }
    },
    title: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data: () => {
    return {
      isDataReady: false
    }
  },
  watch: {
    'config': {
      handler() {
        this.isDataReady = false
        this.processConfig()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    loadingProp: {
      get: function() {
        return this.$props.loading
      },
      set: function(v) {
        this.$emit('update:loading', v)
      }
    }
  },
  methods: {
    processConfig() {
      for (const b of this.config) {
        const d = getBtnDataByName(b.name)
        for (const [k, v] of Object.entries(d.props)) {
          b.props[k] = v
        }
        b.props.label = this.$t(b.props.label)
        //TODO: do loop for events too
        b.component = d.component

        if (b.name === 'Continue' || (b.events === 'AsContinue')) {
          if (!b.events || typeof(b.events) === 'string') b.events = {}

          b.events['click'] = async () => await this.tryToUpdateEntityStatus()
        }
      }
      
      this.isDataReady = true
    },
    async tryToUpdateEntityStatus() {
      const r = await execHttpRequestThrowable(async () => await this.updateEntityStatus(this.$route.params.id), {}, v => this.loadingProp = v)
      this.openSuccessDialog(this.$t(r.response.data !== 'REJECTED' ? this.onSuccessRepairStatusMessage : 'dialogs.messages.success.repairStatusSetToRejected'))
      this.tryToFetchData()
    },
    openSuccessDialog(message) {
      this.$successDialog.open(null, message, null)
    }
  }
}
</script>
<style>

</style>