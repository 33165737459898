<template>
  <v-container class="d-flex justify-center align-center pt-10">
    <v-card
    width="500"
    min-height="780"
    class="rounded-0 primary-background-color px-8 pb-3"
    elevation="5">
      <v-form
      v-model="isFormValid">
        <v-row
        dense
        no-gutters>
          <v-col class="pt-8">
            <div
            class="new-user-title-box d-flex justify-center align-center">
              <v-icon dark class="mr-2">
                mdi-lead-pencil
              </v-icon>
              <span>{{$t('pages.requestAccount.cardTitle')}}</span>
            </div>
          </v-col>
        </v-row>
        <p class="process-repair-tab-section-title mt-5" style="font-size: 20px; position: relative; top: 20px;">{{$t('pages.repair.contactDetails')}}</p>

        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-first-name
            v-model="contact.firstName"
            required
            :label="$t('textFields.firstName')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-last-name
            v-model="contact.lastName"
            required
            :label="$t('textFields.lastName')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-phone
            v-model="contact.phone"
            required
            :label="$t('textFields.phone')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-email
            v-model="contact.email"
            required
            :label="$t('textFields.email')"/>
          </v-col>
        </v-row>

        <p class="process-repair-tab-section-title mt-5" style="font-size: 20px; position: relative; top: 20px;">{{$t('pages.repair.addressDetails')}}</p>

        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.companyName')"
            v-model="address.companyName"
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.country')"
            v-model="address.country"
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.state')"
            v-model="address.province"/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.city')"
            v-model="address.city"
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.postalCode')"
            v-model="address.postalCode"
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.street')"
            v-model="address.street"
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.building')"
            v-model="address.building"/>
          </v-col>
        </v-row>

        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col class="d-flex justify-center align-center pb-4">
            <primary-btn
            @click="tryToCreateNewAccountRequest"
            :disabled="!isFormValid"
            :label="$t('btns.request')"
            :loading="isLoading"
            color='#0277bd'
            :width="150"
            :height="50"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import TextFieldFirstName from '@/components/text-fields/TextFieldFirstName'
import TextFieldLastName from '@/components/text-fields/TextFieldLastName'
import TextFieldPhone from '@/components/text-fields/TextFieldPhone'
import TextFieldEmail from '@/components/text-fields/TextFieldEmail'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { execHttpRequestThrowable } from '@/services/http'
import { routeFromRoot } from '@/services/routeService'
import { requestAccount } from '@/repositories/contactRepo'
export default {
  components: {
    TextFieldFirstName,
    TextFieldLastName,
    TextFieldPhone,
    TextFieldEmail,
    TextFieldValue,
    PrimaryBtn
  },
  data() {
    return {
      contact: {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      },
      address: {
        companyName: '',
        country: '',
        province: '',
        city: '',
        postalCode: '',
        street: '',
        building: ''
      },
      isFormValid: false,
      isLoading: false
    }
  },
  computed: {
  },
  methods: {
    async tryToCreateNewAccountRequest() {
      const messages = {
        _200: 'dialogs.messages.success.requestAccountMailSend'
      }

      const data = {
        contact: this.contact,
        address: this.address
      }
      await execHttpRequestThrowable(async () => await requestAccount(data), messages, v => this.isLoading = v)
      routeFromRoot('login')
    }
  },
  mounted() {
    this.$feedback.fill("Create Account Request Page", this.$route.path)
  }
}
</script>
<style>

</style>