<template>
  <div>
    <label class="text-input-label">
      {{label}}
    </label>
    <v-text-field
    v-model="model"
    :rules="rules"
    hide-details="auto"
    @input="$emit('input')"
    :dense="normal"
    :disabled="disabled"
    :class="`sts-web-system-label ${disabled ? 'v-input--is-readonly' : ''}`">
      <template #prepend>
        <v-icon
        v-if="icon"
        size="30"
        color="black">
          mdi-account
        </v-icon>
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    label: String,
    required: Boolean,
    value: String,
    normal: Boolean,
    disabled: Boolean,
    max: {
      type: Number,
      default: () => {
        return null
      }
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true,
        (v) => v ? (new RegExp(/^\d{1,}$/).test(v) || this.$t('invalidValue.valueMustBeDigit')) : true,
        (v) => !!v && v.length > 0 && this.max ? (v && (parseInt(v) <= this.max) || this.$t('invalidValue.maxNumberRestriction', {0: this.max})) : true
      ]
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>