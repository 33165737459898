<template>
  <v-row
  dense>
    <v-col>
      <component 
      :is="tab.component" 
      :disabled="disabled" 
      :data.sync="model"/>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: Object,
    tab: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    role: {
      type: String,
      required: true
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  methods: {
    //Used by children
    isRole(roles) {
      return roles.includes(this.role)
    },
    //Used by children
    isNotRole(roles) {
      return !this.isRole(roles)
    },
    //Used by children
    isRepairStatus(statuses) {
      return statuses.includes(this.model.status.key)
    }
  }
}
</script>
<style>

</style>