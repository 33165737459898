import { put, get } from '@/services/http'
import { getUserIdentification, getUserToken } from '@/services/authService'

export function editClient(data) {
  return put(`/api/v1/client/edit`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}

export function fetchAllClients() {
  return get(`/api/v1/client/fetch/all?username=${getUserIdentification()}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}


export function fetchClientsByCriteria(page, size, sort, word, serviceIdentification) {
  return get(`/api/v1/client/fetch-by-criteria?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}${word ? `&word=${word}` : ''}${serviceIdentification ? `&serviceIdentification=${serviceIdentification}` : ''}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}