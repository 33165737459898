import { get, put } from '@/services/http'
import { getUserToken } from '@/services/authService'

export function editAddress(username, data) {
  return put(`/api/v1/address/edit?identification=${username}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function fetchAddressByIdentification() {
  return get(`/api/v1/address/fetch`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
