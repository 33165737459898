<template>
  <v-container class="d-flex justify-center align-center pt-10">
    <v-card
    v-if="isDataReady"
    width="500"
    min-height="780"
    class="rounded-0 primary-background-color px-8 pb-10"
    elevation="5">
      <v-form
      v-model="isFormValid">
        <v-row
        dense
        no-gutters>
          <v-col class="pt-8">
            <div
            v-if="!isReadOnly"
            class="new-user-title-box d-flex justify-center align-center">
              <v-icon dark class="mr-2">
                mdi-lead-pencil
              </v-icon>
              <span>{{$t('pages.remoteSupport.cardTitle')}}</span>
            </div>
            <waiting-approval-label
            v-else
            :status="previewData.initialApproval"
            box/>
          </v-col>
        </v-row>
        <v-row
        v-if="isRole(['SERVICE']) && !isReadOnly"
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <async-text-field-autocomplete-clients
            v-model="client"
            label="textFields.clients"
            item-text="name"
            required
            :service-identification="getIdentification"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-field-value
            v-model="newData.title"
            required
            :readonly="isReadOnly"
            :label="$t('textFields.title')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-area
            v-model="newData.description"
            no-resize
            required
            :readonly="isReadOnly"
            :label="$t('textFields.description')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-field-date
            v-model="newData.preferredDate"
            :readonly="isReadOnly"
            required
            :label="$t('textFields.preferredDateLocal', {'0': getTimeZoneInNumber(new Date())})"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-field-time
            v-model="newData.preferredTime"
            :readonly="isReadOnly"
            required
            :label="$t('textFields.preferredTimeLocal', {'0': getTimeZoneInNumber(new Date())})"/>
          </v-col>
        </v-row>

        <p class="process-repair-tab-section-title" style="font-size: 20px; position: relative; top: 20px;">{{$t('pages.repair.contactDetails')}}</p>

        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-field-first-name
            v-model="newData.contact.firstName"
            :readonly="isReadOnly"
            required
            :label="$t('textFields.firstName')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-field-last-name
            v-model="newData.contact.lastName"
            :readonly="isReadOnly"
            required
            :label="$t('textFields.lastName')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-field-phone
            v-model="newData.contact.phone"
            :readonly="isReadOnly"
            required
            :label="$t('textFields.phone')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col>
            <text-field-email
            v-model="newData.contact.email"
            :readonly="isReadOnly"
            required
            :label="$t('textFields.email')"/>
          </v-col>
        </v-row>

        <v-row
        :class="`mt-8`"
        dense
        no-gutters
        v-for="(e, i) in newData.contact.backupEmails" 
        :key="i">
          <v-col>
            <text-field-email
            v-model="e.value"
            :readonly="isReadOnly"
            required
            append-icon="mdi-minus"
            @click:append="removeBackupEmail(i)"
            :label="$t('textFields.email')"/>
          </v-col>
        </v-row>
        <v-row
        v-if="!isReadOnly"
        class="mt-8"
        dense
        no-gutters>
          <v-col class="d-flex justify-center align-center">
            <v-btn
            @click="addBackupEmail"
            icon>
              <v-icon color="primary">
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
        v-if="!isReadOnly"
        class="mt-8"
        dense
        no-gutters>
          <v-col class="d-flex justify-center align-center">
            <primary-btn
            @click="tryToCreateNewRemoteSupport"
            :disabled="!isFormValid"
            :label="$t('btns.create')"
            :loading="isLoading"
            color='#0277bd'
            :width="150"
            :height="50"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import WaitingApprovalLabel from '@/components/common/process-tabs/WaitingApprovalLabel'
import AsyncTextFieldAutocompleteClients from '@/components/text-fields/AsyncTextFieldAutocompleteClients'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextArea from '@/components/text-fields/TextArea'
import TextFieldFirstName from '@/components/text-fields/TextFieldFirstName'
import TextFieldLastName from '@/components/text-fields/TextFieldLastName'
import TextFieldPhone from '@/components/text-fields/TextFieldPhone'
import TextFieldEmail from '@/components/text-fields/TextFieldEmail'
import TextFieldDate from '@/components/text-fields/TextFieldDate'
import TextFieldTime from '@/components/text-fields/TextFieldTime'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { createRemoteSupport } from '@/repositories/supportRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { routeFromRoot } from '@/services/routeService'
import DateUtil from '@/utils/DateUtil'
import { copyObj } from '@/utils/StringUtil'
import { getUserIdentification, getUserRole } from '@/services/authService'
export default {
  components: {
    WaitingApprovalLabel,
    AsyncTextFieldAutocompleteClients,
    TextFieldValue,
    TextArea,
    TextFieldFirstName,
    TextFieldLastName,
    TextFieldPhone,
    TextFieldEmail,
    TextFieldDate,
    TextFieldTime,
    PrimaryBtn
  },
  props: {
    previewData: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      newData: {
        title: '',
        description: '',
        preferredDate: null,
        preferredTime: null,
        contact: {
          id: null,
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          backupEmails: []
        },
      },
      isDataReady: false,
      client: null,
      isFormValid: false,
      isLoading: false
    }
  },
  computed: {
    isReadOnly() {
      return !!this.previewData
    },
    role() {
      return getUserRole()
    },
    getIdentification() {
      return getUserIdentification()
    }
  },
  methods: {
    addBackupEmail() {
      this.newData.contact.backupEmails.push({
        id: null,
        value: ''
      })
    },
    removeBackupEmail(i) {
      this.contact.backupEmails.splice(i, 1)
    },
    isRole(roles) {
      return roles.includes(this.role)
    },
    async tryToCreateNewRemoteSupport() {
      const data = {
        title: this.newData.title,
        description: this.newData.description,
        preferredDate: new Date(`${this.newData.preferredDate}T${this.newData.preferredTime}:00`).toUTCString(),
        contact: this.newData.contact,
        client: this.client
      }
      await execHttpRequestThrowable(async () => await createRemoteSupport(data), {}, v => this.isLoading = v)
      routeFromRoot('remote-support/list')
    },
    putContactData(data) {
      let cc
      if (data) {
        cc = data
      }
      else cc = this.$contactHolder.get()
      this.newData.contact = {
        firstName: String(cc.firstName),
        lastName: String(cc.lastName),
        phone: String(cc.phone),
        email: String(cc.email),
        backupEmails: [...cc.backupEmails]
      }
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  mounted() {
    this.isDataReady = false
    if (this.previewData) {
      this.newData = copyObj(this.previewData)
    }
    this.$feedback.fill("Create Remote Support Page", this.$route.path)
    this.isDataReady = true
  },
  created() {
    if (this.isRole(['CLIENT'])) {
      this.$watch('$contactHolder.contact', () => {
        this.putContactData()
      }, {
        immediate: true,
        deep: true
      })
    }
    else {
      this.$watch('client', (v) => {
        if (v) this.putContactData(v.contact)
      }, {
        deep: true
      })
    }
  }
}
</script>
<style>

</style>