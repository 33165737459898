import { get, put } from '@/services/http'
import { getUserIdentification, getUserToken } from '@/services/authService'

export function fetchAllServices() {
  return get(`/api/v1/service/fetch/all?username=${getUserIdentification()}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function editService(data) {
  return put(`/api/v1/service/edit`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}

