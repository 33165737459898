<template>
  <v-text-field
  v-if="!normal"
  v-model="model"
  :rules="rules"
  :class="`sts-web-system-label ${special ? 'special' : ''} ${disabled ? 'v-input--is-readonly' : ''}`"
  hide-details="auto"
  :label="label"
  :disabled="disabled"
  :readonly="readonly">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
  <v-text-field
  v-else
  v-model="model"
  :rules="rules"
  :class="`sts-web-system-label normal-text-field ${special ? 'special' : ''} ${disabled ? 'v-input--is-readonly' : ''}`"
  :label="label"
  :disabled="disabled"
  flat
  solo
  hide-details="auto"
  dense
  :readonly="readonly">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    required: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    label: String,
    value: String,
    normal: Boolean,
    min: {
      type: Number,
      default: () => {
        return null
      }
    },
    max: {
      type: Number,
      default: () => {
        return null
      }
    },
    special: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true,
        (v) => {
          if (v !== null && v !== null && this.max) {
            return  String(v).length <= this.max || this.$t('invalidValue.maxSymbols', {'value': this.max})
          }
          return true
        },
        (v) => {
          if (v !== null && v !== null && this.min) {
            return  String(v).length >= this.min || this.$t('invalidValue.minSymbols', {'value': this.min})
          }
          return true
        }
      ]
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
.sts-web-system-label  > div.v-input__control > div.v-input__slot > div.v-text-field__slot > input {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: black;
}
.normal-text-field.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 38px;
  border: 1px solid #49505742;
}
div.special.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #ffffff;
  border: 2px solid #0d47a1;
}
</style>