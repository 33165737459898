<template>
  <v-dialog
  v-model="$confirmActionDialog.model"
  width="500"
  @click:outside="closeDialog">
    <v-card
    color="white"
    width="100%">
      <div class="confirm-action-dialog-header confirm-action-dialog-header-title d-flex justify-space-between align-center px-5">
        <span class="text-uppercase">{{$t($confirmActionDialog.title)}}</span>
        <v-btn
        icon
        dark
        @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="d-flex justify-center align-center pt-4">
        <v-icon size="90" color="#33b5e5">
          mdi-help-circle-outline
        </v-icon>
      </div>
      <div class="d-flex justify-center align-center px-4 pt-4">
        <p class="confirm-action-dialog-content text-center">
          {{$t($confirmActionDialog.subtitle)}}
        </p>
      </div>
      <div 
      class="mt-5"
      style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
      <v-container>
        <v-row dense class="pa-4 pb-6" justify="center">
          <v-col cols="4">
            <outlined-btn
            :label="$t($confirmActionDialog.closeBtnLabel)"
            color="#33b5e5"
            :loading="isLoading"
            block
            :height="45"
            @click="closeDialog"/>
          </v-col>
          <v-col cols="4">
            <primary-btn
            :label="$t($confirmActionDialog.actionBtnLabel)"
            color="#33b5e5"
            :loading="isLoading"
            :width="150"
            :height="45"
            @click="execAction"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import OutlinedBtn from '@/components/btns/OutlinedBtn'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
export default {
  components: {
    OutlinedBtn,
    PrimaryBtn
  },
  props: {
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {

  },
  methods: {
    async execAction() {
      if (this.$confirmActionDialog.action) {
        try {
          this.isLoading = true
          await this.$confirmActionDialog.action()
          this.closeDialog()
        }
        finally {
          this.isLoading = false
        }
      }
      else console.error(this.$t('dialogs.messages.failed.actionNotProvided', {'0': '(Validate Dialog)'}))
    },
    closeDialog() {
      this.$confirmActionDialog.close()
    }
  }
}
</script>
<style>
.confirm-action-dialog-header {
  width: 100%;
  height: 70px;
  background-color: #33b5e5;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.144);
  border-radius: 0px!important;
}
.confirm-action-dialog-header-title {
  font-family: 'Titillium Web', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.confirm-action-dialog-content {
  font-family: 'Titillium Web', sans-serif;
  color: black;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
</style>