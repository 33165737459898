<template>
  <div
  style="position: relative; height: 100%;">
    <changes-dialog v-if="$changesDialog.model"/>
    <success-dialog v-if="$successDialog.model"/>
    <failed-dialog v-if="$failedDialog.model"/>
    <confirm-action-dialog v-if="$confirmActionDialog.model"/>
    <snack-bar v-if="$snackbar.model"/>
    <component :is="getLayoutName"/>
  </div>
</template>
<script>
import ChangesDialog from '@/components/dialogs/ChangesDialog'
import SuccessDialog from '@/components/dialogs/SuccessDialog'
import FailedDialog from '@/components/dialogs/FailedDialog'
import SnackBar from '@/components/common/SnackBar'

import ConfirmActionDialog from '@/components/dialogs/ConfirmActionDialog'
import { selectLanguage } from '@/services/authService'
import { getLayoutByPath } from '@/data/layouts/layouts'
import SignInLayout from '@/layouts/SignInLayout'
import SignOutLayout from '@/layouts/SignOutLayout'
export default {
  components: {
    //layouts
    SignInLayout,
    SignOutLayout,

    ChangesDialog,
    SuccessDialog,
    FailedDialog,
    ConfirmActionDialog,
    SnackBar
  },
  computed: {
    getLayoutName() {
      return getLayoutByPath(this.$route.path)
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
  },
  async mounted() {
    const lang = window.location.hash.substr(2, 2)
    selectLanguage(lang)
    this.$i18n.locale = lang 
  }
}
</script>
<style>

</style>