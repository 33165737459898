<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        number-pages
        :headers="headers"
        :items="items"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :onUpdateSize="tryToFetchContacts"
        :onPerviousBtnClick="tryToFetchContacts"
        :onNextBtnClick="tryToFetchContacts"
        :total-elements="totalElements"
        class="pa-4">
          <template v-slot:[`header.id`]="{ header }">
              {{$t(header.text)}}  
          </template>
          <template v-slot:[`header.username`]="{ header }">
              {{$t(header.text)}}
          </template>
          <template v-slot:[`header.role`]="{ header }">
              {{$t(header.text)}}
          </template>
          <template v-slot:[`header.status`]="{ header }">
              {{$t(header.text)}}
          </template>
          <template v-slot:[`header.details`]="{ header }">
              {{$t(header.text)}}
          </template>

          <template v-slot:[`item.id`]="{ item }">
              <span>
                {{item.id}}
              </span>
          </template>
          <template v-slot:[`item.username`]="{ item }">
              <span>
                {{item.username}}
              </span>
          </template>
          <template v-slot:[`item.role`]="{ item }">
              <span>
                {{$t(getRole(item.role))}}
              </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
              <span v-if="!item.status" class="green--text">
                {{$t('textFields.unlocked')}}
              </span>
              <span v-else class="red--text">
                {{$t('textFields.locked')}}
              </span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
              <v-btn 
              @click="routeTo(`edit/contact/${item.username}`)"
              class="detail-btn rounded-0" 
              width="70" 
              height="30">
                {{$t('btns.edit')}}
              </v-btn>
          </template>
        </main-data-table>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4"
    name="export.title.users"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import { fetchContactsByCriteria } from '@/repositories/contactRepo'
import { routeFromRoot } from '@/services/routeService'
import { execHttpRequestThrowable } from '@/services/http'
import { getRoleByKey } from '@/utils/rolesUtil'
import { isLanguageEn } from '@/services/authService'
import { exportUsersXlsx } from '@/repositories/exportRepo'

import MainDataTable from '@/components/common/MainDataTable'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
export default {
  components: {
    MainDataTable,
    ExportXlsxRow
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.userId',
          align: 'start',
          sortable: true,
          value: 'id'
        },{
          text: 'headers.username',
          align: 'start',
          sortable: true,
          value: 'username'
        },{
          text: 'headers.role',
          align: 'start',
          sortable: true,
          value: 'role'
        },{
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        }, {
          text: 'headers.details',
          align: 'start',
          sortable: true,
          value: 'details'
        }
      ],
      items: [],
      totalElements: 0,
      page: 1,
      size: 10,
      isLoading: false
    }
  },
  computed: {
    isEN() {
      return isLanguageEn()
    },
    getExporter() {
      return exportUsersXlsx
    }
  },
  methods: {
    routeTo(link) {
      routeFromRoot(link)
    },
    async tryToFetchContacts() {
      this.items = []
      const r = await execHttpRequestThrowable(async () => await fetchContactsByCriteria(this.size, this.page - 1), {}, v => this.isLoading = v)
      const p = r.response.data
      this.totalElements = p.totalElements
      this.items = p.content
    },
    getRole(r) {
      return getRoleByKey(r)
    }
  },
  async mounted() {
    await this.tryToFetchContacts()
  },
  created() {
    this.$feedback.fill("Contact List Page", this.$route.path)
  }
}
</script>
<style>

</style>