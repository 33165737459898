<template>
  <v-container
  v-if="isDataReady"
  fluid>
    <v-row v-if="$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])" dense no-gutters>
        <v-col>
          <p class="process-repair-tab-section-subtitle text-center">{{$t('pages.repair.partsAreInWaitingApproval')}}</p>
        </v-col>
    </v-row>
    <v-row dense no-gutters>
        <v-col v-if="$parent.isRole(['ADMIN', 'SUPERVISOR'])">
          <p class="process-repair-tab-section-title">{{$t('pages.repair.partsForRepairApproval')}}</p>
        </v-col>
        <v-col v-if="$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])">
          <p class="process-repair-tab-section-title">{{$t('pages.repair.partsInfo')}}</p>
        </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        :headers="getHeaders"
        :items="newData.repairParts"
        number-pages
        :page.sync="page"
        :size="-1"
        :items-per-page-options="[-1]"
        :total-elements="-1"
        hide-default-footer>
          <template v-slot:[`header.isApproved`]="{ }">
              <span v-if="$parent.isRole(['SERVICE'])">{{$t('headers.supervisorStatus')}}</span>
              <span v-else-if="$parent.isRole(['ADMIN', 'SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])">{{$t('headers.status')}}</span>
          </template>
          <template v-slot:[`header.approval`]="{ header }">
              {{$t(header.text)}}
          </template>


          <template 
          v-slot:[`item.mark`]="{ item }">
              <div
              v-if="!isDiagnosticStatus(item, ['IT_IS_WORKING'])"
              class="d-flex justify-center align-center">
                <v-checkbox
                v-model="item.mark"
                :disabled="disabled"/> 
              </div>
              <div v-else></div>
          </template>
          <template v-slot:[`item.imageUrl`]="{ item }">
              <previewable-image
              v-model="item.imageUrl"
              height="65"
              width="50"
              class="py-2"/>
          </template>
          <template 
          v-if="$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])"
          v-slot:[`item.problemDescription`]="{ item }">
              <div class="py-2">
                <span>
                  {{item.problemDescription}}
                </span>  
              </div>
          </template>
          <template 
          v-if="$parent.isRole(['SERVICE'])"
          v-slot:[`item.serviceStatus`]="{ item }">
              <span v-if="isDiagnosticStatus(item, ['IT_IS_WORKING'])">
                {{$t('repairStatus.itIsWorking')}}
              </span>
              <span v-else-if="isDiagnosticStatus(item, ['FOR_REPAIR'])">
                {{$t('repairStatus.forRepair')}}
              </span>  
              <span v-else-if="isDiagnosticStatus(item, ['FOR_REPLACE'])">
                {{$t('repairStatus.forReplace')}}
              </span>
              <span v-else-if="isDiagnosticStatus(item, ['NON_REPAIRABLE'])">
                {{$t('repairStatus.nonRepairable')}}
              </span>
          </template>
          <template 
          v-if="$parent.isNotRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])"
          v-slot:[`item.comment`]="{ item }">
              <div class="py-2">
                <span>
                  {{item.diagnosticInfo.comment}}
                </span>
              </div>
          </template>
          <template 
          v-if="$parent.isNotRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])"
          v-slot:[`item.totalCost`]="{ item }">
              <span>
                {{item.diagnosticInfo.totalCost}}
              </span>
          </template>
          <template 
          v-slot:[`item.isApproved`]="{ item }">
            <span
            v-if="isDiagnosticStatus(item, ['IT_IS_WORKING'])"
            class="green--text"
            style="font-weight: 300;">
              {{$t('repairStatus.itIsWorking')}}
            </span>  
            <div
            v-else>
              <span v-if="isRepairApprovalStatus(item, ['APPROVED'])" class="green--text" style="font-weight: 300;">
                {{$t('initialStatuses.approved')}}
              </span>
              <span v-else-if="isRepairApprovalStatus(item, ['REJECTED'])" class="red--text" style="font-weight: 300;">
                {{$t('initialStatuses.rejected')}}
              </span>
              <span v-else-if="isRepairApprovalStatus(item, ['WAITING_APPROVAL'])" class="orange--text" style="font-weight: 300;">
                {{$t('initialStatuses.waitingApproval')}}
              </span>
            </div>
          </template>
          <template 
          v-if="$parent.isRole(['ADMIN', 'SUPERVISOR'])"
          v-slot:[`item.approval`]="{ item }">
              <div 
              v-if="!isDiagnosticStatus(item, ['IT_IS_WORKING'])"
              class="d-flex justify-start">  
                <primary-btn
                @click="changeInitialStatusSingle(item, 'REJECTED')"
                :label="$t('btns.reject')"
                color="#ff3547"
                class="mx-2"
                :disabled="disabled || isRepairApprovalStatus(item, ['REJECTED'])"
                :loading="isLoading"
                small-text
                :width="100"
                :height="30"/>
                <primary-btn
                @click="changeInitialStatusSingle(item, 'APPROVED')"
                :label="$t('btns.approve')"
                color="#4285f4"
                class="mx-2"
                :loading="isLoading"
                :disabled="disabled || isRepairApprovalStatus(item, ['APPROVED'])"
                small-text
                :width="100"
                :height="30"/>
              </div>
          </template>
        </main-data-table>
        <v-row 
        v-if="$parent.isRole(['ADMIN', 'SUPERVISOR'])"
        dense class="mt-5">
          <v-col>
            <p class="text-center"
            style="font-weight: bold">
              {{$t('common.pressContinueWhenReady')}}
            </p>
            <process-repair-quick-btns
            :config="getQuickBtnsConfig"
            :loading.sync="isLoading"
            on-success-repair-status-message="dialogs.messages.success.firstDetailsCompleted"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="newData.repairParts.length === 0"
    :loading.sync="isLoading"
    class="px-4 mt-4"
    name="export.title.repairApprovalTab"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import MainDataTable from '@/components/common/MainDataTable'
import PreviewableImage from '@/components/common/PreviewableImage'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { changePartMainStatus} from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getTextByKeyInitialStatus } from '@/utils/statusesUtil'
import { copyObj, compareArrays } from '@/utils/StringUtil'
import { exportRepairApprovalTabXlsx } from '@/repositories/exportRepo'
export default {
  components: {
    ProcessRepairQuickBtns,
    MainDataTable,
    PreviewableImage,
    ExportXlsxRow,
    PrimaryBtn
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      headers: [{
          text: 'headers.mark',
          align: 'start',
          sortable: true,
          value: 'mark'
        },{
          text: 'headers.image',
          align: 'start',
          sortable: true,
          value: 'imageUrl'
        },{
          text: 'headers.category',
          align: 'start',
          sortable: true,
          value: 'category'
        },{
          text: 'headers.partName',
          align: 'start',
          sortable: true,
          value: 'partName'
        }, {
          text: 'headers.serialNumber',
          align: 'start',
          sortable: true,
          value: 'serialNumber'
        }, {
          text: 'headers.serviceTag',
          align: 'start',
          sortable: true,
          value: 'serviceTag'
        },{
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'serviceStatus'
        }, {
          text: 'headers.problemDescription',
          align: 'start',
          sortable: true,
          value: 'problemDescription'
        }, {
          text: 'headers.comment',
          align: 'start',
          sortable: true,
          value: 'comment'
        }, {
          text: 'headers.totalCost',
          align: 'start',
          sortable: true,
          value: 'totalCost'
        }, {
          text: '',
          align: 'start',
          sortable: true,
          value: 'isApproved'
        }, {
          text: 'headers.repairApproval',
          align: 'start',
          sortable: true,
          value: 'approval'
        }
      ],
      newData: null,
      page: 1,
      isLoading: false,
      isDataReady: false
    }
  },
  computed: {
    repairProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.$parent.isRole(['ADMIN', 'SUPERVISOR'])) {
          if (h.value === 'serviceStatus' ||
          // h.value === 'isApproved' ||
          h.value === 'problemDescription') continue
        }
        else if (this.$parent.isRole(['SERVICE'])) {
          if (h.value === 'mark' ||
          h.value === 'approval'||
          h.value === 'problemDescription') continue
        }
        else if (this.$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])) {
          if (h.value === 'mark' ||
          h.value === 'serviceStatus' ||
          h.value === 'comment' ||
          h.value === 'totalCost' ||
          h.value === 'approval') continue
        }
        
        if (this.disabled) {
          if (h.value === 'mark') continue
        }

        headers.push(h)
      }

      return headers
    },
    isMarkedApprovedBtnDisable() {
      return this.newData.repairParts.filter(p => p.mark === true).length === 0
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'RejectAll',
          events: {
            'click': () => this.setChangedDialogItems('REJECTED', false)
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'ApproveAll',
          events: {
            'click': () => this.setChangedDialogItems('APPROVED', false)
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'ApproveMarked',
          events: {
            'click': () => this.setChangedDialogItems('APPROVED', true)
          },
          props: {
            'disabled': this.disabled || this.isMarkedApprovedBtnDisable
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    },
    getExporter() {
      return async () => await exportRepairApprovalTabXlsx(this.$route.params.id)
    }
  },
  methods: {
    async changeInitialStatusSingle(part, status) {
      await this.tryToChangeRepairStatus([{
        id: part.id,
        repairApproval: status
      }], false)
    },
    async tryToChangeRepairStatus(parts, showSuccessDialog) {
      const data = parts.map(p => {
        return {
          repairPartId: p.id,
          status: p.repairApproval
        }
      })
      const status = data[0].status

      const messages = {
        ignoreSuccessDialog: !showSuccessDialog,
        _200: this.$t(status === 'APPROVED' ? 'dialogs.messages.success.approvalDecisionSaved' : 'dialogs.messages.success.rejectionDecisionSaved')
      }

      const r = await execHttpRequestThrowable(async () => await changePartMainStatus(this.$route.params.id, data),
      messages,
      v => this.isLoading = v)
      const ids = parts.map(p => p.id)
      //Update server parts
      this.repairProp.repairParts
        .filter(p => ids.includes(p.id))
        .forEach(p => p.repairApproval = String(status))
        //Update local parts
      this.newData.repairParts
        .filter(p => ids.includes(p.id))
        .forEach(p => p.repairApproval = String(status))

      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItems(status, isMarkBtn) {
      const filteredParts = this.newData.repairParts.filter(p => ((isMarkBtn && p.mark && p.repairApproval !== status) || (!isMarkBtn && p.repairApproval !== status)) && !this.isDiagnosticStatus(p, ['IT_IS_WORKING']))
      const newParts = filteredParts.map(p => {
        return {
          rowNumber: p.rowNumber,
          repairApproval: status
        }
      })
      const oldParts = filteredParts.map(p => {
        return {
          rowNumber: p.rowNumber,
          repairApproval: p.repairApproval
        }
      })
      const props = [
        {
          field: 'headers.repairApproval',
          prop: 'repairApproval',
          getter: getTextByKeyInitialStatus
        }
      ]
      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareArrays(oldParts, newParts, props),
        async () => await this.tryToChangeRepairStatus(filteredParts.map(p => {
          return {
            id: p.id,
            repairApproval: status
          }
        }), true),
        true
      )
    },
    isDiagnosticStatus(i, s) {
      return s.includes(i.diagnosticInfo.status)
    },
    isInitialStatus(i, s) {
      return s.includes(i.initialApproval)
    },
    isRepairApprovalStatus(i, s) {
      return s.includes(i.repairApproval)
    }
  },
  mounted() {
    this.newData = {
      isAllowedToContinue: this.repairProp.isAllowedToContinue,
      repairParts: this.repairProp.repairParts
        .filter(rp => rp.initialApproval === 'APPROVED')
        .map((rp, i) => {
          const newObj = copyObj(rp)
          newObj.mark = this.$parent.isRole(['ADMIN', 'SUPERVISOR']) ? false : null
          newObj.rowNumber = i + 1
          return newObj
        })
    }
    this.isDataReady = true
  }
}
</script>
<style>

</style>