import { get, put, post, _delete } from '@/services/http'
import { getUserToken } from '@/services/authService'

export function notifyServiceForNewPartRequest(id) {
  return put(`/api/v1/parts/notify-service-part-request-creation?partRequestId=${id}`, {}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function createNewPartRequestItems(data, id) {
  return post(`/api/v1/parts/new-part-request-items${id ? `?partRequestId=${id}` : ''}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function createNewPartRequestSuggestItem(data, id) {
  return post(`/api/v1/parts/new-part-request-item-suggest${id ? `?partRequestId=${id}` : ''}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getUserToken()
    }
  })
}
export function fetchPartRequestByCriteria(size, page, sort) {
  return get(`/api/v1/parts/fetch-part-request-by-criteria?page=${page}&size=${size}&sort=${sort}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchPartRequestById(id) {
  return get(`/api/v1/parts/fetch/part-request?id=${id}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function updatePartRequestItemsInitialApproval(id, data) {
  return post(`/api/v1/parts/update/part-request/initial-approval?id=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function fetchHistoryByCriteria(id, size, page, sort) {
  return get(`/api/v1/parts/fetch/part-request/history?id=${id}&size=${size}&page=${page}&sort=${sort}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function updatePartRequestStatus(id, status) {
  return put(`/api/v1/parts/update-part-request-status?partRequestId=${id}${status ? `&status=${status}` : ''}`, {}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function updateOneWayShipping(id, data) {
  return post(`/api/v1/parts/update/part-request/one-way-shipping?id=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function deletePartRequestInShippingById(id) {
  return _delete(`/api/v1/parts/delete/shipping-details?id=${id}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function updatePartRequestTransportArrangement(id, data) {
  return post(`/api/v1/parts/update/part-request/transport-arrangement?id=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}