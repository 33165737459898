<template>
  <div>
    <label class="text-input-label">
      {{$t(label)}}
    </label>
    <v-text-field
    v-model="model"
    :placeholder="$t(placeholder)"
    class="text-input text-input-border-color mt-3"
    hide-details="auto"
    solo
    :dense="normal"
    flat
    />
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    placeholder: String,
    value: String,
    normal: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  }
}
</script>
<style>
.text-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; 
  
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

div.text-input > div.v-input__control > div.v-input__slot > div.v-text-field__slot > input {
  color: #495057;
}

.text-input-border-color.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined {
  border: 1px solid #49505742;
}

.text-input-label {
  color: #495057;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
}
</style>