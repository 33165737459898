<template>
  <v-text-field
  v-if="!normal"
  v-model="model"
  :rules="rules"
  :class="`sts-web-system-label ${disabled ? 'v-input--is-readonly' : ''}`"
  :style="`${width ? `width: ${width};` : ''}`"
  hide-details="auto"
  :disabled="disabled"
  :readonly="readonly"
  @input="emitInput"
  :label="label">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
  <v-text-field
  v-else
  v-model="model"
  :rules="rules"
  :class="`sts-web-system-label normal-text-field ${disabled ? 'v-input--is-readonly' : ''}`"
  :style="`${width ? `width: ${width};` : ''}`"
  hide-details="auto"
  flat
  dense
  :readonly="readonly"
  @input="emitInput"
  solo
  :disabled="disabled"
  :label="label">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    label: String,
    required: Boolean,
    value: [String, Number],
    disabled: Boolean,
    normal: Boolean,
    double: Boolean,
    readonly: Boolean,
    width: String
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => {
          if (this.required) {
            return (v !== null && v !== undefined) || this.$t('invalidValue.required')
          }
          else return true
        },
        (v) => {
          if (!!v && v.length > 0) {
            return new RegExp(this.double ? /^\d+?(\.\d{1,2})?$/ : /^\d+?$/).test(v) || this.$t('invalidValue.valueMustBeDigit')
          }
          else return true
        }
      ]
    }
  },
  methods: {
    emitInput() {
      this.$emit('input')
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>