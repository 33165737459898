import { es } from '@/languages/es.js'
import { en } from '@/languages/en.js'

import Vue from 'vue';
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: ['es', 'en'],
    defaultLocale: 'en',
    fallbackLocale: 'en',
    messages: {
      es,
      en
    }
})