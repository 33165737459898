import Vue from 'vue'

class ContactHolderPlugin {
  contact
  constructor() {
    this.contact = {
      id: -1,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      backupEmails: []
    }
  }
  get() {
    return this.contact
  }
  set(c) {
    this.contact.id = c.id
    this.contact.firstName = c.firstName
    this.contact.lastName = c.lastName
    this.contact.phone = c.phone
    this.contact.email = c.email
    if (c.backupEmails) this.contact.backupEmails = [...c.backupEmails]
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$contactHolder = Vue.observable(new ContactHolderPlugin())
  }
}