import { v4 as uuidGenerate } from 'uuid'

const dynamicNotifiers = {}

export const DynamicNotifier = {
  request: (e) => {
    const n = {
      id: uuidGenerate(),
      static: '0',
      dynamic: '1'
    }
    if (!Object.keys(dynamicNotifiers).includes(e)) dynamicNotifiers[e] = []
    dynamicNotifiers[e].push(n)
    return n
  },
  notify: (e) => {
    if (!Object.keys(dynamicNotifiers).includes(e)) {
      console.error(`There is not such specified event - ${e}! You must request it first!`)
      return
    }
    for (const s of dynamicNotifiers[e]) s.dynamic = '1'
  },
  reset: (n) => {
    n.dynamic = '0'
  },
  delete: (id, e) => {
    if (!Object.keys(dynamicNotifiers).includes(e)) {
      console.error(`There is not such specified event - ${e}! You must request it first!`)
      return
    }
    dynamicNotifiers[e] = dynamicNotifiers[e].filter(n => n.id != id)
  }
}