<template>
  <v-container 
  v-if="isDataReady"
  fluid>
    <div 
    v-if="$parent.isRole(['ADMIN', 'CLIENT'])"
    class="d-flex flex-column pb-10">
      <h4>
        {{$t('pages.repair.updateDataShippingDetailsTab')}}
      </h4>
      <h4 class="red--text">
        {{$t('pages.repair.dontSendRejectedParts')}}
      </h4>
    </div>
    <v-row dense>
        <v-col>
          <p class="process-repair-tab-section-title">{{$t('pages.repair.contactDetails')}}</p>
        </v-col>
    </v-row>
    <v-form
    ref="form"
    v-model="isFormValid">
      <v-row dense>
        <v-col class="mr-4">
          <text-field-value 
          v-model="getAddress.companyName" 
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          :label="$t('textFields.companyName')"
          required/>
        </v-col>
        <v-col class="mr-4">
          <text-field-first-name 
          v-model="getContact.firstName" 
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col class="ml-4">
          <text-field-last-name 
          v-model="getContact.lastName" 
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-col class="mr-4">
          <text-field-phone 
          v-model="getContact.phone" 
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col class="ml-4">
          <text-field-email
          :label="$t('textFields.email')"
          v-model="getContact.email" 
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
      </v-row>
      <v-row v-for="(r, i) in getBackupEmailsPositions()" 
      class="mt-5"
      :key="i + 100"  
      dense 
      no-gutters >
        <v-col v-if="r.col1 && r.col1 !== 'btn'" class="mr-4">
          <text-field-email
          :label="$t('textFields.email')"
          v-model="r.col1.value"
          @click:append="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE']) ? '' : removeNewBackUpEmail(r.col1)"
          required
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          append-icon="mdi-minus"/>
        </v-col>
        <v-col v-else-if="r.col1 && r.col1 === 'btn'" class="d-flex justify-center align-center mr-4">
          <v-btn
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          @click="addNewBackUpEmail"
          icon>
            <v-icon color="blue">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="r.col2 && r.col2 !== 'btn'" class="ml-4">
          <text-field-email
          :label="$t('textFields.email')"
          v-model="r.col2.value"
          @click:append="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE']) ? '' : removeNewBackUpEmail(r.col2)"
          append-icon="mdi-minus"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col v-else-if="r.col2 && r.col2 === 'btn'" class="d-flex justify-center align-center ml-4">
          <v-btn
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          @click="addNewBackUpEmail"
          icon>
            <v-icon color="blue">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
      v-if="getBackupEmailsPositions().length === 0">
        <v-col class="d-flex justify-center align-center ml-4">
          <v-btn
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          @click="addNewBackUpEmail"
          icon>
            <v-icon color="blue">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="mt-10">
          <v-col>
            <p class="process-repair-tab-section-title">{{$t('pages.repair.addressDetails')}}</p>
          </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col>
          <text-field-value 
          v-model="getAddress.country" 
          :label="$t('textFields.country')" 
          class="mr-4" 
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col>
          <text-field-value 
          v-model="getAddress.province" 
          :label="$t('textFields.state')" 
          class="ml-4" 
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="mt-5">
        <v-col>
          <text-field-value 
          v-model="getAddress.city" 
          :label="$t('textFields.city')" 
          class="mr-4"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col>
          <text-field-value 
          v-model="getAddress.postalCode" 
          :label="$t('textFields.postalCode')" 
          class="ml-4"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="mt-5">
        <v-col>
          <text-field-value 
          v-model="getAddress.street" 
          :label="$t('textFields.streetDetails')" 
          class="mr-4"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col>
          <text-field-value 
          v-model="getAddress.building"
          :label="$t('textFields.building')" 
          class="ml-4"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="mt-10">
        <v-col>
          <p class="process-repair-tab-section-title">{{$t('pages.repair.packageDetails')}}</p>
          <p class="process-repair-tab-section-subtitle">{{$t('pages.repair.addPackageInfo')}}</p>
        </v-col>
        <v-col 
        class="d-flex justify-center"
        :cols="getPackages.length === 0 ? 3 : 4">
          <p class="process-repair-tab-section-subtitle mt-13 ml-16">{{$t('headers.addRemovePackage')}}</p>
        </v-col>
      </v-row>
      <v-row 
      v-for="(p, i) in getPackages" 
      :key="i + 200"
      dense >
        <v-col cols="6">
          <text-field-dimensions 
          :label="$t('textFields.dimensions')" 
          v-model="p.dimensions" 
          class="mr-4"
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col cols="4">
          <text-field-number 
          :label="$t('textFields.weight')" 
          v-model="p.weight" 
          class="ml-4"
          double
          :disabled="disabled || $parent.isRole(['SUPERVISOR', 'SERVICE'])"
          required/>
        </v-col>
        <v-col v-if="$parent.isRole(['ADMIN', 'CLIENT'])" cols="2" class="d-flex jusitfy-center align-center">
          <primary-btn 
          @click="removePackage(i)"
          label="-"
          :disabled="disabled"
          color="#ff3547"
          :width="70"
          :height="40"/>
        </v-col>
      </v-row>
    </v-form>
    <v-row
    v-if="$parent.isRole(['ADMIN', 'CLIENT']) && getPackages.length < 5"
    dense
    justify="end">
        <v-col cols="1" class="d-flex jusitfy-center align-center">
          <primary-btn 
          @click="addPackage"
          label="+"
          color="#61c461"
          :disabled="disabled"
          :width="70"
          :height="40"/>
        </v-col>
        <v-col cols="1" class="d-flex jusitfy-center align-center">
          <primary-btn 
          v-if="getPackages.length === 0"
          label="-"
          disabled
          color="#ff3547"
          :width="70"
          :height="40"/>
        </v-col>
    </v-row>
    <v-row 
    v-if="$parent.isRole(['ADMIN', 'CLIENT'])"
    dense 
    class="my-10">
      <v-col>
        <p class="text-center"
        style="font-weight: bold">
          {{$t('common.pressContinueWhenReady')}}
        </p>
        <process-repair-quick-btns 
        :loading.sync="isLoading"
        :config="getQuickBtnsConfig"
        on-success-repair-status-message="dialogs.messages.success.toWaitingForPickUpStep"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col
      class="d-flex justify-end">
        <primary-btn
        v-if="$parent.isRole(['ADMIN'])"
        @click="openDeleteDialog"
        color="red"
        :width="120"
        :height="45"
        :disabled="disabled"
        :loading="isLoading"
        class="mx-2"
        :label="$t('btns.delete')"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextFieldFirstName from '@/components/text-fields/TextFieldFirstName'
import TextFieldLastName from '@/components/text-fields/TextFieldLastName'
import TextFieldPhone from '@/components/text-fields/TextFieldPhone'
import TextFieldEmail from '@/components/text-fields/TextFieldEmail'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextFieldDimensions from '@/components/text-fields/TextFieldDimensions'
import TextFieldNumber from '@/components/text-fields/TextFieldNumber'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import { updateOneWayShipping, deletePartRequestInShippingById } from '@/repositories/partRequestRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { copyObj, cloneObj, compareObjects } from '@/utils/StringUtil'
import { routeFromRoot } from '@/services/routeService'
export default {
  name: 'ShippingDetailsTabPartRequest',
  components: {
    TextFieldFirstName,
    TextFieldLastName,
    TextFieldPhone,
    TextFieldEmail,
    TextFieldValue,
    TextFieldDimensions,
    TextFieldNumber,
    PrimaryBtn,
    ProcessRepairQuickBtns
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      isFormValid: false,
      newData: null,
      isLoading: false,
      isDataReady: false
    }
  },
  methods: {
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.pickUpDataSavedSuccessfully'
      }

      const r = await execHttpRequestThrowable(
        async () => await updateOneWayShipping(this.$route.params.id, this.newData.onewayShipping),
        messages,
        v => this.isLoading = v)
      cloneObj(this.newData.onewayShipping, this.partRequestProp.onewayShipping)
      this.newData.isAllowedToContinue = r.response.data
    },
    async tryToDeleteRepair() {
      const messages = {
        _200: 'dialogs.messages.success.partRequestDeletedSuccessfully'
      }

      await execHttpRequestThrowable(async () => await deletePartRequestInShippingById(this.$route.params.id), messages, v => this.isLoading = v)
      routeFromRoot('part-request/list')
    },
    openDeleteDialog() {
      this.$confirmActionDialog.open('pages.partRequest.deletePartRequest',
      null,
      'btns.delete',
      null,
      this.tryToDeleteRepair)
    },
    setChangedDialogItems() {
      const old = this.partRequestProp.onewayShipping
      const _new = this.newData.onewayShipping

      const props = [
        {
          field: 'textFields.firstName',
          prop: 'contact.firstName'
        }, {
          field: 'textFields.lastName',
          prop: 'contact.lastName'
        }, {
          field: 'textFields.phone',
          prop: 'contact.phone'
        }, {
          field: 'textFields.email',
          prop: 'contact.email'
        }, {
          field: {
            value: 'textFields.backupEmailNo',
            params: null
          },
          prop: 'contact.backupEmails[].value'
        }, {
          field: 'textFields.companyName',
          prop: 'address.companyName'
        }, {
          field: 'textFields.country',
          prop: 'address.country'
        }, {
          field: 'textFields.state',
          prop: 'address.province'
        }, {
          field: 'textFields.city',
          prop: 'address.city'
        }, {
          field: 'textFields.postalCode',
          prop: 'address.postalCode'
        }, {
          field: 'textFields.street',
          prop: 'address.street'
        }, {
          field: 'textFields.building',
          prop: 'address.building'
        }, {
          field: {
            value: 'textFields.dimensionsNo',
            params: null
          },
          prop: 'packages[].dimensions'
        }, {
          field: {
            value: 'textFields.weightNo',
            params: null
          },
          prop: 'packages[].weight'
        }
      ]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    },
    addPackage() {
      this.getPackages.push({
        dimensions: '',
        weight: ''
      })

      setTimeout(() => {
        window.scrollTo({
          top: document.body.clientHeight,
          left: 0,
          behavior: 'smooth'
        })
      }, 50)
    },
    removePackage(i) {
      this.getPackages.splice(i, 1)
    },
    addNewBackUpEmail() {
      this.getContact.backupEmails.push({
        id: null,
        value: ''
      })
    },
    removeNewBackUpEmail(e) {
      this.getContact.backupEmails = this.getContact.backupEmails.filter(be => be !== e)
    },
    getBackupEmailsPositions() {
      const rows = []
      let changeRow = true
      let i = -1
      let c = 0

      for (const be of this.getContact.backupEmails) {
        c++
        if (changeRow) {
          changeRow = false
          i++
          rows[i] = {
            col1: be,
            col2: 'btn'
          }
        }
        if (c === 2) {
          rows[i].col2 = be
          c = 0
          changeRow = true
        }
      }

      if (rows.length > 0 && rows[rows.length - 1].col2 !== 'btn') {
        rows.push({
          col1: 'btn',
          col2: null
        })
      }

      return rows
    }
  },
  computed: {
    getContact() {
      return this.newData.onewayShipping.contact
    },
    getAddress() {
      return this.newData.onewayShipping.address
    },
    getPackages() {
      return this.newData.onewayShipping.packages
    },
    partRequestProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'Save',
          events: {
            'click': this.setChangedDialogItems
          },
          props: {
            'disabled': this.disabled || !this.isFormValid || this.getPackages.length === 0
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    }
  },
  mounted() {
    this.newData = {
      isAllowedToContinue: this.partRequestProp.isAllowedToContinue,
      onewayShipping: copyObj(this.partRequestProp.onewayShipping)
    }
    this.isDataReady = true
  }
}
</script>
<style>

</style>