<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <process-workflow
        v-model="remoteSupport"
        :tabs="getTabs"
        :status="getRemoteSupportStatus"
        :role="getRole"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ProcessWorkflow from '@/components/common/process-tabs/ProcessWorkflow'
import { findRemoteSupportById, updateRemoteSupportStatus } from '@/repositories/supportRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getObjectRemoteSupportStatusByKey } from '@/utils/statusesUtil'
import tabs from '@/data/process/process-remote-support-tabs'
import { getUserRole } from '@/services/authService'

export default {
  provide() {
    return {
      tryToFetchData: async () => await this.tryToFetchRemoteSupport(),
      updateEntityStatus: async (id, status) => await updateRemoteSupportStatus(id, status) 
    }
  },
  components: {
    ProcessWorkflow
  },
  data() {
    return {
      remoteSupport: null,
      isDataFetched: false,
      repairStatusTokenNotifier: null
    }
  },
  methods: {
    async tryToFetchRemoteSupport() {
      this.isDataFetched = false
      const r = await execHttpRequestThrowable(async () => await findRemoteSupportById(this.$route.params.id))
      this.remoteSupport = r.response.data
      this.remoteSupport.status = getObjectRemoteSupportStatusByKey(this.remoteSupport.status)
      this.isDataFetched = true
    }
  },
  computed: {
    getTabs() {
      return tabs
    },
    getRemoteSupportStatus() {
      return this.isDataFetched ? this.remoteSupport.status : getObjectRemoteSupportStatusByKey('NONE')
    },
    getRole() {
      return getUserRole()
    }
  },
  async mounted() {
    await this.tryToFetchRemoteSupport()
  },
  created() {
    this.$feedback.fill("Process Remote Support Page", this.$route.path)
  }
}
</script>
<style>

</style>