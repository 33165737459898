<template>
  <!-- To use expanded row you must to provide some additional things! 
    1) Each item from the items array must have isExpanded property.
    2) The innerHeaders property must be initialized.
   -->
  <v-data-table
  :headers="getHeaders"
  :items="items"
  :page.sync="pageProp"
  :footer-props="{ itemsPerPageOptions }"
  @update:items-per-page="updateSize"
  :items-per-page="sizeProp"
  :server-items-length="totalElements"
  @current-items="setCurrentItemsLabel"
  :hide-default-footer="hideDefaultFooter"
  disable-sort
  :loading="loading"
  :item-key="itemKey"
  :height="height"
  :expanded.sync="expanded"
  class="main-data-table secondaryBtn--text">
    <template #no-data>
      <div
      class="d-flex justify-center align-center">
          <p class="main-data-table-header">{{$t('textFields.noData'),}}</p>
      </div>
    </template>

    <template 
    v-for="(h, i) in getHeaders"
    v-slot:[`header.${h.value}`]="{}">
        <div 
        :key="i"
        class="main-data-table-header">
          <slot
          :name="`header.${h.value}`"
          :header="h">
            <!-- default slot content -->
            {{$t(h.text)}}
          </slot>
        </div>
    </template>

    <!-- current template header.rowNumber overrides those in getHeader computed! -->
    <template 
    v-if="numberPages"
    v-slot:[`header.rowNumber`]="{ header }">
      {{header.text}}
    </template>

    <template v-slot:[`item`]="{ index, item }">
        <tr>
          <td v-if="numberPages" :class="`main-data-table-row ${(index % 2) === 0 ? 'main-data-table-row-color-fill' : ''}`">
            <div 
            style="position: relative;"
            class="d-flex justify-center align-center">
              <span>
                {{(sizeProp * (pageProp - 1)) + index + 1}}
              </span>
            </div>  
          </td>
          <td v-if="markItems" :class="`main-data-table-row ${(index % 2) === 0 ? 'main-data-table-row-color-fill' : ''}`">
            <div
            class="d-flex justify-center">
              <v-checkbox
              v-model="item.mark"
              :disabled="disabled"/> 
            </div>
          </td>
          <td v-if="setImage" :class="`main-data-table-row ${(index % 2) === 0 ? 'main-data-table-row-color-fill' : ''}`">
            <div
            class="d-flex justify-center">
              <previewable-image
              v-model="item.imageUrl"
              height="65"
              width="50"
              contain
              class="py-2"/>
            </div>
          </td>
          <td 
          v-for="(h, i) in innerHeaders ? getHeadersForExtended : headers" 
          :key="i"
          :class="`main-data-table-row ${(index % 2) === 0 ? 'main-data-table-row-color-fill' : ''}`">
            <slot
            :name="`item.${h.value}`"
            :item="item"
            :index="index">
              <!-- default slot content -->
              {{item[h.value]}}
            </slot>
          </td>
          <td 
          v-if="innerHeaders"
          :class="`main-data-table-row ${(index % 2) === 0 ? 'main-data-table-row-color-fill' : ''}`">
            <div style="width: 100%;" class="d-flex justify-center">
              <v-btn
              @click="changeExpandState(item)"
              icon>
                <v-icon>
                  {{item.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                </v-icon>
              </v-btn>  
            </div>
          </td>
        </tr>
    </template>

    <template 
    v-if="innerHeaders"
    v-slot:expanded-item="{ item }">
      <td :colspan="getHeaders.length" style="background-color: rgba(0, 0, 0, 0.01); position: relative; width: 100%;">
        <main-data-table
        :headers="innerHeaders"
        :items="item.parts"
        hide-default-footer
        :itemsPerPageOptions="[-1]"
        class="pa-4"
        :item-key="innerItemKey"
        style="width: 100%;">
          <template 
          v-for="(h, i) in innerHeaders"
          v-slot:[`header.${h.value}`]="{}">
              <div 
              :key="i"
              class="main-data-table-header">
                <slot
                :name="`inner-header.${h.value}`"
                :header="h"
                />
              </div>
          </template>

          <template 
          v-for="(h, i) in innerHeaders"
          v-slot:[`item.${h.value}`]="{ index, item}">
              <div 
              :key="i">
                <slot
                :name="`inner-item.${h.value}`"
                :item="item"
                :index="index"
                />
              </div>
          </template>
        </main-data-table>
      </td>
    </template>

  </v-data-table>
</template>
<script>
import { isLanguageEn } from '@/services/authService'
import StringUtil from '@/utils/StringUtil'
import PreviewableImage from '@/components/common/PreviewableImage'
import MainDataTable from '@/components/common/MainDataTable'
export default {
  name: 'main-data-table',
  components: {
    MainDataTable,
    PreviewableImage
  },
  props: {
    headers: Array,
    innerHeaders: Array,
    items: Array,
    numberPages: Boolean,
    page: Number,
    size: Number,
    itemsPerPageOptions: {
      type: Array,
      default: () => {
        return [5, 10, 15, 25, 100]
      }
    },
    onUpdateSize: Function,
    onPerviousBtnClick: Function,
    onNextBtnClick: Function,
    totalElements: {
      type: Number,
      default: () => {
        return -1
      }
    },
    itemKey: {
      type: String,
      default: () => {
        return 'id'
      }
    },
    innerItemKey: {
      type: String,
      default: () => {
        return 'id'
      }
    },
    loading: Boolean,
    hideDefaultFooter: Boolean,
    height: Number,
    markItems: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    setImage: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  computed: {
    pageProp: {
      get: function() {
        return this.$props.page
      },
      set: function(v) {
        this.$emit('update:page', v)
      }
    },
    sizeProp: {
      get: function() {
        return this.$props.size
      },
      set: function(v) {
        this.$emit('update:size', v)
      }
    },
    isEN() {
      return isLanguageEn()
    },
    getHeaders() {
      const headers = []

      if (this.numberPages) headers.push({
        text: '#',
        align: 'start',
        sortable: true,
        value: 'rowNumber'
      })
      if (this.markItems) headers.push({
        text: 'headers.mark',
        align: 'start',
        sortable: false,
        value: 'mark'
      })
      if (this.setImage) headers.push({
        text: 'headers.image',
        align: 'start',
        sortable: false,
        value: 'imageUrl'
      })

      for (const h of this.headers) headers.push(h)

      return headers
    },
    getHeadersForExtended() {
      const headers = []

      for(let i = 0; i < this.headers.length; i++) {
        if (i === this.headers.length - 1) continue
        headers.push(this.headers[i])
      }

      return headers
    }
  },
  data() {
    return {
      expanded: []
    }
  },
  methods: {
    async updateSize(s) {
      this.pageProp = 1
      this.sizeProp = s
      if (this.onUpdateSize) await this.onUpdateSize()
      this.expanded = []
    },
    setCurrentItemsLabel() {
      setTimeout(() => {
        if (document.getElementsByClassName('v-data-footer__pagination')[0] !== undefined && document.getElementsByClassName('v-data-footer__pagination')[0] !== null) {
          document.getElementsByClassName('v-data-footer__select')[0].childNodes[0].textContent = this.isEN ? 'Rows per page' : 'Filas por página'
          document.getElementsByClassName('v-data-footer__pagination')[0].childNodes[0].textContent = document.getElementsByClassName('v-data-footer__pagination')[0].childNodes[0].textContent.replace('of', this.isEN ? 'of' : 'de')
        }
      }, 100)
    },
    changeExpandState(r) {
      if (!r.isExpanded) this.expanded.push(r)
      else {
        for(let i = 0; i < this.expanded.length; i++) {
          if (StringUtil.isEqual(this.expanded[i].id, r.id)) {
            this.expanded.splice(i, 1)
            break
          }
        }
      }

      r.isExpanded = !r.isExpanded
    }
  },
  async mounted() {
    if (this.hideDefaultFooter) return

    document.getElementsByClassName('v-data-footer__icons-before')[0].childNodes[0].addEventListener('click', async () => {
      if (this.onPerviousBtnClick) await this.onPerviousBtnClick()
      this.expanded = []
    })
    document.getElementsByClassName('v-data-footer__icons-after')[0].childNodes[0].addEventListener('click', async () => {
      if (this.onNextBtnClick) await this.onNextBtnClick()
      this.expanded = []
    })
  }
}
</script>
<style>
.main-data-table .v-data-table__wrapper::-webkit-scrollbar
{
  width: 8px;
}
.main-data-table .v-data-table__wrapper::-webkit-scrollbar-thumb
{
  background: radial-gradient(#ffffff60, #d4d4d4ea);
  border-radius: 0px;
}
.main-data-table .v-data-table__wrapper::-webkit-scrollbar-track
{
  background: #ffffff;
}

.v-data-table__wrapper > table > tbody > tr > td.main-data-table-row {
  color: #464646;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: 1px;
  background-color: rgba(0, 0, 0, 0.02);
  border-left: white 1px solid !important;
  border-right: white 1px solid !important;
  border-top: 1px solid #dee2e6 !important;
}
.v-data-table__wrapper > table > tbody > tr > td.main-data-table-row-color-fill {
  background-color: rgba(0, 0, 0, 0.04);
}
</style>