import { get, post } from '@/services/http'
import { getUserToken } from '@/services/authService'
import DateUtil from '@/utils/DateUtil'

export function exportActiveRepairsXlsx() {
  return get(`/api/v1/files/export/active-repairs/xlsx`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportClosedRepairsXlsx() {
  return get(`/api/v1/files/export/closed-repairs/xlsx`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportActionRequiredRepairsXlsx() {
  return get(`/api/v1/files/export/action-required-repairs/xlsx`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportGeneralReportXlsx(data) {
  return post(`/api/v1/files/export/general-report/xlsx`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportUsersXlsx() {
  return get(`/api/v1/files/export/users/xlsx`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportPartsXlsx(word) {
  return get(`/api/v1/files/export/parts/xlsx${word && word !== undefined ? '?keyword=' + word : ''}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportPartRequestXlsx() {
  return get(`/api/v1/files/export/part-request/xlsx`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportRemoteSupportXlsx() {
  return get(`/api/v1/files/export/remote-support/xlsx`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportRepairDetailTabXlsx(repairId) {
  return get(`/api/v1/files/export/repair-detail-tab/xlsx?repairId=${repairId}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportRepairDiagnosticsTabXlsx(repairId) {
  return get(`/api/v1/files/export/repair-diagnostics-tab/xlsx?repairId=${repairId}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportRepairApprovalTabXlsx(repairId) {
  return get(`/api/v1/files/export/repair-approval-tab/xlsx?repairId=${repairId}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportRepairTabXlsx(repairId) {
  return get(`/api/v1/files/export/repair-tab/xlsx?repairId=${repairId}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportRepairHistoryTabXlsx(repairId) {
  return get(`/api/v1/files/export/repair-history-tab/xlsx?repairId=${repairId}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportPartRequestDetailsTabXlsx(partRequestId) {
  return get(`/api/v1/files/export/part-request-detail-tab/xlsx?partRequestId=${partRequestId}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}
export function exportPartRequestHistoryTabXlsx(partRequestId) {
  return get(`/api/v1/files/export/part-request-history-tab/xlsx?partRequestId=${partRequestId}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}

export function exportRemoteSupportHistoryTabXlsx(id) {
  return get(`/api/v1/files/export/remote-support-history-tab/xlsx?id=${id}`, {
    headers: {
      'Authorization': getUserToken(),
      'Locale': DateUtil.getTimeZoneInPureNumber(new Date())
    },
    responseType: 'blob'
  })
}