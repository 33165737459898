const layouts = {
  'SignInLayout': [
    '/active-repairs',
    '/closed-repairs',
    '/action-required',
    '/new-repair',
    '/reports/general',
    '/contacts',
    '/create/contact',
    '/edit/contact',
    '/profile',
    '/mail-notification',
    '/parts',
    '/create/part',
    '/edit/part',
    '/process-repair',
    '/part-request/new',
    '/part-request/list',
    '/remote-support/new',
    '/remote-support/list',
    '/remote-support/process',
    '/request-account/reject',
    '/request-account/approve',
    '/process-part-request'
  ],
  'SignOutLayout': [
    '/login',
    '/sts-description',
    '/sts-disclaimer',
    '/reset-password',
    '/request-account/request'
  ]
}

export function getLayoutByPath(path) {
  for (const [k, v] of Object.entries(layouts)) {
    for (const p of v) {
      if (path.includes(p)) return k
    }
  }

  console.error(`No layout specified for path ${path}! Please set layout!`);
}