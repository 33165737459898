<template>
  <v-container class="d-flex justify-center align-center pt-10">
    <v-card
    width="500"
    min-height="780"
    class="rounded-0 primary-background-color px-8 pb-3"
    elevation="5">
      <v-form
      v-model="isFormValid">
        <v-row
        dense
        no-gutters>
          <v-col
          v-if="isLoading" 
          class="pt-8">
            <div
            class="new-user-title-box d-flex justify-center align-center">
              <v-progress-circular
                indeterminate
                color="white"
                width="2"
              ></v-progress-circular>
            </div>
          </v-col>
          <v-col 
          v-else
          class="pt-8">
            <div
            :class="`${getDecisionAsString() === 'APPROVAL' ? 'new-user-title-box' : 'new-user-title-box-error'} d-flex justify-center align-center`">
              <span>{{ getDecisionAsString() === 'APPROVAL' ? $t('pages.requestAccount.approvedCardTitle') : $t('pages.requestAccount.rejectedCardTitle') }}</span>
              <v-icon dark class="ml-2">
                {{ getDecisionAsString() === 'APPROVAL' ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'}}
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <p class="process-repair-tab-section-title mt-5" style="font-size: 20px; position: relative; top: 20px;">{{$t('pages.repair.contactDetails')}}</p>

        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-first-name
            v-model="contact.firstName"
            required
            readonly
            :label="$t('textFields.firstName')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-last-name
            v-model="contact.lastName"
            required
            readonly
            :label="$t('textFields.lastName')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-phone
            v-model="contact.phone"
            required
            readonly
            :label="$t('textFields.phone')"/>
          </v-col>
        </v-row>
        <v-row
        class="mt-2"
        dense
        no-gutters>
          <v-col>
            <text-field-email
            v-model="contact.email"
            required
            readonly
            :label="$t('textFields.email')"/>
          </v-col>
        </v-row>

        <p class="process-repair-tab-section-title mt-5" style="font-size: 20px; position: relative; top: 20px;">{{$t('pages.repair.addressDetails')}}</p>

        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.companyName')"
            v-model="address.companyName"
            readonly
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.country')"
            v-model="address.country"
            readonly
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.state')"
            v-model="address.province"
            readonly
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.city')"
            v-model="address.city"
            readonly
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.postalCode')"
            v-model="address.postalCode"
            readonly
            required/>
          </v-col>
        </v-row>
        <v-row dense no-gutters class="mt-2">
          <v-col>
            <text-field-value 
            :label="$t('textFields.street')"
            v-model="address.street"
            readonly
            required
            />
          </v-col>
        </v-row>
        <v-row dense no-gutters class="my-2 mb-6">
          <v-col>
            <text-field-value 
            :label="$t('textFields.building')"
            v-model="address.building"
            readonly
            required/>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import TextFieldFirstName from '@/components/text-fields/TextFieldFirstName'
import TextFieldLastName from '@/components/text-fields/TextFieldLastName'
import TextFieldPhone from '@/components/text-fields/TextFieldPhone'
import TextFieldEmail from '@/components/text-fields/TextFieldEmail'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import { execHttpRequestThrowable } from '@/services/http'
import { setRequestAccountDesicion } from '@/repositories/contactRepo'
export default {
  components: {
    TextFieldFirstName,
    TextFieldLastName,
    TextFieldPhone,
    TextFieldEmail,
    TextFieldValue
  },
  data() {
    return {
      contact: {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      },
      address: {
        companyName: '',
        country: '',
        province: '',
        city: '',
        postalCode: '',
        street: '',
        building: ''
      },
      status: null,
      isFormValid: false,
      isLoading: false
    }
  },
  computed: {
  },
  methods: {
    async tryToSetRequestAccountDecision() {
      const r = await execHttpRequestThrowable(async () => await setRequestAccountDesicion(this.$route.params.id, this.getDecisionAsString()),
      {},
      v => this.isLoading = v)
      const data = r.response.data
      this.contact = data.contact
      this.address = data.address
      this.status = data.status
    },
    openSuccessDialog(m) {
      this.$successDialog.open(null, m, null)
    },
    openFailedDialog(m) {
      this.$failedDialog.open(null, m, null)
    },
    getDecisionAsString() {
      const d = this.$route.params.decision
      switch(d) {
        case 'approve': return 'APPROVAL'
        case 'reject': return 'REJECTED'
        default: {
          console.error(`Options allowed for decision are: (approve) and (reject)! The system received ${d}`)
          return null
        }
      }
    }
  },
  async mounted() {
    await this.tryToSetRequestAccountDecision()

    this.$feedback.fill("Account Request Decision Page", this.$route.path)
  }
}
</script>
<style>

</style>