<template>
	<v-snackbar
  v-model="$snackbar.model"
  :timeout="$snackbar.timeout"
  top
  right
  :color="$snackbar.variant"
  >
    {{ $t($snackbar.message, $snackbar.messageProps) }}

    <template v-slot:action="{ attrs }">
      <v-btn
      @click="close"
      v-bind="attrs"
      icon>
        <v-icon
        color="#ffffff">
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  components: {
  },
  props: {
    value: Boolean,
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  methods: {
    close() {
      this.model = false
      this.$snackbar.close()
    }
  }
}
</script>
<style>
.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  /* background-color: #DF9811 !important; */
  border: 2px white solid;
} 
</style>