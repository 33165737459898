<template>
  <div>
    <label class="text-input-label">
      {{$t(label)}}
    </label>
    <v-autocomplete
    v-model="model"
    @input="$emit('input')"
    :placeholder="$t(placeholder)"
    :item-text="itemText"
    :item-value="itemValue"
    :rules="rules"
    return-object
    hide-details="auto"
    :dense="normal"
    :items="items"
    :disabled="disabled"
    clearable>
      <template v-slot:[`selection`]="{ item }">
        <span>{{item.name && item.name !== undefined ? $t(item.name) : (item.value && item.value !== undefined ? $t(item.value) : (item.model && item.model !== undefined ? $t(item.model) : $t('textFields.setNameOrValueProperty')))}}</span>
      </template>
      <template v-slot:item="{item}">
        <v-row 
        justify="space-between"
        dense>
          <v-col :cols="item.imageUrl ? 5 : 12">
            <span>{{item.name && item.name !== undefined ? $t(item.name) : (item.value && item.value !== undefined ? $t(item.value) : (item.model && item.model !== undefined ? $t(item.model) : $t('textFields.setNameOrValueProperty')))}}</span>
          </v-col>
          <v-col 
          v-if="item.imageUrl"
          cols="3">
            <div>
              <v-img :src="item.imageUrl" height="50" contain width="50"/>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    placeholder: String,
    items: Array,
    value: Object,
    normal: Boolean,
    disabled: Boolean,
    itemText: String,
    itemValue: String,
    required: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  data() {
    return {
      rules: [
        v => this.required ? (!!v || this.$t('invalidValue.required')) : true
      ]
    }
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  }
}
</script>
<style>
.text-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; 
  
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

div.text-input > div.v-input__control > div.v-input__slot > div.v-text-field__slot > input {
  color: #495057;
}

.text-input-border-color.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined {
  border: 1px solid #49505742;
}

.text-input-label {
  color: #495057;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
}
</style>