<template>
  <v-container class="d-flex justify-center align-center pt-10">
    <v-card
    width="500"
    min-height="450"
    class="rounded-0 primary-background-color px-8 pb-3"
    elevation="5">
      <v-form
      v-model="isFormValid">
        <v-row
        dense
        no-gutters>
          <v-col class="pt-8">
            <div
            class="new-user-title-box d-flex justify-center align-center">
              <v-icon dark class="mr-2">
                mdi-lead-pencil
              </v-icon>
              <span>{{$t('pages.resetPassword.cardTitle')}}</span>
            </div>
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col class="d-flex justify-center align-center">
            <text-field-password
            v-model="password"
            validate
            required
            :label="$t('textFields.newPassword')"
            />
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col class="d-flex justify-center align-center">
            <text-field-confirm-password
            v-model="confirmPassword"
            :password="password"
            required
            />
          </v-col>
        </v-row>
        <v-row
        class="mt-8"
        dense
        no-gutters>
          <v-col class="d-flex justify-center align-center pb-4">
            <primary-btn
            @click="tryToResetPassword"
            :disabled="!isFormValid"
            :label="$t('btns.save')"
            :loading="isLoading"
            color='#0277bd'
            :width="150"
            :height="50"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import TextFieldPassword from '@/components/text-fields/TextFieldPassword'
import TextFieldConfirmPassword from '@/components/text-fields/TextFieldConfirmPassword'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { verifyActionKey, resetPassword } from '@/repositories/actionKeyRepo'
import { execHttpRequest, execHttpRequestThrowable } from '@/services/http'
import { routeFromRoot } from '@/services/routeService'
export default {
  components: {
    TextFieldPassword,
    TextFieldConfirmPassword,
    PrimaryBtn
  },
  data() {
    return {
      isFormValid: false,
      password: '',
      confirmPassword: '',
      isLoading: false
    }
  },
  methods: {
    async tryToResetPassword() {
      const data = {
        newPassword: this.password
      }
      await execHttpRequestThrowable(async () => await resetPassword(this.$route.params.id, data), {}, v => this.isLoading = v)
      routeFromRoot('login')
    },
    async tryToVerifyActionKey() {
      const messages = {
        _403: this.$t('dialogs.messages.failed.actionKeyExpired'),
        _404: this.$t('dialogs.messages.failed.actionKeyNotFound')
      }
      const r = await execHttpRequest(async () => await verifyActionKey(this.$route.params.id), messages)
      if (!r.isSuccessed) {
        this.openFailedDialog(r.message, null, () => { routeFromRoot('login') })
      }
    },
    openFailedDialog(m) {
      this.$failedDialog.open(null, m, null)
    },
  },
  async mounted() {
    await this.tryToVerifyActionKey()

    this.$feedback.fill("Reset Password Page", this.$route.path)
  }
}
</script>
<style>
.sts-description-title {
  color: #212529;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.sts-description-subtitle {
  color: #212529;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
</style>