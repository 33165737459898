<template>
  <v-container>
    <request-reset-password-or-username-dialog 
    v-if="requestResetPasswordModel" 
    v-model="requestResetPasswordModel" 
    @submit="handleRequestDialog"
    :set-username-field="requestValueInputs.setUsernameField"
    :set-email-field="requestValueInputs.setEmailField"/>
    <v-row
    dense>
      <v-col class="d-flex justify-center align-center pt-8">
        <div class="sign-in-card">
          <div class="py-4 login-system-title-container">
            <h4 class="text-center login-system-title white--text">Service Tracking System</h4>
          </div>
          <v-row dense no-gutters class="px-4 pt-6">
            <v-col>
              <text-field-username v-model="username" icon @enter="tryToSignIn"/>
            </v-col>
          </v-row>
          <v-row no-gutters dense class="px-4">
            <v-col>
              <text-field-password v-model="password" icon @enter="tryToSignIn"/>
            </v-col>
          </v-row>
          <v-row no-gutters dense class="px-4">
            <v-col>
              <select-language v-model="lang"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex justify-center align-center pt-6">
              <primary-btn
              :width="100"
              :height="45"
              @click="tryToSignIn"
              color="#33b5e5"
              :loading="isLoading"
              label="Login"
              :disabled="!lang || !username || username.length === 0 || !password || password.length === 0"
              />
            </v-col>
          </v-row>
          <div 
          class="mt-5"
          style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
          <v-row dense no-gutters class="px-2">
            <v-col 
            class="d-flex justify-start align-center">
              <label 
              style="color: #00000099; text-decoration: none; position: relative; top: 10px; font-size: 18px; font-style: italic;">{{$t('other.forgottenCredentials')}}</label>
            </v-col>
          </v-row>
          <v-row dense no-gutters class="px-2">
            <v-col 
            class="d-flex justify-start align-center">
              <a 
              @click="setRequestResetPasswordOrUsernameDialogState(true, false, true)" 
              style="color: #007bff; text-decoration: none; position: relative; top: 10px;">{{$t('other.retrieveUsername')}}</a>
            </v-col>
          </v-row>
          <v-row dense no-gutters class="px-2">
            <v-col 
            class="d-flex justify-start align-center">
              <a 
              @click="setRequestResetPasswordOrUsernameDialogState(true, true, false)" 
              style="color: #007bff; text-decoration: none; position: relative; top: 10px;">{{$t('other.forgottenPasswordLink')}}</a>
            </v-col>
          </v-row>
          <div 
          class="mt-5"
          style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
          <v-row dense no-gutters class="px-2">
            <v-col 
            class="d-flex justify-start align-center">
              <a 
              @click="routeTo('request-account/request')" 
              style="color: #007bff; text-decoration: none; position: relative; top: 10px;">{{$t('other.accountRequestLink')}}</a>
            </v-col>
          </v-row>
          <div 
          class="mt-5"
          style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
          <v-row dense class="py-2 px-2">
            <v-col 
            class="d-flex justify-start align-center">
              <a @click="routeTo('sts-description')" style="color: #007bff; text-decoration: none;">STS Description</a>
            </v-col>
            <v-col 
            class="d-flex justify-end align-center">
              <a @click="routeTo('sts-disclaimer')" style="color: #007bff; text-decoration: none;">Disclaimer</a>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextFieldUsername from '@/components/text-fields/TextFieldUsername'
import TextFieldPassword from '@/components/text-fields/TextFieldPassword'
import SelectLanguage from '@/components/text-fields/SelectLanguage'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import RequestResetPasswordOrUsernameDialog from '@/components/dialogs/RequestResetPasswordOrUsernameDialog'
import { routeFromRoot, routeToNewWindow } from '@/services/routeService'
import { login } from '@/repositories/authRepo'
import { setUserToken, selectLanguage, getUserRole } from '@/services/authService'
import { execHttpRequest, execHttpRequestThrowable } from '@/services/http'
import { DynamicNotifier } from '@/utils/DynamicNotifier.js'
import { requestResetPassword } from '@/repositories/actionKeyRepo'
import { tryToRequestUsernameByEmail } from '@/repositories/supportRepo'
import { routeLink } from '@/services/systemService'

export default {
  components: {
    TextFieldUsername,
    TextFieldPassword,
    SelectLanguage,
    PrimaryBtn,
    RequestResetPasswordOrUsernameDialog
  },
  data() {
    return {
      lang: {
        label: 'English',
        key: 'en'
      },
      username: '',
      password: '',
      isLoading: false,
      requestResetPasswordModel: false,
      requestValueInputs: {
        setUsernameField: false,
        setEmailField: false
      }
    }
  },
  computed: {
    getRole() {
      return getUserRole()
    }
  },
  methods: {
    async tryToSignIn() {
      const messages = {
        failedTitle: 'LOGIN',
        _401: this.$t('dialogs.messages.failed.userNotFoundUsernameAndPassword')
      }

      const r = await execHttpRequestThrowable(async () => await login(this.username, this.password), messages, 
      v => this.isLoading = v)

      setUserToken(r.response.data)
      DynamicNotifier.notify('signInEvent')
      selectLanguage(this.lang.key)
      this.$i18n.locale = this.lang.key

      if (routeLink.get()) routeToNewWindow(routeLink.get(), '_self', true, false)
      else routeFromRoot(`active-repairs${this.getRole === 'CLIENT' ? '#suggest-options' : ''}`)
    },
    async handleRequestDialog({username = null, email = null}) {
      if (username) await this.tryToRequestResetPassword(username)
      else if (email) await this.tryToRequestUsernameByEmail(email)
      else console.error('You must specified username, email or both fields when you initialize the component - RequestResetPasswordOrUsernameDialog.vue!')
    },
    async tryToRequestResetPassword(username) {
      const messages = {
        successTitle: 'LOGIN',
        failedTitle: 'LOGIN',
        _200: 'dialogs.messages.success.resetPasswordMailSend',
        _404: this.$t('dialogs.messages.failed.userNotFoundByUsername')
      }

      await execHttpRequest(async () => await requestResetPassword(username), messages, 
      v => this.isLoading = v)

      this.setRequestResetPasswordOrUsernameDialogState(false, false, false)
    },
    async tryToRequestUsernameByEmail(email) {
      const messages = {
        successTitle: 'LOGIN',
        failedTitle: 'LOGIN',
        _200: 'dialogs.messages.success.requestUsernameMailSend',
        _404: this.$t('dialogs.messages.failed.usernameNotFoundByEmail')
      }

      await execHttpRequest(async () => await tryToRequestUsernameByEmail(email), messages, 
      v => this.isLoading = v)
      this.setRequestResetPasswordOrUsernameDialogState(false, false, false)
    },
    setRequestResetPasswordOrUsernameDialogState(s, suf, sef) {
      this.requestResetPasswordModel = s
      this.requestValueInputs = {
        setUsernameField: suf,
        setEmailField: sef
      }
    },
    routeTo(link) {
      routeFromRoot(link)
    }
  },
  created() {
    this.$feedback.fill("Login Page", this.$route.path)
  }
}
</script>
<style>
.sign-in-card {
  width: 320px;
  height: 100%;
  background: white;
  border: 1px solid rgba(0,0,0,.2);
}
.login-system-title {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: 1px;
  font-weight: 300;
}
.login-system-title-container {
  background-color: #0277bd;
  border-radius: 0;
  background: linear-gradient(40deg,#45cafc,#303f9f);
}
a {
  cursor: pointer;
  color: #007bff;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
}
a:hover {
  color: #0056b3 !important;
}
</style>