<template>
  <v-container 
  v-if="isDataReady"
  fluid>
    <updatable-status-by-admin
    title="pages.partRequest.repairRequestDetails"
    :subtitle="$parent.isRole(['ADMIN', 'SUPERVISOR']) ? 'pages.partRequest.pleaseApproveOrRejectDetailTab' : ''"
    :data="partRequestProp"
    :loading="isLoading"
    :statusesFetcher="getPartRequestStatuses"
    :statusGetter="getStatusAsObject"/>
    <v-row dense class="mt-10">
      <v-col>
        <span v-if="$parent.isRole(['ADMIN', 'SUPERVISOR'])" class="process-repair-tab-section-title">{{$t('pages.repair.partsForApproval')}}</span>
        <span v-else class="process-repair-tab-section-title">{{$t('pages.repair.partsInfo')}}</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <main-data-table
        :headers="getHeaders"
        :items="newData.requestedParts"
        number-pages
        :mark-items="$parent.isRole(['ADMIN', 'SUPERVISOR']) && !disabled"
        set-image
        :disabled="disabled"
        :page.sync="page"
        :size="-1"
        :items-per-page-options="[-1]"
        :total-elements="-1"
        hide-default-footer>
          <template v-slot:[`item.initialApproval`]="{ item }">
            <waiting-approval-label
            :status="item.initialApproval"/>
          </template>
          <template
          v-if="$parent.isRole(['ADMIN', 'SUPERVISOR'])"
          v-slot:[`item.approval`]="{ item }">
            <waiting-approval-btns
            :current-status="item.initialApproval"
            :loading="isLoading"
            :disabled="disabled"
            @selected="(status) => changeInitialStatusSingle(item, status)"/>
          </template>
        </main-data-table>

        <v-row 
        v-if="$parent.isRole(['ADMIN', 'SUPERVISOR'])"
        class="mt-5"
        dense>
          <v-col>
            <process-repair-quick-btns
            :config="getQuickBtnsConfig"
            :loading.sync="isLoading"
            title="common.pressContinueWhenReady"
            on-success-repair-status-message="dialogs.messages.success.firstDetailsCompleted"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="partRequestProp.requestedParts.length === 0"
    :loading.sync="isLoading"
    class="px-4 mt-4"
    name="export.title.partRequestDetailTab"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import MainDataTable from '@/components/common/MainDataTable'
import UpdatableStatusByAdmin from '@/components/common/process-tabs/UpdatableStatusByAdmin'
import WaitingApprovalLabel from '@/components/common/process-tabs/WaitingApprovalLabel'
import WaitingApprovalBtns from '@/components/common/process-tabs/WaitingApprovalBtns'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import { getTextByKeyInitialStatus, getObjectPartRequestStatusByKey, getAvailablePartRequestStatuses,  } from '@/utils/statusesUtil'
import { updatePartRequestItemsInitialApproval } from '@/repositories/partRequestRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { exportPartRequestDetailsTabXlsx } from '@/repositories/exportRepo'
import { copyObj, compareArrays } from '@/utils/StringUtil'
export default {
  name: 'DetailsTabPartRequest',
  components: {
    ProcessRepairQuickBtns,
    MainDataTable,
    UpdatableStatusByAdmin,
    WaitingApprovalLabel,
    WaitingApprovalBtns,
    ExportXlsxRow
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      headers: [{
          text: 'headers.category',
          align: 'start',
          sortable: true,
          value: 'category'
        }, {
          text: 'headers.partName',
          align: 'start',
          sortable: true,
          value: 'partName'
        }, {
          text: 'headers.quantity',
          align: 'start',
          sortable: true,
          value: 'quantity'
        }, {
          text: 'headers.initialApproval',
          align: 'start',
          sortable: true,
          value: 'initialApproval'
        }, {
          text: 'headers.approval',
          align: 'start',
          sortable: true,
          value: 'approval'
        }
      ],
      page: 1,
      newData: null,
      isLoading: false,
      isDataReady: false
    }
  },
  computed: {
    partRequestProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.$parent.isRole(['SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'])) {
          if (h.value === 'mark' ||
          h.value === 'approval') continue
        }

        headers.push(h)
      }

      return headers
    },
    isMarkedApprovedBtnDisable() {
      
      return this.newData.requestedParts.filter(p => p.mark === true).length === 0
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'RejectAll',
          events: {
            'click': () => this.setChangedDialogItems('REJECTED', false)
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'ApproveAll',
          events: {
            'click': () => this.setChangedDialogItems('APPROVED', false)
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'ApproveMarked',
          events: {
            'click': () => this.setChangedDialogItems('APPROVED', true)
          },
          props: {
            'disabled': this.disabled || this.isMarkedApprovedBtnDisable
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    },
    getExporter() {
      return async () => await exportPartRequestDetailsTabXlsx(this.$route.params.id)
    }
  },
  methods: {
    async changeInitialStatusSingle(part, status) {
      await this.tryToChangeInitialStatus([{
        id: part.id,
        initialApproval: status
      }], false)
    },
    async tryToChangeInitialStatus(parts, showSuccessDialog) {
      const data = parts.map(p => {
        return {
          id: p.id,
          initialApproval: p.initialApproval
        }
      })
      const status = data[0].initialApproval

      const messages = {
        ignoreSuccessDialog: !showSuccessDialog,
        _200: this.$t(status === 'APPROVED' ? 'dialogs.messages.success.approvalDecisionSaved' : 'dialogs.messages.success.rejectionDecisionSaved')
      }
      const r = await execHttpRequestThrowable(async () => await updatePartRequestItemsInitialApproval(this.$route.params.id, data), messages, v => this.isLoading = v)
      const ids = parts.map(p => p.id)
      //Update server parts
      this.partRequestProp.requestedParts
        .filter(p => ids.includes(p.id))
        .forEach(p => p.initialApproval = String(status))
        //Update local parts
      this.newData.requestedParts
        .filter(p => ids.includes(p.id))
        .forEach(p => p.initialApproval = String(status))

      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItems(status, isMarkBtn) {
      const filteredParts = this.newData.requestedParts.filter(p => (isMarkBtn && p.mark && p.initialApproval !== status) || (!isMarkBtn && p.initialApproval !== status))
      const newParts = filteredParts.map(p => {
        return {
          rowNumber: p.rowNumber,
          initialApproval: status
        }
      })
      const oldParts = filteredParts.map(p => {
        return {
          rowNumber: p.rowNumber,
          initialApproval: p.initialApproval
        }
      })

      const props = [
        {
          field: 'textFields.approval',
          prop: 'initialApproval',
          getter: getTextByKeyInitialStatus
        }
      ]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareArrays(oldParts, newParts, props),
        async () => await this.tryToChangeInitialStatus(filteredParts.map(p => {
          return {
            id: p.id,
            initialApproval: status
          }
        }), true),
        true
      )
    },
    getPartRequestStatuses() {
      return getAvailablePartRequestStatuses()
    },
    getStatusAsObject(s) {
      return getObjectPartRequestStatusByKey(s)
    }
  },
  mounted() {
    console.log(this.partRequestProp.requestedParts)
    this.newData = {
      status: this.partRequestProp.status,
      requestedParts: this.partRequestProp.requestedParts.map((p, i) => {
        const newPart = copyObj(p)
        newPart.rowNumber = i + 1
        return newPart
      }),
      isAllowedToContinue: this.partRequestProp.isAllowedToContinue
    }
    this.isDataReady = true
  }
}
</script>
<style>

</style>