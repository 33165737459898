import Vue from 'vue'

class ChangesDialogPlugin {
  model
  action
  title
  items
  isRow
  constructor() {
    this.model = false
    this.action = null
    this.title = null
    this.items = []
    this.isRow = false
  }
  open(title, items, action, isRow) {
    this.title = title
    this.items = items
    this.action = action
    this.isRow = isRow
    this.model = true
  }
  close() {
    this.model = false
    this.action = null
    this.title = null
    this.items = []
    this.isRow = false
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$changesDialog = Vue.observable(new ChangesDialogPlugin())
  }
}