<template>
  <v-container fluid>
    <v-row 
    dense
    class="ml-1">
      <v-col cols="2">
        <report-filter-autocomplete-object
        v-model="client.model"
        :label="client.label"
        item-text="name"
        item-value="id"
        :placeholder="client.placeholder"
        :items="client.items"/>
      </v-col>
      <v-col cols="2">
        <report-filter-autocomplete-object
        v-model="status.model"
        :label="status.label"
        item-text="value"
        item-value="key"
        :placeholder="status.placeholder"
        :items="status.items"/>
      </v-col>
      <v-col cols="2">
        <report-filter-autocomplete-object
        v-model="service.model"
        :label="service.label"
        item-text="name"
        item-value="id"
        :placeholder="service.placeholder"
        :items="service.items"/>
      </v-col>
      <v-col cols="2">
        <report-filter-autocomplete-object
        v-model="category.model"
        :label="category.label"
        item-text="name"
        item-value="key"
        @input="tryToFetchAllPartsCategory"
        :placeholder="category.placeholder"
        :items="category.items"/>
      </v-col>
      <v-col cols="2">
        <report-filter-autocomplete-object
        v-model="part.model"
        :label="part.label"
        :disabled="!category.model"
        item-text="model"
        item-value="id"
        :placeholder="part.placeholder"
        :items="part.items"/>
      </v-col>
    </v-row>
    <v-row 
    dense
    class="ml-1 mt-8">
      <v-col cols="2">
        <report-filter-value
        v-model="requestNumber.model"
        :label="requestNumber.label"
        :placeholder="requestNumber.placeholder"/>
      </v-col>
      <v-col cols="2">
        <report-filter-value
        v-model="serialNumber.model"
        :label="serialNumber.label"
        :placeholder="serialNumber.placeholder"/>
      </v-col>
      <v-col cols="2">
        <report-filter-value
        v-model="serviceTag.model"
        :label="serviceTag.label"
        :placeholder="serviceTag.placeholder"/>
      </v-col>
      <v-col cols="2">
        <report-filter-date
        v-model="creationDateFrom.model"
        :max="creationDateTo.model"
        :label="creationDateFrom.label"
        :placeholder="creationDateFrom.placeholder"/>
      </v-col>
      <v-col cols="2">
        <report-filter-date
        v-model="creationDateTo.model"
        :min="creationDateFrom.model"
        :label="creationDateTo.label"
        :placeholder="creationDateTo.placeholder"/>
      </v-col>
      <v-col cols="2" class="d-flex justify-center align-end">
        <primary-btn 
        @click="execFilter"
        :width="120"
        :height="43"
        :disabled="disableSearching"
        color="#4285f4"
        :label="$t('btns.search')"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ReportFilterValue from '@/components/report/ReportFilterValue'
import ReportFilterAutocompleteObject from '@/components/report/ReportFilterAutocompleteObject'
import ReportFilterDate from '@/components/report/ReportFilterDate'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { fetchAllClients } from '@/repositories/clientRepo'
import { fetchAllServices } from '@/repositories/serviceRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getAvailableRepairStatuses } from '@/utils/statusesUtil'
import { fetchAllCategories, fetchAllPartsByCategory } from '@/repositories/partRepo'

export default {
  components: {
    ReportFilterValue,
    ReportFilterAutocompleteObject,
    ReportFilterDate,
    PrimaryBtn
  },
  computed: {
    disableSearching() {
      return !this.client.model && 
      !this.status.model &&
      !this.service.model &&
      !this.category.model &&
      !this.part.model &&
      !this.requestNumber.model &&
      !this.serialNumber.model &&
      !this.serviceTag.model &&
      !this.creationDateFrom.model &&
      !this.creationDateTo.model
    }
  },
  data() {
    return {
      client: {
        model: null,
        label: 'textFields.clientLabel',
        placeholder: 'placeholder.selectClient',
        items: []
      },
      status: {
        model: null,
        label: 'textFields.statusLabel',
        placeholder: 'placeholder.selectStatus',
        items: getAvailableRepairStatuses()
      },
      service: {
        model: null,
        label: 'textFields.serviceLabel',
        placeholder: 'placeholder.selectService',
        items: []
      },
      category: {
        model: null,
        label: 'textFields.categoryLabel',
        placeholder: 'placeholder.selectCategory',
        items: []
      },
      part: {
        model: null,
        label: 'textFields.partLabel',
        placeholder: 'placeholder.selectPart',
        items: []
      },
      requestNumber: {
        model: null,
        label: 'textFields.requestNumberLabel',
        placeholder: 'placeholder.requestNumber',
      },
      serialNumber: {
        model: null,
        label: 'textFields.serialNumberLabel',
        placeholder: 'placeholder.serialNumber',
      },
      serviceTag: {
        model: null,
        label: 'textFields.serviceTagLabel',
        placeholder: 'placeholder.serviceTag',
      },
      creationDateFrom: {
        model: null,
        label: 'textFields.creationDateFromLabel',
        placeholder: ''
      },
      creationDateTo: {
        model: null,
        label: 'textFields.creationDateToLabel',
        placeholder: ''
      }
    }
  },
  methods: {
    async tryToFetchAllClients() {
      const r = await execHttpRequestThrowable(fetchAllClients)
      this.client.items = [...r.response.data]
    },
    async tryToFetchAllServices() {
      const r = await execHttpRequestThrowable(fetchAllServices)
      this.service.items = [...r.response.data]
    },
    async tryToFetchAllCategories() {
      const r = await execHttpRequestThrowable(fetchAllCategories)
      this.category.items = [...r.response.data]
    },
    async tryToFetchAllPartsCategory() {
      if (this.category.model === null) {
        this.part.items = []
        this.part.model = null
        return
      }
      const r = await execHttpRequestThrowable(async () => await fetchAllPartsByCategory(this.category.model.key))
      this.part.items = [...r.response.data]
    },
    execFilter() {
      this.$emit('filter', {
        client: this.client.model,
        service: this.service.model,
        part: this.part.model,
        category: this.category.model,
        status: this.status.model,
        requestNumber: this.requestNumber.model,
        serialNumber: this.serialNumber.model,
        serviceTag: this.serviceTag.model,
        createdFrom: this.creationDateFrom.model,
        createdTo: this.creationDateTo.model
      })
    }
  },
  async mounted() {
    await this.tryToFetchAllClients()
    await this.tryToFetchAllServices()
    await this.tryToFetchAllCategories()
  },
}
</script>
<style>

</style>