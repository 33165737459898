import Vue from 'vue'
import Router from 'vue-router'
import { navigationRoutes } from '@/routes/navigationRoutes'
import { isUserLogged, getSelectedLanguage } from '@/services/authService'
import { routeLink } from '@/services/systemService'

import BaseLayout from '@/layouts/BaseLayout'


Vue.use(Router)

export const router = new Router({
  mode: 'hash',
  base: '/',
  routes : [{
    path: '/:lang/',
    name: 'BaseLayout',
    component: BaseLayout,
    children: navigationRoutes
  }], scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

const userLogoutAllowedPages = [
  'login',
  'sts-description',
  'sts-disclaimer',
  'reset-password',
  'request-account/request'
]

router.beforeEach((to, from, next) =>
{
  console.log('from ' + from.path)
  console.log('to ' + to.path)

  if (routeLink.get() === window.location.href) routeLink.set(null)

  if(to.params.lang === undefined) {
    console.log('Language has not been specified!')
    if (!isUserLogged()) next(`/en/login`)
    else next({ path: '/en'})
  }
  else if(to.params.lang !== 'es' && to.params.lang !== 'en') {
    console.log('Valid language has not been specified!')
    if (!isUserLogged()) next(`/en/login`)
    else next({ path: '/en'})
  }
  else {
    if (!isUserLogged()) {
      if (userLogoutAllowedPages.includes(to.path.substr(4)) || isLogoutAllowedPagesWithParameters(to.path.substr(4))) {
        next(true)
        return
      }
      
      if (to.query['force']) routeLink.set(window.location.href.split('?')[0])
      
      next(`/${(getSelectedLanguage())}/login`)
    }
    else {
      if (userLogoutAllowedPages.includes(to.path.substr(4)) || new RegExp(/^\/\w\w\/?$/).test(to.path) || isLogoutAllowedPagesWithParameters(to.path.substr(4))) next(`/${(getSelectedLanguage())}/active-repairs`)
      else next(true)
    }
  }
})

function isLogoutAllowedPagesWithParameters(path) {
  const arr = new RegExp(/\/?(?<name>.+?)\/.+?$/).exec(path)
  if (arr && userLogoutAllowedPages.includes(arr.groups['name'])) return true
  else return false
}