<template>
  <div>
    <label class="text-input-label">
      {{$t(label)}}
    </label>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="model"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="model"
            :placeholder="$t(placeholder)"
            append-icon="mdi-calendar"
            readonly
            class="text-input text-input-border-color mt-3"
            hide-details="auto"
            solo
            flat
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="model"
          no-title
          scrollable
          :max="max"
          :min="min"
          :locale="getLocale"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(null)"
          >
            {{$t('btns.reset')}}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            {{$t('btns.cancel')}}
          </v-btn>
          <!-- <v-btn
            text
            color="primary"
            @click="$refs.menu.save(model)"
          >
            {{$t('btns.ok')}}
          </v-btn> -->
        </v-date-picker>
      </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    placeholder: String,
    value: String,
    min: String,
    max: String
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  data() {
    return {
      menu: false
    }
  },
  watch: {
    'model': {
      handler(v) {
        this.$refs.menu.save(v)
      }
    }
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    },
    getLocale() {
      return this.$i18n.locale
    }
  }
}
</script>
<style>
.text-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; 
  
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

div.text-input > div.v-input__control > div.v-input__slot > div.v-text-field__slot > input {
  color: #495057;
}

.text-input-border-color.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined {
  border: 1px solid #49505742;
}

.text-input-label {
  color: #495057;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
}
</style>