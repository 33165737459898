var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{staticClass:"pa-4",attrs:{"headers":_vm.getHeaders(),"inner-headers":_vm.innerHeaders,"items":_vm.items,"number-pages":"","page":_vm.page,"size":_vm.size,"onUpdateSize":_vm.tryToFetchPartRequests,"onPerviousBtnClick":_vm.tryToFetchPartRequests,"onNextBtnClick":_vm.tryToFetchPartRequests,"total-elements":_vm.totalElements,"loading":_vm.isLoading,"innerItemKey":"partName"},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.updatedAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},(_vm.getRole !== 'CLIENT')?{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.clientName)+" ")])]}}:null,{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.createdAt))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.updatedAt))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(_vm.$t(item.statusObj.value))+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('route-btn',{attrs:{"label":"btns.viewDetails","badge":item.actionRequiredFromCurrentUser ? '1' : null,"href":("/process-part-request/" + (item.id))}})]}},{key:"inner-item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('previewable-image',{staticClass:"pa-2",attrs:{"height":"40","width":"40","alt":"Part Image","contain":""},model:{value:(item.imageUrl),callback:function ($$v) {_vm.$set(item, "imageUrl", $$v)},expression:"item.imageUrl"}})]}},{key:"inner-item.category",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"inner-item.partName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.partName)+" ")])]}},{key:"inner-item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.quantity)+" ")])]}}],null,true)},[_c('p',{staticClass:"d-none"},[_vm._v(" "+_vm._s(_vm.updateStatusLoadingKey)+" ")])])],1)],1),_c('export-xlsx-row',{staticClass:"px-4 mt-4",attrs:{"width":150,"height":50,"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"name":"export.title.partRequest","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }