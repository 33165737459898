import { post, put, get } from '@/services/http'
import { getUserToken } from '@/services/authService'

export function savePart(data) {
  return post(`/api/v1/parts`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': getUserToken()
    }
  })
}
export function fetchPart(id) {
  return get(`/api/v1/parts/fetch?partId=${id}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchPartsByCriteria(keyword, size, page) {
  return get(`/api/v1/parts/fetch-by-criteria?${keyword && keyword !== undefined && keyword.length > 0 ? `&keyword=${keyword}` : ''}&size=${size}&page=${page}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchAllCategories() {
  return get(`/api/v1/parts/fetch/all/categories`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchAllPartsByCategory(c, cu, serviceCategoriesKeys) {
  return get(`/api/v1/parts/fetch/by/category?category=${c}${cu && cu !== undefined ? '&contactUsername=' + cu : ''}${serviceCategoriesKeys ? `&serviceCategories=${serviceCategoriesKeys}`: ''}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function updatePartState(pId, state) {
  return put(`/api/v1/parts/update/part-state?id=${pId}&state=${state}`, {}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}