export default [{
  component: 'PartRequest_DetailsTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.details',
  statuses: ['INITIAL'],
  state: 'DISABLED'
}, {
  component: 'PartRequest_ShippingDetailsTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.shippingDetails',
  statuses: ['IN_ONE_WAY_SHIPPING'],
  state: 'DISABLED'
}, {
  component: 'PartRequest_TransportArrangementTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.waitingForPickUp',
  statuses: ['IN_WAITING_FOR_PICK_UP'],
  state: 'DISABLED'
}, {
  component: 'PartRequest_WaitingConfirmationTab',
  label: 'pages.repair.tabs.completed',
  state: 'DISABLED',
  items: [
    {
      roles: ['ADMIN', 'SUPERVISOR', 'SERVICE'],
      statuses: ['WAITING_CONFIRMATION'],
    }, {
      roles: ['CLIENT', 'SUSTAINABILITY_MANAGER'],
      statuses: ['WAITING_CONFIRMATION'],
    }
  ]
}, {
  component: 'PartRequest_HistoryTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE'],
  label: 'pages.repair.tabs.history',
  statuses: ['DONE', 'REJECTED'],
  state: 'ENABLED-UNCHANGABLE'
}]