import Vue from 'vue'

class AuthPlugin {
  logged
  constructor() {
    this.logged = false
  }
  isLogged() {
    return this.logged
  }
  process() {
    const m = new RegExp(/STS_LOGGED=(?<value>\w+)/).exec(document.cookie)
    if (m) this.logged = m[1].toLowerCase() === 'true' 
    else this.logged = false
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$auth = Vue.observable(new AuthPlugin())
  }
}