<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        :headers="getHeaders()"
        :inner-headers="innerHeaders"
        :items="items"
        number-pages
        :page.sync="page"
        :size.sync="size"
        :onUpdateSize="tryToFetchPartRequests"
        :onPerviousBtnClick="tryToFetchPartRequests"
        :onNextBtnClick="tryToFetchPartRequests"
        :total-elements="totalElements"
        :loading="isLoading"
        innerItemKey="partName"
        class="pa-4">
          <template v-slot:[`header.createdAt`]="{ header }">
              {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.updatedAt`]="{ header }">
              {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <!-- 'updateStatusLoadingKey' var is required for 'v-progress-circular' to vanish when it is done! -->
          <p class="d-none">
            {{updateStatusLoadingKey}}
          </p>

          <template 
          v-if="getRole !== 'CLIENT'"
          v-slot:[`item.clientName`]="{ item }">
              <span>
                  {{item.clientName}}
              </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.createdAt)}}
              </span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.updatedAt)}}
              </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
              <h4>
                {{$t(item.statusObj.value)}}
              </h4>
          </template>
          <template v-slot:[`item.details`]="{ item }">
              <route-btn
              label="btns.viewDetails"
              :badge="item.actionRequiredFromCurrentUser ? '1' : null"
              :href="`/process-part-request/${item.id}`"/>
          </template>

          <template v-slot:[`inner-item.imageUrl`]="{ item }">
            <previewable-image
            v-model="item.imageUrl"
            height="40"
            width="40"
            alt="Part Image"
            contain
            class="pa-2"/>
          </template>
          <template v-slot:[`inner-item.category`]="{ item }">
              <span>
                  {{item.category}}
              </span>
          </template>
          <template v-slot:[`inner-item.partName`]="{ item }">
              <span>
                {{item.partName}}
              </span> 
          </template>
          <template v-slot:[`inner-item.quantity`]="{ item }">
              <span>
                {{item.quantity}}
              </span>
          </template>
        </main-data-table>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4 mt-4"
    name="export.title.partRequest"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import { execHttpRequestThrowable } from '@/services/http'
import DateUtil from '@/utils/DateUtil'
import MainDataTable from '@/components/common/MainDataTable'
import RouteBtn from '@/components/btns/RouteBtn'
import PreviewableImage from '@/components/common/PreviewableImage'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import { exportPartRequestXlsx } from '@/repositories/exportRepo'
import { routeFromRoot } from '@/services/routeService'
import { isLanguageEn, getUserRole } from '@/services/authService'
import { fetchPartRequestByCriteria } from '@/repositories/partRequestRepo'
import { getObjectPartRequestStatusByKey} from '@/utils/statusesUtil'
export default {
  components: {
    MainDataTable,
    RouteBtn,
    ExportXlsxRow,
    PreviewableImage
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.clientName',
          align: 'start',
          sortable: true,
          value: 'clientName'
        }, {
          text: 'headers.creationDateTime',
          align: 'start',
          sortable: true,
          value: 'createdAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.lastChangeDateTime',
          align: 'start',
          sortable: true,
          value: 'updatedAt' //(yyyy-MM-dd HH:mm)
        },{
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        }, {
          text: 'headers.details',
          align: 'start',
          sortable: true,
          value: 'details'
        }, {
          text: 'headers.parts',
          align: 'start',
          sortable: true,
          value: 'parts'
        }
      ],
      innerHeaders: [{
          text: 'headers.image',
          align: 'start',
          sortable: true,
          value: 'imageUrl'
        } ,{
          text: 'headers.itemCategory',
          align: 'start',
          sortable: true,
          value: 'category'
        }, {
          text: 'headers.partName',
          align: 'start',
          sortable: true,
          value: 'partName'
        }, {
          text: 'headers.quantity',
          align: 'start',
          sortable: true,
          value: 'quantity'
        },
      ],
      items: [],
      totalElements: 0,
      size: 10,
      page: 1,
      updateStatusLoadingKey: 0,
      isLoading: false
    }
  },
  computed: {
    isEN() {
      return isLanguageEn()
    },
    getRole() {
      return getUserRole()
    },
    getExporter() {
      return exportPartRequestXlsx
    }
  },
  methods: {
    async tryToFetchPartRequests() {
      this.items = []
      const r = await execHttpRequestThrowable(async () => await fetchPartRequestByCriteria(this.size, this.page - 1, 'createdAt,DESC'),
      {},
      v => this.isLoading = v)
      this.pageable = r.response.data
      this.totalElements = this.pageable.totalElements
      this.pageable.content.forEach(o => {
        o.statusObj = getObjectPartRequestStatusByKey(o.status, true)
        o.isLoading = false
      })
      this.items = this.pageable.content
    },
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.getRole === 'CLIENT' && h.value === 'clientName') continue
        headers.push(h)
      }

      return headers
    },
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    routeTo(link) {
      routeFromRoot(link)
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  async mounted() {
    // if (this.getRole !== 'CLIENT' && this.getRole !== 'SERVICE') routeFromRoot('active-repairs')
    await this.tryToFetchPartRequests()

    this.$feedback.fill("Part Request List Page", this.$route.path)
  }
}
</script>
<style>
.main-data-table.theme--light.v-data-table {
  background: #ffffff;
}
.main-data-table.v-data-table {
  background: transparent !important;
}
/*
Rows per page color!
.main-data-table .v-select__selection.v-select__selection--comma {
  color: #DF9811;
}
*/
.main-data-table.v-icon.notranslate .mdi.mdi-menu-down.theme--light.primary--text {
  color: #DF9811;
}
/* 
Rows per page underline color!
.main-data-table .v-select > .v-input__control > .v-input__slot{
  color: #DF9811;
}
*/
/*
Right arrow color! 
.main-data-table .v-btn--icon.v-size--default .v-icon {
  color: #DF9811;
}
*/
.main-data-table.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
  border-color: transparent;
}

.main-data-table .v-data-table-header {
  background-color: #cfd8dc!important;
}
thead.v-data-table-header > tr > th.text-start {
  border-left: white 1px solid;
  border-right: white 1px solid;
}

.main-data-table-header {
  color: #000000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
</style>