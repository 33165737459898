var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.clientActionDialogModel)?_c('client-action-dialog',{model:{value:(_vm.clientActionDialogModel),callback:function ($$v) {_vm.clientActionDialogModel=$$v},expression:"clientActionDialogModel"}}):_vm._e(),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{staticClass:"pa-4",attrs:{"headers":_vm.headers,"items":_vm.items,"number-pages":"","page":_vm.page,"size":_vm.size,"onUpdateSize":_vm.tryToFetchRepairs,"onPerviousBtnClick":_vm.tryToFetchRepairs,"onNextBtnClick":_vm.tryToFetchRepairs,"total-elements":_vm.totalElements,"loading":_vm.isLoading},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.actionRequired",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.updatedAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.details",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatus(item.status)))+" ")])]}},{key:"item.actionRequired",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('other.from'))+" "+_vm._s(item.actionRequired.toLowerCase())+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.createdAt))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.updatedAt))+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('route-btn',{attrs:{"href":("process-repair/" + (item.id)),"label":"btns.viewDetails","width":120,"height":30}})]}}],null,true)})],1)],1),_c('export-xlsx-row',{staticClass:"px-4",attrs:{"width":150,"height":50,"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"name":"export.title.activeRepairs","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }