import Vue from 'vue'

class ConfirmActionDialogPlugin {
  model
  title
  subtitle
  actionBtnLabel
  closeBtnLabel
  action
  constructor() {
    this.model = false
    this.title = null
    this.subtitle = null
    this.actionBtnLabel = null
    this.closeBtnLabel = null
    this.action = null
  }
  open(title, subtitle, actionBtnLabel, closeBtnLabel, action) {
    this.title = title
    this.subtitle = subtitle
    this.actionBtnLabel = actionBtnLabel
    this.closeBtnLabel = closeBtnLabel ? closeBtnLabel : 'btns.close'
    this.action = action
    this.model = true
  }
  close() {
    this.model = false
    this.title = null
    this.subtitle = null
    this.actionBtnLabel = null
    this.closeBtnLabel = null
    this.action = null
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$confirmActionDialog = Vue.observable(new ConfirmActionDialogPlugin())
  }
}