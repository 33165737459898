<template>
  <v-dialog
  v-model="$imagePreviewDialog.model"
  width="600"
  @click:outside="closeDialog">
    <v-card
    color="white"
    width="100%">
      <div class="image-preview-dialog-header image-preview-dialog-header-title d-flex justify-space-between align-center px-5">
        <span>{{$t('dialogs.titles.imagePreview', {'value': $imagePreviewDialog.title})}}</span>
        <v-btn
        icon
        dark
        @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div
      class="d-flex justify-center align-center pa-4"
      style="overflow: hidden;">
        <div
        ref="zoomArea"
        @mousewheel="zooming"
        style="width: 100%; object-fit: contain;"
        :class="`image-preview-zoom-area ${isZoomIn ? 'image-preview-zoom-area-moving' : ''}`">
          <v-img
          :style="`transform: scale(${zoom.model}); z-index: 19;  grid-column: 1; grid-row: 1;`"
          :src="$imagePreviewDialog.value"/>
          <!-- TODO: to be concluded -->
          <!--<image-controllers
          v-if="controllers.model"
          @move="moveAround"
          :top="controllers.top"
          :left="controllers.left"/> -->
        </div>
      </div>
      <div 
      class="mt-5"
      style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
      <v-row dense no-gutters class="pa-4 pb-6" justify="center">
        <v-col class="d-flex justify-center align-center">
          <outlined-btn
          :label="$t('btns.close')"
          color="#0d47a1"
          :width="100"
          :height="45"
          @click="closeDialog"/>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import OutlinedBtn from '@/components/btns/OutlinedBtn'
// import ImageControllers from '@/components/imagePreview/ImageControllers'
export default {
  components: {
    OutlinedBtn,
    // ImageControllers
  },
  data() {
    return {
      zoom: {
        model: 1,
        min: 1,
        max: 5,
        step: 0.1
      },
      controllers: {
        model: false,
        top: null,
        left: null
      }
    }
  },
  computed: {
    isZoomIn() {
      return this.zoom.model > this.zoom.min
    }
  },
  methods: {
    closeDialog() {
      this.$imagePreviewDialog.model = false
    },
    // moveAround(side, step) {
    //   const area = this.$refs.zoomArea

    //   switch (side) {
    //     case 'up': 
    //       area.scrollTop -= step;
    //       break
    //     case 'down': 
    //       area.scrollTop += step;
    //       break
    //     case 'left': 
    //       area.scrollLeft -= step;
    //       break
    //     case 'right': 
    //       area.scrollLeft += step;
    //       break
    //   }
    // },
    processControllersPosition(e) {
      const area = this.$refs.zoomArea
      const dimensions = area.getBoundingClientRect()

      // Mouse position
      const X = e.clientX - dimensions.left;
      const Y = e.clientY - dimensions.top;

      this.controllers.left = `${X - 50}px`
      this.controllers.top = `${Y - 50}px`
    },
    zooming(e) {
      this.controllers.model = true
      if (e.deltaY > 0) {
        //zoom out
        if (this.zoom.model === this.zoom.min || this.zoom.model < this.zoom.min) {
          this.zoom.model = this.zoom.min
          this.controllers.model = false
          return
        }
        this.zoom.model -= this.zoom.step
      }
      else {
        //zoom in
        if (this.zoom.model === this.zoom.max || this.zoom.model > this.zoom.max) {
          this.zoom.model = this.zoom.max
          return
        }
        this.zoom.model += this.zoom.step
      }

      this.processControllersPosition(e)
    }
  },
  beforeDestroy() {
    this.closeDialog()
  }
}
</script>
<style>
.image-preview-dialog-header {
  width: 100%;
  height: 70px;
  background-color: #0d47a1;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.144);
  border-radius: 0px!important;
}
.image-preview-dialog-header-title {
  font-family: 'Titillium Web', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.image-preview-dialog-content {
  font-family: 'Titillium Web', sans-serif;
  color: black;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}

.image-preview-zoom-area {
  overflow: auto;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
.image-preview-zoom-area-moving {
  /* cursor: move; */
}
</style>