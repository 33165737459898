import Vue from 'vue'

class SnackBarPlugin {
  model
  variant
  message
  messageProps
  timeout
  constructor() {
    this.model = false
    this.action = null
    this.title = null
    this.message = null
    this.messageProps = null
  }
  success(message, messageProps, timeout) {
    this.open(message, messageProps, 'SUCCESS', timeout)
  }
  failed(message, messageProps, timeout) {
    this.open(message, messageProps, 'FAILED', timeout)
  }
  open(message, messageProps, variant, timeout) {
    this.message = message
    this.messageProps = messageProps
    this.variant = this.getVariant(variant)
    this.model = true
    //5000 -> 5secs default
    this.timeout = timeout ? timeout : 5000
  }
  close() {
    this.model = false
    this.message = null
  }
  getVariant(v) {
    switch(v) {
      case 'FAILED': return '#f44242'
      default: return '#0d47a1'
    }
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$snackbar = Vue.observable(new SnackBarPlugin())
  }
}