import { get, post, put, _delete } from '@/services/http'
import { getUserIdentification, getUserToken } from '@/services/authService'

export function fetchActiveRepairs(size, page) {
  return get(`/api/v1/repairing/fetch-by-criteria/active?username=${getUserIdentification()}&size=${size}&page=${page}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchActionRequiredRequest(size, page, sortQuery) {
  return get(`/api/v1/repairing/fetch-by-criteria/action-required?size=${size}&page=${page}${sortQuery}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchNumberOfActionRequiredRepair(categories) {
  return get(`/api/v1/repairing/fetch-number/action-required?username=${getUserIdentification()}${categories ? `&categories=${categories}` : ''}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchClosedRepair(size, page) {
  return get(`/api/v1/repairing/fetch-by-criteria/closed?username=${getUserIdentification()}&size=${size}&page=${page}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function fetchRepairsByFilter(data, size, page) {
  return post(`/api/v1/repairing/fetch-by-criteria/report?username=${getUserIdentification()}&size=${size}&page=${page}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function fetchHistoryByCriteria(id, size, page) {
  return get(`/api/v1/repairing/fetch-history-by-criteria?username=${getUserIdentification()}&repairId=${id}&size=${size}&page=${page}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function createRepair(data) {
  return put(`/api/v1/repairing/create/repair?username=${getUserIdentification()}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function fetchRepair(id) {
  return get(`/api/v1/repairing/fetch/repair?username=${getUserIdentification()}&repairId=${id}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function changePartInitialStatus(id, data) {
  return post(`/api/v1/repairing/change/initial/status/all?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function changePartMainStatus(id, data) {
  return post(`/api/v1/repairing/change/main/status/all?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateRepairStatus(id, status) {
  return post(`/api/v1/repairing/change/repair/status?username=${getUserIdentification()}&repairId=${id}${status ? `&status=${status}` : ''}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateShippingData(id, data) {
  return post(`/api/v1/repairing/save/shipping/data?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateTransitInfoData(id, data) {
  return post(`/api/v1/repairing/save/transit-info/data?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updatePickUpShippingData(id, data) {
  return post(`/api/v1/repairing/save/pick-up-shipping/data?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateTransitBackShippingData(id, data) {
  return post(`/api/v1/repairing/save/transit-back-shipping/data?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateDiagnosticStartDate(id, date) {
  return post(`/api/v1/repairing/save/diagnostic-start-date?username=${getUserIdentification()}&repairId=${id}&startDate=${date}`, {}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function updateDiagnosticData(id, data) {
  return post(`/api/v1/repairing/save/part-in-diagnostic?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateAllPartsServiceData(id, data) {
  return post(`/api/v1/repairing/update/parts/repair/data/all?username=${getUserIdentification()}&repairId=${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function deleteRepairInShippingDetailTab(id) {
  return _delete(`/api/v1/repairing/delete/shipping-details?id=${id}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}