var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{staticClass:"pa-4",attrs:{"number-pages":"","headers":_vm.headers,"items":_vm.items,"page":_vm.page,"size":_vm.size,"loading":_vm.isLoading,"onUpdateSize":_vm.tryToFetchContacts,"onPerviousBtnClick":_vm.tryToFetchContacts,"onNextBtnClick":_vm.tryToFetchContacts,"total-elements":_vm.totalElements},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.username",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.role",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.details",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.username)+" ")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getRole(item.role)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.status)?_c('span',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.$t('textFields.unlocked'))+" ")]):_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t('textFields.locked'))+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"detail-btn rounded-0",attrs:{"width":"70","height":"30"},on:{"click":function($event){return _vm.routeTo(("edit/contact/" + (item.username)))}}},[_vm._v(" "+_vm._s(_vm.$t('btns.edit'))+" ")])]}}],null,true)})],1)],1),_c('export-xlsx-row',{staticClass:"px-4",attrs:{"width":150,"height":50,"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"name":"export.title.users","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }