var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDataReady)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"px-8"},[_c('span',{staticClass:"mail-notification-title"},[_vm._v(" "+_vm._s(_vm.$t('pages.mail.settingRoleSubtitle'))+" ")])])],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',[_c('common-tabs',{attrs:{"items":_vm.getTabs},model:{value:(_vm.serviceCategoryTab),callback:function ($$v) {_vm.serviceCategoryTab=$$v},expression:"serviceCategoryTab"}})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{staticClass:"pa-4",attrs:{"number-pages":"","headers":_vm.headers,"items":_vm.newData[_vm.serviceCategoryTab].settings,"page":_vm.page,"size":_vm.size,"loading":_vm.isLoading,"itemsPerPageOptions":_vm.itemsPerPageOptions,"item-key":"number","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"item.processStatus",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatus(item.processStatus, _vm.newData[_vm.serviceCategoryTab].key)))+" ")])]}},{key:"item.isClient",fn:function(ref){
var item = ref.item;
return [_c('text-field-switch',{staticStyle:{"position":"relative","top":"-8px"},attrs:{"color":"purple"},model:{value:(item.isClient),callback:function ($$v) {_vm.$set(item, "isClient", $$v)},expression:"item.isClient"}})]}},{key:"item.isService",fn:function(ref){
var item = ref.item;
return [_c('text-field-switch',{staticStyle:{"position":"relative","top":"-8px"},attrs:{"color":"purple"},model:{value:(item.isService),callback:function ($$v) {_vm.$set(item, "isService", $$v)},expression:"item.isService"}})]}},{key:"item.isSupervisor",fn:function(ref){
var item = ref.item;
return [_c('text-field-switch',{staticStyle:{"position":"relative","top":"-8px"},attrs:{"color":"purple"},model:{value:(item.isSupervisor),callback:function ($$v) {_vm.$set(item, "isSupervisor", $$v)},expression:"item.isSupervisor"}})]}},{key:"item.isAdministrator",fn:function(ref){
var item = ref.item;
return [_c('text-field-switch',{staticStyle:{"position":"relative","top":"-8px"},attrs:{"color":"purple"},model:{value:(item.isAdministrator),callback:function ($$v) {_vm.$set(item, "isAdministrator", $$v)},expression:"item.isAdministrator"}})]}},{key:"item.isSustainabilityManager",fn:function(ref){
var item = ref.item;
return [_c('text-field-switch',{staticStyle:{"position":"relative","top":"-8px"},attrs:{"color":"purple"},model:{value:(item.isSustainabilityManager),callback:function ($$v) {_vm.$set(item, "isSustainabilityManager", $$v)},expression:"item.isSustainabilityManager"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('primary-btn',{attrs:{"label":_vm.$t('btns.save'),"loading":_vm.isLoading,"width":90,"height":45,"color":"#4285f4"},on:{"click":_vm.tryToSaveData}})],1)],1)],1):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }