var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDataReady)?_c('v-container',{attrs:{"fluid":""}},[(_vm.$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER']))?_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"process-repair-tab-section-subtitle text-center"},[_vm._v(_vm._s(_vm.$t('pages.repair.partsAreInWaitingApproval')))])])],1):_vm._e(),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[(_vm.$parent.isRole(['ADMIN', 'SUPERVISOR']))?_c('v-col',[_c('p',{staticClass:"process-repair-tab-section-title"},[_vm._v(_vm._s(_vm.$t('pages.repair.partsForRepairApproval')))])]):_vm._e(),(_vm.$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER']))?_c('v-col',[_c('p',{staticClass:"process-repair-tab-section-title"},[_vm._v(_vm._s(_vm.$t('pages.repair.partsInfo')))])]):_vm._e()],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.newData.repairParts,"number-pages":"","page":_vm.page,"size":-1,"items-per-page-options":[-1],"total-elements":-1,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header.isApproved",fn:function(ref){return [(_vm.$parent.isRole(['SERVICE']))?_c('span',[_vm._v(_vm._s(_vm.$t('headers.supervisorStatus')))]):(_vm.$parent.isRole(['ADMIN', 'SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER']))?_c('span',[_vm._v(_vm._s(_vm.$t('headers.status')))]):_vm._e()]}},{key:"header.approval",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.mark",fn:function(ref){
var item = ref.item;
return [(!_vm.isDiagnosticStatus(item, ['IT_IS_WORKING']))?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-checkbox',{attrs:{"disabled":_vm.disabled},model:{value:(item.mark),callback:function ($$v) {_vm.$set(item, "mark", $$v)},expression:"item.mark"}})],1):_c('div')]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('previewable-image',{staticClass:"py-2",attrs:{"height":"65","width":"50"},model:{value:(item.imageUrl),callback:function ($$v) {_vm.$set(item, "imageUrl", $$v)},expression:"item.imageUrl"}})]}},(_vm.$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER']))?{key:"item.problemDescription",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('span',[_vm._v(" "+_vm._s(item.problemDescription)+" ")])])]}}:null,(_vm.$parent.isRole(['SERVICE']))?{key:"item.serviceStatus",fn:function(ref){
var item = ref.item;
return [(_vm.isDiagnosticStatus(item, ['IT_IS_WORKING']))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('repairStatus.itIsWorking'))+" ")]):(_vm.isDiagnosticStatus(item, ['FOR_REPAIR']))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('repairStatus.forRepair'))+" ")]):(_vm.isDiagnosticStatus(item, ['FOR_REPLACE']))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('repairStatus.forReplace'))+" ")]):(_vm.isDiagnosticStatus(item, ['NON_REPAIRABLE']))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('repairStatus.nonRepairable'))+" ")]):_vm._e()]}}:null,(_vm.$parent.isNotRole(['CLIENT', 'SUSTAINABILITY_MANAGER']))?{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.comment)+" ")])])]}}:null,(_vm.$parent.isNotRole(['CLIENT', 'SUSTAINABILITY_MANAGER']))?{key:"item.totalCost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.totalCost)+" ")])]}}:null,{key:"item.isApproved",fn:function(ref){
var item = ref.item;
return [(_vm.isDiagnosticStatus(item, ['IT_IS_WORKING']))?_c('span',{staticClass:"green--text",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.$t('repairStatus.itIsWorking'))+" ")]):_c('div',[(_vm.isRepairApprovalStatus(item, ['APPROVED']))?_c('span',{staticClass:"green--text",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.$t('initialStatuses.approved'))+" ")]):(_vm.isRepairApprovalStatus(item, ['REJECTED']))?_c('span',{staticClass:"red--text",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.$t('initialStatuses.rejected'))+" ")]):(_vm.isRepairApprovalStatus(item, ['WAITING_APPROVAL']))?_c('span',{staticClass:"orange--text",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.$t('initialStatuses.waitingApproval'))+" ")]):_vm._e()])]}},(_vm.$parent.isRole(['ADMIN', 'SUPERVISOR']))?{key:"item.approval",fn:function(ref){
var item = ref.item;
return [(!_vm.isDiagnosticStatus(item, ['IT_IS_WORKING']))?_c('div',{staticClass:"d-flex justify-start"},[_c('primary-btn',{staticClass:"mx-2",attrs:{"label":_vm.$t('btns.reject'),"color":"#ff3547","disabled":_vm.disabled || _vm.isRepairApprovalStatus(item, ['REJECTED']),"loading":_vm.isLoading,"small-text":"","width":100,"height":30},on:{"click":function($event){return _vm.changeInitialStatusSingle(item, 'REJECTED')}}}),_c('primary-btn',{staticClass:"mx-2",attrs:{"label":_vm.$t('btns.approve'),"color":"#4285f4","loading":_vm.isLoading,"disabled":_vm.disabled || _vm.isRepairApprovalStatus(item, ['APPROVED']),"small-text":"","width":100,"height":30},on:{"click":function($event){return _vm.changeInitialStatusSingle(item, 'APPROVED')}}})],1):_vm._e()]}}:null],null,true)}),(_vm.$parent.isRole(['ADMIN', 'SUPERVISOR']))?_c('v-row',{staticClass:"mt-5",attrs:{"dense":""}},[_c('v-col',[_c('p',{staticClass:"text-center",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t('common.pressContinueWhenReady'))+" ")]),_c('process-repair-quick-btns',{attrs:{"config":_vm.getQuickBtnsConfig,"loading":_vm.isLoading,"on-success-repair-status-message":"dialogs.messages.success.firstDetailsCompleted"},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)],1):_vm._e()],1)],1),_c('export-xlsx-row',{staticClass:"px-4 mt-4",attrs:{"width":150,"height":50,"disabled":_vm.newData.repairParts.length === 0,"loading":_vm.isLoading,"name":"export.title.repairApprovalTab","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }