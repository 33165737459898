<template>
  <v-container
  v-if="isDataReady"
  fluid>
    <v-row 
    dense 
    no-gutters 
    class="mt-2 mb-4">
        <v-col>
          <p v-if="$parent.isRole(['SUPERVISOR'])" class="process-repair-tab-section-subtitle text-center">
           {{$t('pages.repair.partsAreInService')}}
          </p>
          <div
          v-else-if="$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])">
            <p class="process-repair-tab-section-subtitle text-center">
            {{$t('pages.repair.partsAreInDiagnostic')}}
            </p>
            <p
            class="text-h6 text-end mr-10">
              {{$t('headers.totalPrice')}}: <span>{{getTotalPriceOfAll}}</span>
            </p>
          </div>
        </v-col>
    </v-row>
    <v-row v-if="$parent.isRole(['SUPERVISOR'])" dense no-gutters>
      <v-col>
        <span class="process-repair-tab-section-title">{{$t('pages.repair.partsInfo')}}</span>
      </v-col>
    </v-row>
    <v-form
    ref="form"
    v-model="isFormValid">
      <v-row 
      v-if="$parent.isNotRole(['SUSTAINABILITY_MANAGER'])"
      dense>
        <v-col>
          <main-data-table
          v-if="isDataReady"
          :headers="getHeaders"
          :items="newData.repairParts"
          number-pages
          set-image
          :page.sync="page"
          :size="-1"
          :items-per-page-options="[-1]"
          :total-elements="-1"
          hide-default-footer>
            <template v-slot:[`item.serialNumber`]="{ item }">
                <text-field-value 
                v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.serialNumber" 
                normal/>
                <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                  {{item.serialNumber}}
                </span>
            </template>
            <template v-slot:[`item.serviceTag`]="{ item }">
                <text-field-value 
                v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.serviceTag" 
                normal/>
                <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                  {{item.serviceTag}}
                </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <text-field-autocomplete 
                :label="$t('textFields.selectStatus')"
                v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.diagnosticInfo.statusObj"
                :items="serviceStatuses"
                normal 
                itemText="value"
                itemValue="key"/>
                <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                  {{item.diagnosticInfo.statusObj ? $t(item.diagnosticInfo.statusObj.value) : ''}}
                </span>
            </template>
            <template v-slot:[`item.comment`]="{ item }">
                <div class="py-2">
                  <text-area
                  v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                  v-model="item.diagnosticInfo.comment"
                  solo-normal
                  width="22rem"
                  no-resize/> 
                  <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                    {{item.diagnosticInfo.comment}}
                  </span>
                </div>
            </template>
            <template v-slot:[`item.diagnosticPrice`]="{ item }">
                <text-field-number
                v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.diagnosticInfo.prices.diagnosticPrice"
                @input="calcTotalPriceOfItem(item.diagnosticInfo)"
                normal
                double/>
                <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                  {{item.diagnosticInfo.prices.diagnosticPrice}}
                </span>
            </template>
            <template v-slot:[`item.repairPrice`]="{ item }">
                <text-field-number
                v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.diagnosticInfo.prices.repairPrice"
                @input="calcTotalPriceOfItem(item.diagnosticInfo)"
                normal
                double/>
                <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                  {{item.diagnosticInfo.prices.repairPrice}}
                </span>
            </template>
            <template v-slot:[`item.partsPrice`]="{ item }">
                <text-field-number
                v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.diagnosticInfo.prices.partsPrice"
                @input="calcTotalPriceOfItem(item.diagnosticInfo)"
                normal
                double/>
                <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                  {{item.diagnosticInfo.prices.partsPrice}}
                </span>
            </template>
            <template v-slot:[`item.totalCost`]="{ item }">
                <text-field-number
                v-if="!disabled && $parent.isRole(['ADMIN', 'SERVICE'])"
                v-model="item.diagnosticInfo.totalCost"
                normal
                readonly
                double/>
                <span v-else-if="disabled || $parent.isRole(['SUPERVISOR'])">
                  {{item.diagnosticInfo.totalCost}}
                </span>
            </template>
          </main-data-table>
          <v-row 
          v-if="$parent.isNotRole(['CLIENT'])"
          dense
          class="mt-5">
            <v-col
            class="mr-13">
              <p
              class="text-end">
                {{$t('headers.diagnosticPriceTotal')}}: <strong>{{getTotalOfPrices('diagnosticPrice')}}</strong>
              </p>
              <p
              class="text-end">
                {{$t('headers.repairPriceTotal')}}: <strong>{{getTotalOfPrices('repairPrice')}}</strong>
              </p>
              <p
              class="text-end">
                {{$t('headers.partsPriceTotal')}}: <strong>{{getTotalOfPrices('partsPrice')}}</strong>
              </p>
              <p
              class="text-h6 text-end">
                {{$t('headers.totalPrice')}}: <span>{{getTotalPriceOfAll}}</span>
              </p>
            </v-col>
          </v-row>
          <v-row 
          v-if="$parent.isRole(['ADMIN', 'SERVICE'])"
          dense
          class="mt-5">
            <v-col>
              <process-repair-quick-btns 
              :loading.sync="isLoading"
              :config="getQuickBtnsConfig"
              on-success-repair-status-message="dialogs.messages.success.proceedingToNextStepSuccessfully"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <export-xlsx-row
      v-if="$parent.isNotRole(['SUSTAINABILITY_MANAGER'])"
      :width="150"
      :height="50"
      :disabled="newData.repairParts.length === 0"
      :loading.sync="isLoading"
      class="px-4 mt-4"
      name="export.title.repairDiagnosticsTab"
      :exporter="getExporter"/>
    </v-form>
  </v-container>
</template>
<script>
import TextArea from '@/components/text-fields/TextArea'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextFieldAutocomplete from '@/components/text-fields/TextFieldAutocomplete'
import TextFieldNumber from '@/components/text-fields/TextFieldNumber'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import MainDataTable from '@/components/common/MainDataTable'
import { getUserRole } from '@/services/authService'
import { updateDiagnosticData } from '@/repositories/repairRepo'
import { DIAGNOSTICS_STATUSES, getObjectDiagnosticStatusByKey, getDiagnosticStatusByKey } from '@/utils/statusesUtil'
import { execHttpRequestThrowable } from '@/services/http'
import { copyObj, cloneObj, compareArrays } from '@/utils/StringUtil'
import { exportRepairDiagnosticsTabXlsx } from '@/repositories/exportRepo'
export default {
  components: {
    ProcessRepairQuickBtns,
    ExportXlsxRow,
    TextArea,
    TextFieldValue,
    TextFieldAutocomplete,
    TextFieldNumber,
    MainDataTable
  },
  data() {
    return {
      isFormValid: false,
      headers: [{
          text: 'headers.partName',
          align: 'start',
          sortable: true,
          value: 'partName'
        }, {
          text: 'headers.serialNumber',
          align: 'start',
          sortable: true,
          value: 'serialNumber'
        }, {
          text: 'headers.serviceTag',
          align: 'start',
          sortable: true,
          value: 'serviceTag'
        }, {
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        }, {
          text: 'headers.comment',
          align: 'start',
          sortable: true,
          value: 'comment'
        }, {
          text: 'headers.diagnosticPrice',
          align: 'start',
          sortable: true,
          value: 'diagnosticPrice'
        }, {
          text: 'headers.repairPrice',
          align: 'start',
          sortable: true,
          value: 'repairPrice'
        }, {
          text: 'headers.partsPrice',
          align: 'start',
          sortable: true,
          value: 'partsPrice'
        }, {
          text: 'headers.totalCost',
          align: 'start',
          sortable: true,
          value: 'totalCost'
        }
      ],
      serviceStatuses: DIAGNOSTICS_STATUSES,
      page: 1,
      isLoading: false,
      newData: null,
      isDataReady: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  computed: {
    getRole() {
      return getUserRole()
    },
    repairProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getTotal() {
      if (this.getTotalOfPrices)
        return this.getTotalOfPrices('diagnosticPrice') + 
        this.getTotalOfPrices('repairPrice') + 
        this.getTotalOfPrices('partsPrice')
      return 0
    },
    getTotalPriceOfAll() {
      let t = 0
      for (const di of this.newData.repairParts.map(rp => rp.diagnosticInfo)) {
        t += this.calcTotalPriceOfItem(di)
      }

      return t
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'Save',
          events: {
            'click': this.setChangedDialogItems
          },
          props: {
            'disabled': this.disabled
          }
        }, {
          name: 'SubmitForApproval',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          },
          events: 'AsContinue'
        }
      ]
    },
    getExporter() {
      return async () => await exportRepairDiagnosticsTabXlsx(this.$route.params.id)
    },
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.$parent.isRole(['CLIENT'])) {
          if (h.value === 'totalCost' ||
          h.value === 'partsPrice' || 
          h.value === 'repairPrice' ||
          h.value === 'diagnosticPrice') continue
        }
        headers.push(h)
      }
      return headers
    }
  },
  methods: {
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.dataSavedSuccessfully'
      }
      const parts = this.newData.repairParts
      parts.forEach(p => {
        p.diagnosticInfo.status = p.diagnosticInfo.statusObj ? String(p.diagnosticInfo.statusObj.key) : null
      })
      const data = parts.map(p => {
        return {
          id: p.id,
          serialNumber: p.serialNumber,
          serviceTag: p.serviceTag,
          diagnosticInfo: {
            status: p.diagnosticInfo.status,
            totalCost: p.diagnosticInfo.totalCost,
            comment: p.diagnosticInfo.comment,
            prices: p.diagnosticInfo.prices
          }
        }
      })
      const r = await execHttpRequestThrowable(async () => await updateDiagnosticData(this.$route.params.id, data),
      messages,
      v => this.isLoading = v)
      const oldParts = this.repairProp.repairParts.filter(p => p.initialApproval === 'APPROVED')
      for (let i = 0; i < oldParts.length; i++) cloneObj(parts[i], oldParts[i])
      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItems() {
      const oldParts = this.repairProp.repairParts.filter(p => p.initialApproval === 'APPROVED')
      const newParts = this.newData.repairParts
      const props = [
        {
          field: 'headers.serialNumber',
          prop: 'serialNumber'
        }, {
          field: 'headers.serviceTag',
          prop: 'serviceTag'
        }, {
          field: 'headers.status',
          prop: 'diagnosticInfo.statusObj.key',
          getter: getDiagnosticStatusByKey
        }, {
          field: 'headers.diagnosticPrice',
          prop: 'diagnosticInfo.prices.diagnosticPrice'
        },  {
          field: 'headers.repairPrice',
          prop: 'diagnosticInfo.prices.repairPrice'
        }, {
          field: 'headers.partsPrice',
          prop: 'diagnosticInfo.prices.partsPrice'
        }, {
          field: 'headers.totalCost',
          prop: 'diagnosticInfo.totalCost'
        }, {
          field: 'headers.comment',
          prop: 'diagnosticInfo.comment'
        }
      ]
      
      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareArrays(oldParts, newParts, props, true),
        this.tryToSaveData,
        true
      )
    },
    getTotalOfPrices(prop) {
      let total = 0.0
      const isTotalCost = prop === 'totalCost'
      this.newData.repairParts.forEach(rp => {
        if (rp.diagnosticInfo) {
          const v = isTotalCost ? rp.diagnosticInfo.totalCost : rp.diagnosticInfo.prices[prop]
          if (v !== null && v !== '') {
            total += Number.parseFloat(v)
          }
        }
      })

      return total
    },
    calcTotalPriceOfItem(di) {
      const prices = di.prices

      const pp = Number.parseFloat(prices.partsPrice)
      const rp = Number.parseFloat(prices.repairPrice)
      const dp = Number.parseFloat(prices.diagnosticPrice)

      di.totalCost = (Number.isNaN(pp) ? 0 : pp) + (Number.isNaN(rp) ? 0 : rp) + (Number.isNaN(dp) ? 0 : dp)

      return di.totalCost
    }
  },
  mounted() {
    this.newData = {
      repairParts: [],
      isAllowedToContinue: this.repairProp.isAllowedToContinue
    }
    this.repairProp.repairParts.filter(rp => rp.initialApproval === 'APPROVED').forEach(p => {
      p.diagnosticInfo.statusObj = getObjectDiagnosticStatusByKey(p.diagnosticInfo.status)
      p.diagnosticInfo.totalCost = p.diagnosticInfo.totalCost ? Number.parseFloat(p.diagnosticInfo.totalCost) : 0
      this.newData.repairParts.push(copyObj(p))
    })
    this.isDataReady = true
  }
}
</script>
<style>

</style>