const isDigit = (v) => {
  return new RegExp('^[0-9]+(\\.[0-9]+)?$').test(v)
}
const isInteger = (v) => {
  return new RegExp('^[0-9]+$').test(v)
}
const isEmail = (v) => {
  return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(v)
}


export const ValidService = {
  isDigit,
  isInteger,
  isEmail
}