<template>
  <div
  class="px-8">
    <v-row
    dense
    no-gutters
    class="mt-4">
      <v-col>
        <text-field-password 
        v-model="passwordProp"
        :label="$t('textFields.userPassword')"
        :validate="showConfirmInput"
        :required="required"/>
      </v-col>
    </v-row>
    <v-row
    v-if="showConfirmInput"
    dense
    no-gutters
    class="mt-4">
      <v-col>
        <text-field-confirm-password
        v-model="confirmPasswordProp"
        :label="$t('textFields.confirmPassword')"
        :password="passwordProp"
        :disabled="disabledConfirmInput"
        required/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TextFieldPassword from '@/components/text-fields/TextFieldPassword'
import TextFieldConfirmPassword from '@/components/text-fields/TextFieldConfirmPassword'
export default {
  components: {
    TextFieldPassword,
    TextFieldConfirmPassword
  },
  props: {
    showConfirmOnPasswordInput: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    required: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    password: String,
    confirmPassword: String
  },
  computed: {
    showConfirmInput() {
      return !this.showConfirmOnPasswordInput ||
      (this.showConfirmOnPasswordInput && this.passwordProp && this.passwordProp.length > 0)
    },
    disabledConfirmInput() {
      return !this.showConfirmOnPasswordInput && !this.passwordProp && this.passwordProp.length === 0
    },
    passwordProp: {
      get: function() {
        return this.$props.password
      },
      set: function(v) {
        this.$emit('update:password', v)
      }
    },
    confirmPasswordProp: {
      get: function() {
        return this.$props.confirmPassword
      },
      set: function(v) {
        this.$emit('update:confirmPassword', v)
      }
    }
  }
}
</script>
<style>

</style>