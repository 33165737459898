<template>
  <v-container 
  v-if="isDataReady"
  fluid>
    <v-row dense>
      <v-col>
        <p class="process-repair-tab-section-title">{{$t('pages.remoteSupport.reviewData')}}</p>
      </v-col>
    </v-row>
    <v-form
    v-model="isFormValid">
      <v-row
      justify="center">
        <v-col
        cols="3">
          <text-field-number 
          v-model="serviceReview.durationInHours" 
          :label="$t('textFields.durationInHours')" 
          class="mr-4"
          double
          :disabled="disabled || $parent.isNotRole(['ADMIN', 'SERVICE'])"/>
        </v-col>
        <v-col
        cols="3">
          <text-field-date 
          v-model="serviceReview.completionDate" 
          :label="$t('textFields.completionDate', {'value': getTimeZoneInNumber(new Date())})" 
          :disabled="disabled ||$parent.isNotRole(['ADMIN', 'SERVICE'])"/>
        </v-col>
      </v-row>
      <v-row
      justify="center">
        <v-col
        cols="6">
          <text-area 
          v-model="serviceReview.summary" 
          :label="$t('textFields.summary')" 
          :disabled="disabled || $parent.isNotRole(['ADMIN', 'SERVICE'])"/>
        </v-col>
      </v-row>
    </v-form>
    <v-row
    v-if="$parent.isRole(['ADMIN', 'SERVICE'])">
      <v-col
      class="d-flex justify-center">
        <process-repair-quick-btns 
        :loading.sync="isLoading"
        :config="getQuickBtnsConfig"
        on-success-repair-status-message="dialogs.messages.success.waitingCompletion"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextArea from '@/components/text-fields/TextArea'
import TextFieldDate from '@/components/text-fields/TextFieldDate'
import TextFieldNumber from '@/components/text-fields/TextFieldNumber'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import DateUtil from '@/utils/DateUtil'
import { updateServiceReviewData } from '@/repositories/supportRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { copyObj, cloneObj, compareObjects } from '@/utils/StringUtil'
export default {
  name: 'ServiceReviewTabRemoteSupport',
  components: {
    TextFieldDate,
    TextFieldNumber,
    TextArea,
    ProcessRepairQuickBtns
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      isFormValid: false,
      newData: null,
      isLoading: false,
      isDataReady: false
    }
  },
  methods: {
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.serviceReviewDataSavedSuccessfully'
      }

      const r = await execHttpRequestThrowable(
        async () => await updateServiceReviewData(this.$route.params.id, this.newData.serviceReview),
        messages,
        v => this.isLoading = v)
      cloneObj(this.newData.serviceReview, this.remoteSupportProp.serviceReview)
      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItems() {
      const old = this.remoteSupportProp.serviceReview
      const _new = this.newData.serviceReview
      const props = [
        {
          field: 'textFields.durationInHours',
          prop: 'durationInHours'
        }, {
          field: {
            value: 'textFields.completionDate',
            params: {
              'value': this.getTimeZoneInNumber(new Date())
            }
          },
          prop: 'completionDate'
        }, {
          field: 'textFields.summary',
          prop: 'summary'
        }
      ]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  computed: {
    remoteSupportProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    serviceReview() {
      return this.newData.serviceReview
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'Save',
          events: {
            'click': this.setChangedDialogItems
          },
          props: {
            'disabled': this.disabled || !this.isFormValid
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    }
  },
  mounted() {
    const data = this.remoteSupportProp.serviceReview
    data.completionDate = data.completionDate ? data.completionDate.replaceAll(/T.*$/g, '') : ''
    
    this.newData = {
      isAllowedToContinue: this.remoteSupportProp.allowedToContinue,
      serviceReview: copyObj(this.remoteSupportProp.serviceReview)
    }
    this.isDataReady = true
  }
}
</script>
<style>

</style>