<template>
  <v-row>
    <v-col>
      <div>
        <div 
        class="d-flex align-center flex-wrap" 
        style="width: 100%;">
          <div
          v-for="(t, i) in getTabs" 
          :key="i"
          :class="`process-repair-tab-text d-flex align-center ${isDisabled(t) ? 'process-repair-tab-disabled' : (isSelectedTab(i) ? 'process-repair-tab-selected' : 'process-repair-tab-released')}`"
          @click="!isDisabled(t) ? tabClicked(i) : ''">
            <span style="white-space: nowrap;">{{$t(t.label)}}</span>
          </div>
        </div>
        <div 
        style="width: 100%; height: 1px; background-color: rgba(0,0,0,.07); position: relative; top: -1px;"/>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: () => 0
    },
    status: {
      type: Object,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    },
    getTabs() {
      return this.processTabs()
    }
  },
  data() {
    return {}
  },
  watch: {
    'model': {
      handler() {
        this.processTabs()
      },
      immediate: true
    }
  },
  methods: {
    tabClicked(i) {
      this.model = i
    },
    isDisabled(t) {
      return t.state === 'DISABLED'
    },
    isSelectedTab(i) {
      return this.model === i
    },
    processTabs() {
      const tabs = []
      let found = false
      for (const t of this.tabs) {
        if (!String(t.state).includes('UNCHANGABLE')) {
          t.state = found ? 'DISABLED' : 'ENABLED'
          if (this.$parent.findByStatus(t, this.status.key, this.role)) found = true
        }
        tabs.push(t)
      }

      return tabs
    }
  }
}
</script>
<style>
.process-repair-tab-released {
  padding: 0px 15px;
  color: #007bff;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 #fff;
  height: 40px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.process-repair-tab-released:hover {
  animation-name: process-repair-tab-released-to-hover;
  animation-duration: 500ms;

  padding: 0px 15px;
  color: #495057;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 #fff;
  height: 40px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  cursor: pointer;
  border-top: 1px solid #a3a3a369;
  border-left: 1px solid #a3a3a369;
  border-right: 1px solid #a3a3a369;
}
.process-repair-tab-released:not(:hover) {
  animation-name: process-repair-tab-released-mouse-leave;
  animation-duration: 500ms;

  padding: 0px 15px;
  color: #007bff;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 #fff;
  height: 40px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.process-repair-tab-selected {
  padding: 0px 15px;
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  height: 40px;
  border-top: 1px solid #a3a3a369;
  border-left: 1px solid #a3a3a369;
  border-right: 1px solid #a3a3a369;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  position: relative;
  z-index: 3;
}
.process-repair-tab-disabled {
  padding: 0px 15px;
  color: #6c757d;
  height: 40px;
  position: relative;
  z-index: 3;
  cursor: pointer;
}
.process-repair-tab-text {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}

@keyframes process-repair-tab-released-to-hover {
  from {
    padding: 0px 15px;
    color: #007bff;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 #fff;
    height: 40px;
    display: flex;
    align-items: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  to {
    padding: 0px 15px;
    color: #495057;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 #fff;
    height: 40px;
    display: flex;
    align-items: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    cursor: pointer;
    border-top: 1px solid #a3a3a369;
    border-left: 1px solid #a3a3a369;
    border-right: 1px solid #a3a3a369;
  }
}
@keyframes process-repair-tab-released-mouse-leave {
  from {
    padding: 0px 15px;
    color: #495057;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 #fff;
    height: 40px;
    display: flex;
    align-items: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    cursor: pointer;
    border-top: 1px solid #a3a3a369;
    border-left: 1px solid #a3a3a369;
    border-right: 1px solid #a3a3a369;
  }
  to {
    padding: 0px 15px;
    color: #007bff;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 #fff;
    height: 40px;
    display: flex;
    align-items: center;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
}

.process-repair-tab-section-title {
  color: #007bff;
  font-style: italic;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.process-repair-tab-section-subtitle {
  color: #212529;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
</style>