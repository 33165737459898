<template>
  <v-dialog
  v-model="model"
  width="500"
  @click:outside="closeDialog">
    <v-card
    color="white"
    width="100%">
      <div class="confirm-action-dialog-header confirm-action-dialog-header-title d-flex justify-space-between align-center px-5">
        <span>{{$t(getHeaderTitle)}}</span>
        <v-btn
        icon
        dark
        @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-row 
      v-if="setUsernameField"
      dense 
      no-gutters 
      class="px-10 my-5">
        <v-col>
          <text-field-username 
          v-model="innerData.username"
          @enter="emitSubmit"
          :label="$t('textFields.username')"
          required/>
        </v-col>
      </v-row>
      <v-row 
      v-if="setEmailField"
      dense 
      no-gutters 
      class="px-10 my-5">
        <v-col>
          <text-field-email 
          v-model="innerData.email"
          @enter="emitSubmit"
          :label="$t('textFields.email')"
          required/>
        </v-col>
      </v-row>
      <div 
      class="mt-5"
      style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
      <v-row dense no-gutters class="pa-4 pb-6" justify="center">
        <v-col class="d-flex justify-center">
          <primary-btn
          :label="$t('btns.submitRequest')"
          color="#33b5e5"
          :loading="isLoading"
          :disabled="setUsernameField ? innerData.username.length === 0 : false || setEmailField ? !isEmailValid : false"
          :width="150"
          :height="45"
          @click="emitSubmit"/>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import TextFieldUsername from '@/components/text-fields/TextFieldUsername'
import TextFieldEmail from '@/components/text-fields/TextFieldEmail'
import { ValidService } from '@/utils/Validation'
export default {
  components: {
    PrimaryBtn,
    TextFieldUsername,
    TextFieldEmail
  },
  props: {
    value: Boolean,
    setUsernameField: Boolean,
    setEmailField: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  data() {
    return {
      isLoading: false,
      innerData: {
        username: '',
        email: ''
      }
    }
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    },
    getHeaderTitle() {
      if (this.setUsernameField && this.setEmailField) {
        // Nothing for now
        return ''
      }
      else if (this.setUsernameField) return 'dialogs.titles.forgottenPassword'
      else if (this.setEmailField) return 'dialogs.titles.retrieveUsername'
      else return ''
    },
    isEmailValid() {
      return ValidService.isEmail(this.innerData.email)
    }
  },
  methods: {
    emitSubmit() {
      if ((this.setUsernameField && this.innerData.username.length === 0) || (this.setEmailField && !this.isEmailValid)) return
      this.isLoading = true
      this.$emit('submit', this.innerData)
    },
    closeDialog() {
      this.model = false
    }
  }
}
</script>
<style>
.confirm-action-dialog-header {
  width: 100%;
  height: 70px;
  background-color: #33b5e5;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.144);
  border-radius: 0px!important;
}
.confirm-action-dialog-header-title {
  font-family: 'Titillium Web', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.confirm-action-dialog-content {
  font-family: 'Titillium Web', sans-serif;
  color: black;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
</style>