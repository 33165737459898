<template>
  <v-switch
  v-model="model"
  :label="$t(label)"
  :readonly="readonly"
  :disabled="disabled"
  :loading="loading"
  :color="color"
  hide-details
  @change="$emit('change')">
  </v-switch>
</template>
<script>
export default {
  props: {
    label: String,
    required: Boolean,
    value: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    loading: Boolean,
    color: {
      type: String,
      default: () => {
        return null
      }
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      
    }
  }
}
</script>
<style>
</style>