<template>
  <v-container>
    <v-row dense no-gutters class="mt-10">
        <v-col>
          <p class="sts-description-title text-center mb-8">{{$t('pages.stsDescription.title')}}</p>
          <p class="sts-description-subtitle text-center">{{$t('pages.stsDescription.subtitle')}}</p>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  components: {
  },
  created() {
    this.$feedback.fill("STS Description Page", this.$route.path)
  }
}
</script>
<style>
.sts-description-title {
  color: #212529;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.sts-description-subtitle {
  color: #212529;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
</style>