import { put } from '@/services/http'
import { getUserIdentification, getUserToken } from '@/services/authService'

export function saveClientsForManager(username, data) {
  return put(`/api/v1/sustainability-manager/save/clients?currentUsername=${getUserIdentification()}&specifiedUsername=${username}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}