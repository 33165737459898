<template>
  <v-btn
  @click="$emit('click')"
  :width="width"
  :height="height"
  elevation="5"
  outlined
  :block="block"
  :loading="loading"
  :style="`background: ${innerColor ? innerColor: '#ffffff'}; border: 2px solid ${color ? color : '#4285f4'}; color: ${color ? color : '#4285f4'};`"
  :disabled="disabled"
  class="outlined-btn rounded-0 outlined-btn-font-medium">
    {{label}}
  </v-btn>
</template>
<script>
export default {
  props: {
    label: String,
    width: Number,
    height: Number,
    disabled: Boolean,
    color: String,
    innerColor: String,
    loading: Boolean,
    block: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>
<style>
.outlined-btn-font-medium.v-btn {
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
.outlined-btn-font-small.v-btn {
  font-weight: 400;
  font-size: 0.6rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}

.outlined-btn-font-medium.v-btn.v-btn--disabled{
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
.outlined-btn-font-small.v-btn.v-btn--disabled {
  font-weight: 400;
  font-size: 0.6rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
</style>