import { get, put } from '@/services/http'
import { getUserIdentification, getUserToken } from '@/services/authService'

export function fetchAllNotificationSettings() {
  return get(`/api/v1/mail/fetch?username=${getUserIdentification()}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function editNotificationSettings(data) {
  return put(`/api/v1/mail/save?username=${getUserIdentification()}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}

