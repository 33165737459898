import Vue from 'vue'

class ImagePreviewDialogPlugin {
  model
  title
  value
  constructor() {
    this.model = false
    this.title = null
    this.value = null
  }
  open(value, title) {
    this.value = value
    this.title = title
    this.model = true
  }
  close() {
    this.model = false
    this.title = null
    this.value = null
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$imagePreviewDialog = Vue.observable(new ImagePreviewDialogPlugin())
  }
}