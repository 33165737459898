var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{staticClass:"pa-4",attrs:{"number-pages":"","headers":_vm.getHeaders,"items":_vm.items,"page":_vm.page,"size":_vm.size,"loading":_vm.isLoading,"onUpdateSize":_vm.tryToFetchRepairs,"onPerviousBtnClick":_vm.tryToFetchRepairs,"onNextBtnClick":_vm.tryToFetchRepairs,"total-elements":_vm.totalElements},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.completedAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.clientUsername",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.details",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatus(item.status)))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.createdAt))+" ")])]}},{key:"item.completedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.updatedAt))+" ")])]}},(_vm.getRole !== 'CLIENT')?{key:"item.clientUsername",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.clientUsername)+" ")])]}}:null,{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('route-btn',{attrs:{"href":("process-repair/" + (item.id)),"label":"btns.viewDetails","width":120,"height":30}})]}}],null,true)})],1)],1),_c('export-xlsx-row',{staticClass:"px-4",attrs:{"width":150,"height":50,"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"name":"export.title.closedRepairs","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }