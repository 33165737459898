<template>
	<v-tabs
	v-model="model">
		<v-tab
		v-for="(item, i) in items"
		:key="i">
			{{$t(item.label)}}
		</v-tab>
	</v-tabs>
</template>
<script>
export default {
	props: {
		value: Number,
		items: {
			type: Array,
			required: true
		}
	},
	model: {
		prop: 'value',
		event: 'valueChanged'
	},
	computed: {
		model: {
			get: function() {
				return this.$props.value
			},
			set: function(v) {
				this.$emit('valueChanged', v)
			}
		}
	}
}
</script>
<style>

</style>