<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="`nav-btn text-none ${btn.highlight ?  'nav-item-pushed' : ''}`"
        text
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon 
        size="19"
        class="mr-1">
          {{btn.icon}}
        </v-icon>
        {{$t(btn.label)}}
        <v-icon 
        size="19"
        class="mr-1">
          mdi-menu-down
        </v-icon>
        <v-badge
        v-if="btn.label === 'pages.partRequest.menu' && partsNumber > 0"
          color="red"
          :content="partsNumber > 9 ? '9+' : partsNumber"
          inline
        />
      </v-btn>
    </template>
    <div class="nav-menu-container">
      <v-row 
      no-gutters
      dense
      class="my-2"
      v-for="(c, i) in btn.children"
            :key="i">
        <v-col
        v-if="isGranted(c.roles)">
          <div
            @click="c.link ? routeTo(c.link) : c.action()"
            class="d-flex justify-start align-center nav-menu-item">
              <span>{{$t(c.label)}} </span> <!-- {{c.link === 'profile' ? getContact.firstName : ''}} -->
            </div>
        </v-col>
      </v-row>
    </div>
  </v-menu>
</template>
<script>
import { routeFromRoot } from '@/services/routeService'
import { getUserRole } from '@/services/authService'
export default {
  props: {
    btn: Object
  },
  data() {
    return {
      partsNumber: 0
    }
  },
  watch: {
    '$actionRequired.partsNumber': {
      handler(v) {
        this.partsNumber = v
      },
      immediate: true
    }
  },
  computed: {
    getContact() {
      return this.$contactHolder.get()
    }
  },
  methods: {
    routeTo(l) {
      routeFromRoot(l)
    },
    isGranted(roles) {
      return roles.includes(getUserRole())
    }
  }
}
</script>
<style>
.nav-btn.v-btn.v-btn--text.theme--dark {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.05rem;
  line-height: inherit;
  letter-spacing: 0px;
  font-weight: 300;
  padding: 0px 10px;
}

.nav-menu-container {
  background-color: white;
  padding: 5px 8px;
  width: 100%;
}

.nav-menu-item {
  background-color: white;
  color: black;
  height: 40px;
  padding: 0px 10px;

  font-family: 'Titillium Web', sans-serif;
  font-size: 0.89rem;
  line-height: inherit;
  letter-spacing: 1px;
  font-weight: 300;
  box-shadow: 0px 0px 0px 0px transparent;
}

.nav-menu-item:hover {
  animation-name: nav-menu-item-to-hover;
  animation-duration: 100ms;

  background-color: #00B0E3;
  color: white!important;
  height: 40px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.363);

  font-family: 'Titillium Web', sans-serif;
  font-size: 0.89rem;
  line-height: inherit;
  letter-spacing: 1px;
  font-weight: 300;
}

.nav-menu-item:not(:hover) {
  animation-name: nav-menu-item-mouse-leave;
  animation-duration: 100ms;

  background-color: white;
  color: black;
  height: 40px;
  padding: 0px 10px;

  font-family: 'Titillium Web', sans-serif;
  font-size: 0.89rem;
  line-height: inherit;
  letter-spacing: 1px;
  font-weight: 300;
  box-shadow: 0px 0px 0px 0px transparent;
}

@keyframes nav-menu-item-to-hover {
  from {
    background-color: white;
    color: black;
    height: 40px;
    padding: 0px 10px;

    font-family: 'Titillium Web', sans-serif;
    font-size: 0.89rem;
    line-height: inherit;
    letter-spacing: 1px;
    font-weight: 300;
    box-shadow: 0px 0px 0px 0px transparent;
  }
  to {
    background-color: #00B0E3;
    color: white!important;
    height: 40px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.363);

    font-family: 'Titillium Web', sans-serif;
    font-size: 0.89rem;
    line-height: inherit;
    letter-spacing: 1px;
    font-weight: 300;
  }
}
@keyframes nav-menu-item-mouse-leave {
  from {
    background-color: #00B0E3;
    color: white!important;
    height: 40px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.363);

    font-family: 'Titillium Web', sans-serif;
    font-size: 0.89rem;
    line-height: inherit;
    letter-spacing: 1px;
    font-weight: 300;
  }
  to {
    background-color: white;
    color: black;
    height: 40px;
    padding: 0px 10px;

    font-family: 'Titillium Web', sans-serif;
    font-size: 0.89rem;
    line-height: inherit;
    letter-spacing: 1px;
    font-weight: 300;
    box-shadow: 0px 0px 0px 0px transparent;
  }
}
</style>