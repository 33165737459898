var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.normal)?_c('v-autocomplete',{attrs:{"rules":_vm.rules,"label":_vm.label,"placeholder":_vm.placeholder,"item-text":_vm.itemText,"item-value":_vm.itemValue,"return-object":"","auto-select-first":_vm.autoSelectFirst,"hide-details":"auto","items":_vm.items,"disabled":_vm.disabled,"clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.value)?_c('span',[_vm._v(_vm._s(_vm.$t(item.value)))]):(item.name)?_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.value)?_c('span',[_vm._v(_vm._s(_vm.$t(item.value)))]):(item.name)?_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))]):_vm._e()]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_c('v-autocomplete',{staticClass:"normal-text-field",attrs:{"rules":_vm.rules,"label":_vm.label,"placeholder":_vm.placeholder,"items":_vm.items,"item-text":_vm.itemText,"item-value":_vm.itemValue,"auto-select-first":_vm.autoSelectFirst,"return-object":"","disabled":_vm.disabled,"flat":"","solo":"","dense":"","hide-details":"auto","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.value)?_c('span',[_vm._v(_vm._s(_vm.$t(item.value)))]):(item.name)?_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.value)?_c('span',[_vm._v(_vm._s(_vm.$t(item.value)))]):(item.name)?_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))]):_vm._e()]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }