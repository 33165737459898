import Vue from 'vue'

class FailedDialogPlugin {
  model
  action
  title
  message
  constructor() {
    this.model = false
    this.action = null
    this.title = null
    this.message = null
  }
  open(title, message, action) {
    this.title = title ? title : 'dialogs.titles.failed'
    this.message = message
    this.action = action
    this.model = true
  }
  close() {
    this.model = false
    this.action = null
    this.title = null
    this.message = null
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$failedDialog = Vue.observable(new FailedDialogPlugin())
  }
}