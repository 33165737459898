<template>
  <v-row 
  dense
  no-gutters>
    <v-col class="d-flex justify-end align-center">
      <primary-btn
      :label="$t('btns.exportXLSX')"
      @click="exportFile"
      :disabled="disabled"
      :loading="loadingProp"
      :width="width"
      :height="height"
      :color="color"/>
    </v-col>
  </v-row>
</template>
<script>
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { saveAs } from 'file-saver'
import { execHttpRequestThrowable } from '@/services/http'
export default {
  components: {
    PrimaryBtn
  },
  props: {
    width: {
      type: Number,
      default: () => {
        return 80
      }
    },
    height: {
      type: Number,
      default: () => {
        return 50
      }
    },
    color: {
      type: String,
      default: () => {
        return '#4285f4'
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    exporter: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    loadingProp: {
      get: function() {
        return this.$props.loading
      },
      set: function(v) {
        this.$emit('update:loading', v)
      }
    }
  },
  methods: {
    async exportFile() {
      const r = await execHttpRequestThrowable(async () => await this.exporter(), {}, v => this.loadingProp = v)
      saveAs(r.response.data, `${this.$t(this.name)}.xlsx`)
    }
  }
}
</script>
<style>

</style>