export const ROLES = [
  {
    key: 'CLIENT',
    value: 'roles.client'
  }, {
    key: 'SERVICE',
    value: 'roles.service'
  }, {
    key: 'SUPERVISOR',
    value: 'roles.supervisor'
  }, {
    key: 'ADMIN',
    value: 'roles.admin'
  }, {
    key: 'SUSTAINABILITY_MANAGER',
    value: 'roles.sustainabilityManager'
  }
]

export function getRoleByKey(k) {
  const s = ROLES.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}
export function getObjectRoleByKey(k) {
  const s = ROLES.filter(s => s.key === k)

  if (s.length > 0) return {
    key: String(s[0].key),
    value: String(s[0].value)
  }
  else return null
}