<template>
  <v-container fluid>
    <v-container style="padding-left: 200px;">
      <v-form
      :disabled="!isSuggestFormEmpty">
        <v-row 
        dense 
        class="mt-2"
        justify="start" 
        align="center">
          <v-col cols="2" class="mx-4">
            <report-filter-autocomplete-object
            :label="$t('textFields.itemCategoryLabel')"
            v-model="newPart.category"
            @input="tryToFetchPartsByCriteria"
            item-text="name"
            item-value="key"
            normal
            :items="categories"/>
          </v-col>
          <v-col cols="2" class="mx-4">
            <report-filter-autocomplete-object
            :label="$t('textFields.partLabel')"
            :disabled="!newPart.category"
            v-model="newPart.part"
            item-text="model"
            item-value="id"
            normal
            :items="parts"/>
          </v-col>
          <v-col cols="2" class="mx-4">
            <text-field-number-with-label
            :label="$t('textFields.quantityLabel')"
            v-model="newPart.quantity"
            :disabled="!newPart.part"
            :max="100"
            normal/>
          </v-col>
          <v-col cols="1" class="mx-4">
            <primary-btn
            @click="addPart"
            :label="$t('btns.addPart')"
            color="#4285f4"
            :disabled="!isFormValid && !isSuggestFormValid"
            :width="100"
            :height="40"/>
          </v-col>
          <v-col cols="2" class="mx-4">
            <primary-btn
            @click="openConfirmActionDialog(tryToCreatePartRequest)"
            :label="$t('btns.submitRequest')"
            color="#ffa000"
            :disabled="(selectedParts.length === 0 && suggestSelectedParts.length === 0) || !userDataForm"
            :width="150"
            :height="40"/>
          </v-col>
        </v-row>
      </v-form>
      <!--<div class="d-flex justify-start align-center mt-10 pl-4">
        <h4>
          {{$t('pages.partRequest.iDontFindMyPart')}}
        </h4>
      </div>
      <v-form
      ref="sForm"
      :disabled="!isFormEmpty">
        <v-row 
        dense 
        class="mt-2"
        justify="start" 
        align="center">
          <v-col cols="2" class="mx-4">
            <text-field-value-with-label
            :label="$t('textFields.partName')"
            v-model="suggestPart.partName"
            />
          </v-col>
          <v-col cols="2" class="mx-4">
          <text-field-number-with-label
          :label="$t('textFields.quantityLabel')"
          v-model="suggestPart.quantity"
          :max="100"
          :disabled="!suggestPart.partName || suggestPart.partName.length === 0"
          normal/>
          </v-col>
          <v-col cols="2" class="mx-4">
          <text-field-file-with-label
          ref="newPartRequestFileRef"
          id="new-part-request-file"
          v-model="suggestPart.file"
          :label="$t('textFields.uploadYourFile')"
          :disabled="!suggestPart.partName || suggestPart.partName.length === 0"
          @input="(v) => suggestPart.file = [...v]"
          @clear="suggestPart.file = []"
          remove-btn/>
          </v-col>
        </v-row>
      </v-form> -->
      <v-form
      v-if="contact && address"
      ref="userDataForm"
      v-model="userDataForm"
      class="px-4">
        <v-row dense class="mt-10">
          <v-col>
            <p class="process-repair-tab-section-title">{{$t('pages.repair.contactDetails')}}</p>
          </v-col>
        </v-row>
        <v-row dense>
        <v-col class="mr-4">
          <text-field-first-name 
          v-model="contact.firstName" 
          required/>
        </v-col>
        <v-col class="ml-4">
          <text-field-last-name 
          v-model="contact.lastName" 
          required/>
        </v-col>
        <v-col class="ml-4">
          <text-field-phone 
          v-model="contact.phone" 
          required/>
        </v-col>
      </v-row>
      <v-row dense  class="mt-15">
          <v-col>
            <p class="process-repair-tab-section-title">{{$t('pages.repair.addressDetails')}}</p>
          </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <text-field-value 
          v-model="address.country" 
          :label="$t('textFields.country')" 
          class="mr-4" 
          required/>
        </v-col>
        <v-col>
          <text-field-value 
          v-model="address.province" 
          :label="$t('textFields.state')" 
          class="ml-4" />
        </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-col>
          <text-field-value 
          v-model="address.city" 
          :label="$t('textFields.city')" 
          class="mr-4"
          required/>
        </v-col>
        <v-col>
          <text-field-value 
          v-model="address.postalCode" 
          :label="$t('textFields.postalCode')" 
          class="ml-4"
          required/>
        </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-col>
          <text-field-value 
          v-model="address.street" 
          :label="$t('textFields.streetDetails')" 
          class="mr-4"
          required/>
        </v-col>
        <v-col>
          <text-field-value 
          v-model="address.building"
          :label="$t('textFields.building')" 
          class="ml-4"/>
        </v-col>
      </v-row>
      </v-form>
    </v-container>
    <main-data-table
    number-pages
    :headers="headers"
    :items="getItems"
    :page.sync="page"
    :size.sync="size"
    class="pa-4">
      <template v-slot:[`header.imageUrl`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:[`header.category`]="{ header }">
          {{$t(header.text)}}  
      </template>
      <template v-slot:[`header.partNumber`]="{ header }">
          {{$t(header.text)}}  
      </template>
      <template v-slot:[`header.quantity`]="{ header }">
          {{$t(header.text)}}
      </template>
      <template v-slot:[`header.deleteRow`]="{ header }">
          {{$t(header.text)}}
      </template>

      <template v-slot:[`item.imageUrl`]="{ item }">
        <previewable-image
        v-model="item.imageUrl"
        height="40"
        width="50"
        alt="Part Image"
        class="pa-5"/>
      </template>
      <template v-slot:[`item.category`]="{ item }">
          {{item.category}}
      </template>
      <template v-slot:[`item.partNumber`]="{ item }">
          {{item.partName}}
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
          {{item.quantity}}
      </template>
      <template v-slot:[`item.deleteRow`]="{ index, item }">
          <primary-btn
          :label="$t('btns.delete')"
          @click="deleteRow(index, item.isSuggest)"
          color="#ff3547"
          :width="80"
          :height="30"/>
      </template>
    </main-data-table>
  </v-container>
</template>
<script>
import ReportFilterAutocompleteObject from '@/components/report/ReportFilterAutocompleteObject'
import TextFieldNumberWithLabel from '@/components/text-fields/TextFieldNumberWithLabel'
// import TextFieldValueWithLabel from '@/components/text-fields/TextFieldValueWithLabel'
// import TextFieldFileWithLabel from '@/components/text-fields/TextFieldFileWithLabel'
import TextFieldFirstName from '@/components/text-fields/TextFieldFirstName'
import TextFieldLastName from '@/components/text-fields/TextFieldLastName'
import TextFieldPhone from '@/components/text-fields/TextFieldPhone'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import PreviewableImage from '@/components/common/PreviewableImage'

import MainDataTable from '@/components/common/MainDataTable'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { fetchAllCategories, fetchAllPartsByCategory } from '@/repositories/partRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { fetchAddressByIdentification } from '@/repositories/addressRepo'
import { ValidService } from '@/utils/Validation'
import { isLanguageEn } from '@/services/authService'
import StringUtil from '@/utils/StringUtil'
import { createNewPartRequestItems, 
         createNewPartRequestSuggestItem, 
         notifyServiceForNewPartRequest } from '@/repositories/partRequestRepo'
import { routeFromRoot } from '@/services/routeService'
export default {
  components: {
    ReportFilterAutocompleteObject,
    TextFieldNumberWithLabel,
    // TextFieldFileWithLabel,
    // TextFieldValueWithLabel,
    TextFieldFirstName,
    TextFieldLastName,
    TextFieldPhone,
    PrimaryBtn,
    TextFieldValue,
    MainDataTable,
    PreviewableImage
  },
  data() {
    return {
      categories: [],
      parts: [],
      headers: [ {
          text: 'headers.image',
          align: 'start',
          sortable: true,
          value: 'imageUrl'
        },{
          text: 'headers.itemCategory',
          align: 'start',
          sortable: true,
          value: 'category'
        }, {
          text: 'headers.partNumber',
          align: 'start',
          sortable: true,
          value: 'partNumber'
        }, {
          text: 'headers.quantity',
          align: 'start',
          sortable: true,
          value: 'quantity'
        }, {
          text: 'headers.deleteRow',
          align: 'start',
          sortable: true,
          value: 'deleteRow'
        }
      ],
      page: 1,
      size: 10,
      selectedParts: [],
      suggestSelectedParts: [],
      newPart: {
        category: null,
        part: null,
        quantity: ''
      },
      suggestPart: {
        partName: '',
        quantity: '',
        file: []
      },
      address: null,
      contact: null,
      isLoading: false,
      userDataForm: false
    }
  },
  computed: {
    isFormEmpty() {
      return !this.newPart.category && 
             !this.newPart.part && 
             (!this.newPart.quantity || this.newPart.quantity === '0' || this.newPart.quantity.length === 0)
    },
    isSuggestFormEmpty() {
      return (!this.suggestPart.partName || this.suggestPart.partName.length === 0) &&
              this.suggestPart.file.length === 0 && 
              (!this.suggestPart.quantity || this.suggestPart.quantity === '0' || this.suggestPart.quantity.length === 0)
    },
    isFormValid() {
      return this.newPart.category &&
             this.newPart.part && 
             this.newPart.quantity &&
             ValidService.isInteger(this.newPart.quantity)
    },
    isSuggestFormValid() {
      return this.suggestPart.partName &&
             this.suggestPart.partName.length > 0 &&
             this.suggestPart.file.length > 0 && 
             ValidService.isInteger(this.suggestPart.quantity)
    },
    getItems() {
      let items = []
      this.suggestSelectedParts.forEach(o => o.isSuggest = true)

      for (const f of this.suggestSelectedParts) {
        items.push(f)
      }
      for (const f of this.selectedParts) {
        items.push(f)
      }

      return items
    },
    isEN() {
      return isLanguageEn()
    }
  },
  watch: {
    '$contactHolder.contact': {
      handler() {
        this.putContactData()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async tryToFetchAllCategories() {
      const r = await execHttpRequestThrowable(fetchAllCategories)
      this.categories = [...r.response.data]
    },
    async tryToFetchPartsByCriteria() {
      if (this.newPart.category === null) {
        this.parts = []
        this.newPart.part = null
        this.newPart.quantity = '0'
        return
      }

      const r = await execHttpRequestThrowable(async () => await fetchAllPartsByCategory(this.newPart.category.key, null, ['PARTS']))
      this.parts = [...r.response.data]
    },
    addPart() {
      if (!this.isFormEmpty) {
        const p = this.selectedParts.filter(p => StringUtil.isEqual(p.id, this.newPart.part.id))
        
        if (p.length > 0) {
          p[0].quantity = parseInt(p[0].quantity)
          p[0].quantity += parseInt(this.newPart.quantity)
        }
        else {
          this.selectedParts.push({
            id: this.newPart.part.id,
            category: this.newPart.category.name,
            partName: this.newPart.part.model,
            quantity: this.newPart.quantity,
            imageUrl: this.newPart.part.imageUrl
          })
        }
      }
      else if (!this.isSuggestFormEmpty) {
        const p = this.suggestSelectedParts.filter(p => StringUtil.isEqual(p.partName, this.suggestPart.partName))
        if (p.length > 0) {
          p[0].quantity = parseInt(p[0].quantity)
          p[0].quantity += parseInt(this.suggestPart.quantity)
        }
        else {
          this.suggestSelectedParts.push({
            id: -1,
            category: this.suggestPart.category,
            partName: this.suggestPart.partName,
            quantity: this.suggestPart.quantity,
            imageUrl: window.URL.createObjectURL(this.suggestPart.file[0]),
            file: this.suggestPart.file[0]
          })
        }
      }

      this.clearForms()
    },
    clearForms() {
      this.newPart = {
        category: null,
        part: null,
        quantity: ''
      }
      this.suggestPart = {
        partName: '',
        quantity: '',
        file: []
      }

      // this.$refs.newPartRequestFileRef.clearFilesValue()
    },
    deleteRow(i, isSuggest) {
      if (isSuggest) this.suggestSelectedParts.splice(i, 1)
      else this.selectedParts.splice(i, 1)
    },
    openConfirmActionDialog(a) {
      this.$confirmActionDialog.open(
        'dialogs.titles.validate', 
        'dialogs.subtitles.requestNewPartWarning', 
        'btns.request',
        'btns.continueEditing',
        a)
    },
    async tryToCreatePartRequest() { 
      try {
        let partRequestId = -1
        const data = {
          id: null,
          contact: this.contact,
          address: this.address,
          parts: this.selectedParts
        }
        const r = await execHttpRequestThrowable(async () => await createNewPartRequestItems(data, null))
        partRequestId = r.response.data

        for (const p of this.suggestSelectedParts) {
          const formData = new FormData()
          formData.append('multipartFiles', p.file)
          formData.append('partName', p.partName)
          formData.append('quantity', p.quantity)
          formData.append('firstName', data.contact.firstName)
          formData.append('lastName', data.contact.lastName)
          formData.append('phone', data.contact.phone)
          formData.append('email', data.contact.email)
          formData.append('companyName', data.address.companyName)
          formData.append('country', data.address.country)
          formData.append('province', data.address.province)
          formData.append('city', data.address.city)
          formData.append('postalCode', data.address.postalCode)
          formData.append('street', data.address.street)
          formData.append('building', data.address.building)
          await execHttpRequestThrowable(async () => await createNewPartRequestSuggestItem(formData, partRequestId))
        }

        if (partRequestId !== -1) {
          execHttpRequestThrowable(async () => await notifyServiceForNewPartRequest(partRequestId))
          routeFromRoot('part-request/list')
        }
      }
      catch(e) {
        if (e.name === 'HttpRequestFailedException') console.error(e)
        else this.openFailedDialog(this.$t('dialogs.messages.failed.internalErrorOccurred'))
      }
    },
    async tryToFetchAddressByIdentification() {
      const r = await execHttpRequestThrowable(fetchAddressByIdentification)
      this.address = r.response.data
    },
    putContactData() {
      const c = this.$contactHolder.get()
      this.contact = {
        id: c.id,
        firstName: c.firstName,
        lastName: c.lastName,
        phone: c.phone,
        email: c.email,
        backupEmails: c.backupEmails
      }
    },
    openFailedDialog(m) {
      this.$failedDialog.open(null, m, null)
    }
  },
  async mounted() {
    await this.tryToFetchAllCategories()
    await this.tryToFetchAddressByIdentification()

    this.$feedback.fill("Create Part Request Page", this.$route.path)
  }
}
</script>
<style>

.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
.new-repair-group-title {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: 0px;
}
.v-expansion-panel-header__icon > i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: blue;
}
</style>