var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('report-filter',{on:{"filter":_vm.tryToFilterRepairs}})],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{staticClass:"pa-4",attrs:{"number-pages":"","headers":_vm.headers,"items":_vm.items,"page":_vm.page,"size":_vm.size,"loading":_vm.isLoading,"onUpdateSize":_vm.tryToFetchRepairs,"onPerviousBtnClick":_vm.tryToFetchRepairs,"onNextBtnClick":_vm.tryToFetchRepairs,"total-elements":_vm.totalElements},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.updatedAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatus(item.status)))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.createdAt))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.updatedAt))+" ")])]}},{key:"item.clientUsername",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.clientUsername)+" ")])]}},{key:"item.serviceUsername",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.serviceUsername)+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('route-btn',{attrs:{"href":("process-repair/" + (item.id)),"target":"blank","label":"btns.viewDetails","width":120,"height":30}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end align-center pr-4"},[_c('primary-btn',{attrs:{"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"width":150,"height":50,"color":"#4285f4","label":_vm.$t('btns.exportXLSX')},on:{"click":_vm.tryToExportGeneralReportXlsx}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }