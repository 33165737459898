const BTNS = {
  'Save': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 100,
      'height': 45,
      'class': "mx-2",
      'label': "btns.save"
    },
    events: {}
  },
  'RejectAll': {
    component: 'OutlinedBtn',
    props: {
      'color': "#ff3547",
      'width': 120,
      'height': 45,
      'class': "mx-2",
      'label': "btns.rejectAll"
    },
    events: {}
  },
  'ApproveAll': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 120,
      'height': 45,
      'class': "mx-2",
      'label': "btns.approveAll"
    },
    events: {}
  },
  'ApproveMarked': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 150,
      'height': 45,
      'class': "mx-2",
      'label': "btns.approveMarked"
    },
    events: {}
  },
  'Reject': {
    component: 'OutlinedBtn',
    props: {
      'color': "#ff3547",
      'width': 120,
      'height': 45,
      'class': "mx-2",
      'label': "btns.reject"
    },
    events: {}
  },
  'Approve': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 120,
      'height': 45,
      'class': "mx-2",
      'label': "btns.approve"
    },
    events: {}
  },
  'Continue': {
    component: 'PrimaryBtn',
    props: {
      'color': "#ffa000",
      'width': 120,
      'height': 45,
      'class': "mx-2",
      'label': "btns.continue"
    },
    events: {}
  },
  'PackagePickedUp': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 230,
      'height': 45,
      'class': "mx-2",
      'label': "btns.packagesPickedUp"
    },
    events: {}
  },
  'ShipmentArrived': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 180,
      'height': 45,
      'class': "mx-2",
      'label': "btns.shipmentArrived"
    },
    events: {}
  },
  'DiagnosticDate': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 350,
      'height': 45,
      'class': "mx-2",
      'label': "btns.setDiagnosticDate"
    },
    events: {}
  },
  'SubmitForApproval': {
    component: 'PrimaryBtn',
    props: {
      'color': "#ffa000",
      'width': 200,
      'height': 45,
      'class': "mx-2",
      'label': "btns.submitForApproval"
    },
    events: {}
  },
  'AllInProgress': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 150,
      'height': 45,
      'class': "mx-2",
      'label': "btns.allInProgress"
    },
    events: {}
  },
  'AllReplaced': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 150,
      'height': 45,
      'class': "mx-2",
      'label': "btns.allReplaced"
    },
    events: {}
  },
  'AllRepaired': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 150,
      'height': 45,
      'class': "mx-2",
      'label': "btns.allRepaired"
    },
    events: {}
  },
  'AllNonRepairable': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 150,
      'height': 45,
      'class': "mx-2",
      'label': "btns.allNonRepairable"
    },
    events: {}
  },
  'ConfrimDelivery': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 180,
      'height': 45,
      'class': "mx-2",
      'label': "btns.confrimDelivery"
    },
    events: {}
  },
  'Complete': {
    component: 'OutlinedBtn',
    props: {
      'color': "#4285f4",
      'width': 180,
      'height': 45,
      'class': "mx-2",
      'label': "btns.complete"
    },
    events: {}
  }
}


export function getBtnDataByName(n) {
  return BTNS[n]
}