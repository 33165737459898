import Vue from 'vue'

class FeedbackPlugin {
  pageName
  pagePath
  constructor() {
    this.pageName = ""
    this.pagePath = ""
  }
  fill(pageName, pagePath) {
    this.pageName = pageName
    this.pagePath = String(pagePath).substring(3) //remove "/es" -> the language in path! exp: /es/active-repairs
  }
  get() {
    return {
      pageName: this.pageName,
      pagePath: this.pagePath
    }
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$feedback = Vue.observable(new FeedbackPlugin())
  }
}