<template>
  <v-container fluid>
    {{isPathChanged}}
    <v-row dense no-gutters justify="center">
      <v-col
      v-if="isCreating || isEditing"
      lg="3"
      class="d-flex justify-center align-center pt-10">
        <account-details-card
        :contact.sync="contact"/>
      </v-col>
      <v-col
      lg="3"
      v-if="isEditing && isContactRole(['CLIENT', 'SERVICE'])"
      class="d-flex justify-center align-center pt-10">
        <address-details-card
        :address.sync="address"/>
      </v-col>
      <v-col
      lg="3"
      v-if="isEditing && !isCurrentRole(['SERVICE']) && isContactRole(['SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
      class="d-flex justify-center align-center pt-10">
        <additional-details-card 
        :current-user-role="getRole" 
        :selected-user-role="contact.role" 
        :additionalData.sync="additionalData"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AccountDetailsCard from '@/components/cards/AccountDetailsCard'
import AddressDetailsCard from '@/components/cards/AddressDetailsCard'
import AdditionalDetailsCard from '@/components/cards/AdditionalDetailsCard'
import { execHttpRequestThrowable } from '@/services/http'
import { fetchProfile } from '@/repositories/contactRepo'
import { getUserIdentification, getUserRole } from '@/services/authService'
export default {
  components: {
    AccountDetailsCard,
    AddressDetailsCard,
    AdditionalDetailsCard
  },
  data() {
    return {
      contact: {
        id: null,
        username: null,
        password:null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        role: null,
        status: null
      },
      address: {
        companyName: null,
        country: null,
        province: null,
        city: null,
        postalCode: null,
        street: null,
        building: null
      },
      additionalData: null,
      create: true,
      currentPath: null,
      isDataFetched: false
    }
  },
  computed: {
    isPathChanged() {
      if (this.currentPath !== this.$route.path) this.setCurrentPath()
      return ''
    },
    getRole() {
      return getUserRole()
    },
    isCreating() {
      return this.create
    },
    isEditing() {
      return !this.create && this.isDataFetched
    }
  },
  methods: {
    async tryToFetchProfile(username) {
      const r = await execHttpRequestThrowable(async () => await fetchProfile(username))
      const contact = r.response.data.contact
      const address = r.response.data.address
      this.contact = {
        id: contact.id,
        username: contact.username,
        firstName: contact.firstName,
        lastName: contact.lastName,
        phone: contact.phone,
        email: contact.email,
        backupEmails: contact.backupEmails,
        role: contact.role,
        status: contact.status
      },
      address ? this.address = {
        companyName: address.companyName ? address.companyName : null,
        country: address.country ? address.country : null,
        province: address.province ? address.province : null,
        city: address.city ? address.city : null,
        postalCode: address.postalCode ? address.postalCode : null,
        street: address.street ? address.street : null,
        building: address.building ? address.building : null,
      } : this.address = null,
      this.additionalData = {
        clientForAdmin: r.response.data.clientForAdmin,
        serviceForAdmin: r.response.data.serviceForAdmin,
        serviceForClient: r.response.data.serviceForClient,
        serviceForService: r.response.data.serviceForService,
        managerForAdmin: r.response.data.managerForAdmin
      }

      this.isDataFetched = true
    },
    async setCurrentPath() {
      this.currentPath = this.$route.path

      if (this.$route.params.username) {
        this.create = false
        await this.tryToFetchProfile(this.$route.params.username)
      }
      else if (this.$route.path.includes('/profile')) {
        this.create = false
        await this.tryToFetchProfile(getUserIdentification())
      }
      else {
        this.create = true
        this.contact = {
          id: null,
          username: null,
          password:null,
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          role: null,
          status: null
        },
        this.address = {
          companyName: null,
          country: null,
          province: null,
          city: null,
          postalCode: null,
          street: null,
          building: null
        }
      }
    },
    isCurrentRole(roles) {
      return roles.includes(this.getRole)
    },
    isContactRole(roles) {
      return roles.includes(this.contact.role)
    }
  },
  created() {
    this.$feedback.fill("Process Contact Page", this.$route.path)
  }
}
</script>
<style>
.new-user-title-box {
  width: 100%;
  height: 50px;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
  background-color: #0277bd!important;
}
.new-user-title-box-error {
  width: 100%;
  height: 50px;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
  background-color: #af4040!important;
}
.card-title {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}
</style>