import { getSelectedLanguageFromUrl } from '@/services/authService'
function getDateNewYorkFormat(date) {
  const pd = getProcessedDate(date)

  const newDate = new Date(`${pd.year}-${pd.month}-${pd.day}T${pd.hour}:${pd.minutes}:${pd.seconds}Z`)
  const monthByLang = getSelectedLanguageFromUrl() === 'en' ? months[newDate.getMonth()].en : months[newDate.getMonth()].sp

  const monthName = monthByLang.substr(0, 3)
  
  const hour = newDate.getHours()
  const minutes = newDate.getMinutes()
  const seconds = newDate.getSeconds()

  const meridiem = hour > 11 ? 'PM' : 'AM'

  return String(monthName)
  .concat(' ')
  .concat(newDate.getDate())
  .concat(', ')
  .concat(newDate.getFullYear())
  .concat(', ')
  .concat(hour > 12 ? (hour - 12 < 10 ? String('0').concat(hour - 12) : hour) : (hour < 10 ? String('0').concat(hour) : hour))
  .concat(`:${minutes < 10 ? String('0').concat(minutes) : minutes}:${seconds < 10 ? String('0').concat(seconds) : seconds}`)
  .concat(' ').concat(meridiem)

  // return String(monthName).concat(' ').concat(newDate.getDate()).concat(', ').concat(newDate.getFullYear()).concat(', ').concat(newDate.toLocaleString().split(', ')[1])
}

function getTimeZoneInNumber(d) {
  const r = new RegExp(/\sGMT(?<hour>[+|-]\d{2})\d*?\s/).exec(new Date(d))

  if (r && r[1]) {
    const sign = r[1].substr(0, 1)
    return 'UTC ' + sign + parseInt(r[1].substring(1))
  }
  else console.error(`The function received non-Date type parameter - ${d}`)
}
function getTimeZoneInPureNumber(d) {
  return getTimeZoneInNumber(d).substring(4)
}

export function getTime(d) {
  const newDate = new Date(d)
  return `${newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours()}:${newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()}`
}

function getByHoursAndMinutes(h, m) {
  return new Date(`1970-01-01T${h}:${m}:00`)
}

function getProcessedDate(d) {
  const r = new RegExp(/^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})T(?<hour>\d{2}):(?<minutes>\d{2}):(?<seconds>\d{2})/).exec(d)
  return {
    year: r.groups['year'],
    month: r.groups['month'],
    day: r.groups['day'],
    hour: r.groups['hour'],
    minutes: r.groups['minutes'],
    seconds: r.groups['seconds']
  }
}

export default {
  getDateNewYorkFormat,
  getTime,
  getByHoursAndMinutes,
  getTimeZoneInNumber,
  getTimeZoneInPureNumber
}
const months = [
  {
    en: 'January',
    sp: 'Enero'
  }, {
    en: 'February',
    sp: 'Febrero'
  }, {
    en: 'March',
    sp: 'Marzo'
  }, {
    en: 'April',
    sp: 'Abril'
  }, {
    en: 'May',
    sp: 'Mayo'
  }, {
    en: 'June',
    sp: 'Junio'
  }, {
    en: 'July',
    sp: 'Julio'
  }, {
    en: 'August',
    sp: 'Agosto'
  }, {
    en: 'September',
    sp: 'Septiembre'
  }, {
    en: 'October',
    sp: 'Octubre'
  }, {
    en: 'November',
    sp: 'Noviembre'
  }, {
    en: 'December',
    sp: 'Diciembre'
  }
]