export default [{
  component: 'RemoteSupport_DetailsTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.details',
  statuses: ['INITIAL'],
  state: 'DISABLED'
}, {
  component: 'RemoteSupport_ServiceReviewTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.remoteSupport.tabs.serviceReview',
  statuses: ['SERVICE_REVIEW'],
  state: 'DISABLED'
}, {
  component: 'RemoteSupport_WaitingCompletionTab',
  label: 'pages.repair.tabs.completed',
  state: 'DISABLED',
  items: [
    {
      roles: ['ADMIN', 'SUPERVISOR', 'SERVICE'],
      statuses: ['WAITING_COMPLETION'],
    }, {
      roles: ['CLIENT', 'SUSTAINABILITY_MANAGER'],
      statuses: ['WAITING_COMPLETION', 'DONE', 'REJECTED'],
    }
  ]
}, {
  component: 'RemoteSupport_HistoryTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE'],
  label: 'pages.repair.tabs.history', 
  statuses: ['DONE', 'REJECTED'],
  state: 'ENABLED-UNCHANGABLE'
}]