<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <process-workflow
        v-model="partRequest"
        :tabs="getTabs"
        :status="getPartRequestStatus"
        :role="getRole"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ProcessWorkflow from '@/components/common/process-tabs/ProcessWorkflow'
import { fetchPartRequestById, updatePartRequestStatus } from '@/repositories/partRequestRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getObjectPartRequestStatusByKey } from '@/utils/statusesUtil'
import tabs from '@/data/process/process-part-request-tabs'
import { getUserRole } from '@/services/authService'

export default {
  provide() {
    return {
      tryToFetchData: async () => await this.tryToFetchPartRequest(),
      updateEntityStatus: async (id, status) => await updatePartRequestStatus(id, status) 
    }
  },
  components: {
    ProcessWorkflow
  },
  data() {
    return {
      partRequest: null,
      isDataFetched: false,
      repairStatusTokenNotifier: null
    }
  },
  methods: {
    async tryToFetchPartRequest() {
      this.isDataFetched = false
      const r = await execHttpRequestThrowable(async () => await fetchPartRequestById(this.$route.params.id))
      this.partRequest = r.response.data
      this.partRequest.status = getObjectPartRequestStatusByKey(this.partRequest.status)
      this.isDataFetched = true
    }
  },
  computed: {
    getTabs() {
      return tabs
    },
    getPartRequestStatus() {
      return this.isDataFetched ? this.partRequest.status : getObjectPartRequestStatusByKey('NONE')
    },
    getRole() {
      return getUserRole()
    }
  },
  async mounted() {
    await this.tryToFetchPartRequest()
  },
  created() {
    this.$feedback.fill("Process Part Request Page", this.$route.path)
  }
}
</script>
<style>

</style>