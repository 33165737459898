<template>
  <v-btn
  @click="$emit('click')"
  :width="width ? width : '100%'"
  :height="height"
  elevation="5"
  :loading="loading"
  :disabled="disabled"
  :style="`background: ${color ? color: '#ffffff'};`"
  :class="`rounded-0 ${smallText ? 'primary-btn-font-small' : 'primary-btn-font-medium'}`">
    {{label}}
    <v-badge
    v-if="badge"
      color="red"
      :content="badge"
      inline
    />
  </v-btn>
</template>
<script>
export default {
  props: {
    label: String,
    width: Number,
    height: Number,
    disabled: Boolean,
    color: String,
    smallText: Boolean,
    loading: Boolean,
    badge: {
      type: String,
      default: () => {
        return null
      }
    }
  }
}
</script>
<style>
.primary-btn-font-medium.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0px;
}
.primary-btn-font-small.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  font-weight: 400;
  font-size: 0.6rem;
  letter-spacing: 0px;
}

.primary-btn-font-medium.v-btn.v-btn--disabled{
  color: #fff;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
.primary-btn-font-small.v-btn.v-btn--disabled {
  color: #fff;
  font-weight: 400;
  font-size: 0.6rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
</style>