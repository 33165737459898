<template>
  <div
  :class="`d-flex justify-center align-center feedback-container-${(show ? 'shown' : 'hidden')}`">
    <div
    @click="onThumbClick"
    :class="`feedback-thumb ${!show ? 'feedback-thumb-scale' : ''} d-flex align-center`">
      <!-- <p class="feedback-p">
        FEEDBACK
      </p> -->
      <v-icon
      dark
      class="feedback-icon">
        mdi-face-agent
      </v-icon>
    </div>
    <div
    class="feedback-hidden-area pa-5">
      <v-form
      ref="form"
      v-model="form">
        <v-row>
          <v-col>
            <text-field-value
            v-model="title"
            :label="$t('textFields.title')"
            normal
            :max="64"
            required
            special/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-area
            v-model="content"
            :label="$t('textFields.content')"
            soloNormal
            required
            :max="640"
            special/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <primary-btn
            @click="tryToRequestFeedback"
            :label="$t('btns.send')"
            color="#0d47a1"
            :loading="isLoading"
            :disabled="!form"
            block/>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script>
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextArea from '@/components/text-fields/TextArea'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import { requestFeedback } from '@/repositories/supportRepo'
import { execHttpRequestThrowable } from '@/services/http'
export default {
  components: {
    TextFieldValue,
    TextArea,
    PrimaryBtn
  },
  props: {
    
  },
  data() {
    return {
      show: false,
      isLoading: false,
      title: '',
      content: '',
      form: false
    }
  },
  methods: {
    onThumbClick() {
      this.show = !this.show
    },
    async tryToRequestFeedback() {
      const messages = {
        _200: 'dialogs.messages.success.feedbackRequestedSuccessfully'
      }
      const data = {
        title: this.title,
        content: this.content,
        pageName: this.$feedback.pageName,
        pagePath: this.$feedback.pagePath
      }
      await execHttpRequestThrowable(async () => await requestFeedback(data), messages, v => this.isLoading = v)
      this.title = ''
      this.content = ''
      this.onThumbClick()
      this.$refs.form.reset()
    }
  }
}
</script>
<style>
.feedback-p {
  transform: rotateZ(-90deg);
  position: relative;
  right: 25px;
  top: -10px;
  letter-spacing: 5px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}
.feedback-label {
  position: relative;
  letter-spacing: 1px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
}
.feedback-icon {
  position: absolute !important; 
  bottom: 13px !important; 
  left: 15px !important;
}

.feedback-container-shown {
  position: absolute;
  right: 0px;
  bottom: 1px;

  animation-name: showing-feedback;
  animation-duration: 1s;
}
.feedback-container-hidden {
  position: absolute;
  right: -400px;
  bottom: 1px;

  animation-name: hiding-feedback;
  animation-duration: 1s;
}
@keyframes showing-feedback {
  from {
    right: -400px;
  }
  to {
    right: 0px;
  }
}
@keyframes hiding-feedback {
  from {
    right: 0px;
  }
  to {
    right: -400px;
  }
}

.feedback-hidden-area {
  position: relative;
  width: 400px;
  height: auto;
  border: 2px #0d47a175 solid;
  background: #ffffff;
}

.feedback-thumb {
  width: 50px;
  height: 50px;
  margin-top: auto;
  background: #0d47a175;
  color: white;
  position: relative;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
}

.feedback-thumb-scale {
  transform: scale(1, 1);
}
.feedback-thumb-scale:hover {
  transform: scale(1.1, 1.1);

  animation-name: on-hover-feedback;
  animation-duration: 200ms;
}
.feedback-thumb-scale:not(:hover) {
  transform: scale(1, 1);

  animation-name: on-not-hover-feedback;
  animation-duration: 200ms;
}

@keyframes on-hover-feedback {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.1, 1.1);
  }
}
@keyframes on-not-hover-feedback {
  from {
    transform: scale(1.1, 1.1);
  }
  to {
    transform: scale(1, 1);
  }
}
</style>