import axios from 'axios'
import { removeUserToken, getSelectedLanguage } from '@/services/authService'
import { i18n } from '@/plugins/vue-i18n'
import { app } from '@/main'
import { routeFromRoot } from '@/services/routeService'

export async function get(url, config) {
  return await axios.get(url, addLangHeader(config))
}
export async function post(url, body, config) {
  return await axios.post(url, body, addLangHeader(config))
}
export async function put(url, body, config) {
  return await axios.put(url, body, addLangHeader(config))
}
export async function _delete(url, config) {
  return await axios.delete(url, addLangHeader(config))
}

function addLangHeader(config) {
  if (config === null || config === undefined) {
    config = {
      headers: {}
    }
  }
  if (config.headers === null || config.headers === undefined) {
    config['headers'] = {}
  }
  config.headers['Lang'] = getSelectedLanguage()
  return config
}

class HttpRequestFailedException extends Error {
  constructor(m) {
    super(m)
    this.name = 'HttpRequestFailedException'
  }
}

export async function execHttpRequestThrowable(request, messages, onLoading) {
  const r = await execHttpRequest(request, messages, onLoading)

  if (r.isSuccessed) return r
  else throw new HttpRequestFailedException('Http Request Failed')
}

export async function execHttpRequest(request, messages, onLoading) {
  try {
    if (onLoading) onLoading(true)
    const response = await request()

    if (response.status === 200 || response.status === 202) {
      let m = ''
      if (messages && messages._200) {
        m = messages._200

        if (!messages.ignoreSuccessDialog)
          app.$successDialog.open(messages.successTitle, i18n.t(m, messages.onSuccessProps))
      }
      
      return {
        isSuccessed: true,
        message: m,
        response,
        status: response.status
      }
    }
  }
  catch (error) {
    console.error(error)

    const response = {
      isSuccessed: false,
      message: null,
      response: null,
      status: error.response.status
    }
    if (!error.response || error.response === undefined) {
      response.message = 'dialogs.messages.failed.internalErrorOccurred'
    }
    else {
      response.message = error.response.data.message
      if (error.response.status === 204) {
        response.message = getMessageByStatusIfExists(messages, '204', error.response.data.message)
      }
      else if (error.response.status > 499 && error.response.status < 600) {
        if (error.response.data.errorCode === 'MaxUploadSizeExceededException') {
          response.message = 'dialogs.messages.failed.maxUploadSizeExceeded'
        }
        else response.message = getMessageByStatusIfExists(messages, '500', 'dialogs.messages.failed.serverErrorOccurred')
      }
      else if (error.response.status === 400) {
        response.message = getMessageByStatusIfExists(messages, '400', 'dialogs.messages.failed.badRequest')
      }
      else if (error.response.status === 401) {
        if (error.response.data.messageId === 'exception.access.denied.jwt.expire' || 
        error.response.data.messageId === 'exception.access.denied.jwt.verify') {
          routeFromRoot('login')
          removeUserToken()
          response.message = getMessageByStatusIfExists(messages, '401', 'dialogs.messages.failed.noLongerLoggedInTheSystem')
        }
        else {
          response.message = getMessageByStatusIfExists(messages, '401', 'dialogs.messages.failed.accessForbidden')
        }
      }
      else if (error.response.status === 403) {
        response.message = getMessageByStatusIfExists(messages, '403', 'dialogs.messages.failed.accessForbidden')
      }
      else if (error.response.status === 404) {
        response.message = getMessageByStatusIfExists(messages, '404', 'dialogs.messages.failed.resourceNotFound')
      }
      else if (error.response.status === 405) {
        response.message = getMessageByStatusIfExists(messages, '405', 'dialogs.messages.failed.methodNotAllowed')
      }
      else if (error.response.status === 409) {
        response.message = getMessageByStatusIfExists(messages, '409', '')
      }
    }

    app.$failedDialog.open(messages?.failedTitle, response.message)
    return response
  }
  finally {
    if (onLoading) onLoading(false)
  }
}

function getMessageByStatusIfExists(messages, status, _default) {
  if (messages && messages[`_${status}`]) return messages[`_${status}`]
  else return _default
}