<template>
  <v-dialog
  v-model="$changesDialog.model"
  width="700"
  @click:outside="closeDialog">
    <v-card
    color="white"
    width="100%">
      <div class="changes-dialog-header changes-dialog-header-title d-flex justify-space-between align-center px-5">
        <span>{{$t($changesDialog.title)}}</span>
        <v-btn
        icon
        dark
        @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-row dense no-gutters class="px-5 pt-5">
        <v-col>
          <main-data-table
          :headers="getHeaders"
          :items="getItems"
          :page="page"
          :size="size"
          :itemsPerPageOptions="itemsPerPageOptions"
          hide-default-footer
          :height="300">
            <template v-if="isRow" v-slot:[`header.number`]="{ header }">
                {{$t(header.text)}} 
            </template>
            <template v-slot:[`header.field`]="{ header }">
                {{$t(header.text)}} 
            </template>
            <template v-slot:[`header.newValue`]="{ header }">
                {{$t(header.text)}} 
            </template>

            <template v-if="isRow" v-slot:[`item.number`]="{ item }">
                <span>
                  {{item.number}}
                </span>  
            </template>
            <template v-slot:[`item.field`]="{ item }">
                <span>
                  {{$t(getField(item))}}
                </span>
                <!-- <span v-if="!isImage(item.oldValue)">
                  {{$t(item.oldValue)}}
                </span>  
                 <div v-else class="d-flex justify-center px-4 my-4">
                  <img
                  :src="item.oldValue"
                  class="img-thumbnail"
                  />
                </div> -->
            </template>
            <template v-slot:[`item.newValue`]="{ item }">
                <span v-if="!isImage(item.newValue)">
                  {{$t(item.newValue)}}
                </span>
                <div v-else class="d-flex justify-center px-4 my-4">
                  <img
                  :src="item.newValue"
                  class="img-thumbnail"
                  />
                </div>
            </template>
          </main-data-table>
        </v-col>
      </v-row>
      <div 
      class="mt-5"
      style="width: 100%; height: 1px; background-color: rgba(0,0,0,.2);"/>
      <div class="d-flex justify-center align-center pa-4">
        <outlined-btn
        :label="$t('btns.close')"
        color="#33b5e5"
        class="mx-2"
        :loading="isLoading"
        :width="100"
        :height="45"
        @click="closeDialog"/>
        <primary-btn
        :label="$t('btns.applyChanges')"
        color="#33b5e5"
        class="mx-2"
        :loading="isLoading"
        :width="150"
        :height="45"
        :disabled="getItems.length === 0"
        @click="execAction"/>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import OutlinedBtn from '@/components/btns/OutlinedBtn'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import MainDataTable from '@/components/common/MainDataTable'
export default {
  components: {
    OutlinedBtn,
    PrimaryBtn,
    MainDataTable
  },
  computed: {
    getHeaders() {
      const headers = []
      if (this.isRow) {
        headers.push({
          text: 'headers.number',
          align: 'start',
          sortable: true,
          value: 'number'
        })
      }
      headers.push({
        text: 'headers.field',
        align: 'start',
        sortable: true,
        value: 'field'
      })
      headers.push({
        text: 'headers.newValue',
        align: 'start',
        sortable: true,
        value: 'newValue'
      })

      return headers
    },
    getItems() {
      return this.$changesDialog.items
    },
    isRow() {
      return this.$changesDialog.isRow
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.row',
          align: 'start',
          sortable: true,
          value: 'number'
        },{
          text: 'headers.field',
          align: 'start',
          sortable: true,
          value: 'field'
        },{
          text: 'headers.newValue',
          align: 'start',
          sortable: true,
          value: 'newValue'
        }
      ],
      size: -1,
      page: 1,
      itemsPerPageOptions: [-1],
      isLoading: false
    }
  },
  methods: {
    async execAction() {
      if (this.$changesDialog.action) {
        try {
          this.isLoading = true
          await this.$changesDialog.action()
          this.closeDialog()
        }
        finally {
          this.isLoading = false
        }
      }
      else console.error(this.$t('An action is not provided!', {'0': '(Changes Dialog)'}))
    },
    isImage(v) {
      return new RegExp(/^blob:http:\/\/(.*\/?)+?$/).test(v)
    },
    closeDialog() {
      this.$changesDialog.close()
    },
    getField({field}) {
      return typeof(field) === 'object' ? this.$t(field.value, field.params) : field
    }
  },
  beforeDestroy() {
    this.closeDialog()
  }
}
</script>
<style>
.changes-dialog-header {
  width: 100%;
  height: 70px;
  background-color: #33b5e5;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.144);
  border-radius: 0px!important;
}
.changes-dialog-header-title {
  font-family: 'Titillium Web', sans-serif;
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.changes-dialog-content {
  font-family: 'Titillium Web', sans-serif;
  color: black;
  font-weight: 300;
  font-size: 1.0rem;
  letter-spacing: 1px;
}
.changes-dialog-differences-plot {
  width: 100%;
  height: 300px;
}
.changes-dialog-data-table .v-data-table-header {
  background-color: #cfd8dc!important;
}
</style>