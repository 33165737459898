<template>
  <div>
    <image-preview-dialog v-if="$imagePreviewDialog.model"/>

    <navigator-bar/>
    <router-view/>
    <feedback-area/>
  </div>
</template>
<script>
import ImagePreviewDialog from '@/components/dialogs/ImagePreviewDialog'
import NavigatorBar from '@/components/navigation/NavigatorBar'
import FeedbackArea from '@/components/common/FeedbackArea'
export default {
  components: {
    FeedbackArea,
    NavigatorBar,
    ImagePreviewDialog
  }
}
</script>
<style>

</style>