var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"main-data-table secondaryBtn--text",attrs:{"headers":_vm.getHeaders,"items":_vm.items,"page":_vm.pageProp,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions },"items-per-page":_vm.sizeProp,"server-items-length":_vm.totalElements,"hide-default-footer":_vm.hideDefaultFooter,"disable-sort":"","loading":_vm.loading,"item-key":_vm.itemKey,"height":_vm.height,"expanded":_vm.expanded},on:{"update:page":function($event){_vm.pageProp=$event},"update:items-per-page":_vm.updateSize,"current-items":_vm.setCurrentItemsLabel,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('p',{staticClass:"main-data-table-header"},[_vm._v(_vm._s(_vm.$t('textFields.noData')))])])]},proxy:true},_vm._l((_vm.getHeaders),function(h,i){return {key:("header." + (h.value)),fn:function(ref){return [_c('div',{key:i,staticClass:"main-data-table-header"},[_vm._t(("header." + (h.value)),function(){return [_vm._v(" "+_vm._s(_vm.$t(h.text))+" ")]},{"header":h})],2)]}}}),(_vm.numberPages)?{key:"header.rowNumber",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}}:null,{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('tr',[(_vm.numberPages)?_c('td',{class:("main-data-table-row " + ((index % 2) === 0 ? 'main-data-table-row-color-fill' : ''))},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"position":"relative"}},[_c('span',[_vm._v(" "+_vm._s((_vm.sizeProp * (_vm.pageProp - 1)) + index + 1)+" ")])])]):_vm._e(),(_vm.markItems)?_c('td',{class:("main-data-table-row " + ((index % 2) === 0 ? 'main-data-table-row-color-fill' : ''))},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{attrs:{"disabled":_vm.disabled},model:{value:(item.mark),callback:function ($$v) {_vm.$set(item, "mark", $$v)},expression:"item.mark"}})],1)]):_vm._e(),(_vm.setImage)?_c('td',{class:("main-data-table-row " + ((index % 2) === 0 ? 'main-data-table-row-color-fill' : ''))},[_c('div',{staticClass:"d-flex justify-center"},[_c('previewable-image',{staticClass:"py-2",attrs:{"height":"65","width":"50","contain":""},model:{value:(item.imageUrl),callback:function ($$v) {_vm.$set(item, "imageUrl", $$v)},expression:"item.imageUrl"}})],1)]):_vm._e(),_vm._l((_vm.innerHeaders ? _vm.getHeadersForExtended : _vm.headers),function(h,i){return _c('td',{key:i,class:("main-data-table-row " + ((index % 2) === 0 ? 'main-data-table-row-color-fill' : ''))},[_vm._t(("item." + (h.value)),function(){return [_vm._v(" "+_vm._s(item[h.value])+" ")]},{"item":item,"index":index})],2)}),(_vm.innerHeaders)?_c('td',{class:("main-data-table-row " + ((index % 2) === 0 ? 'main-data-table-row-color-fill' : ''))},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeExpandState(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)],1)]):_vm._e()],2)]}},(_vm.innerHeaders)?{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"background-color":"rgba(0, 0, 0, 0.01)","position":"relative","width":"100%"},attrs:{"colspan":_vm.getHeaders.length}},[_c('main-data-table',{staticClass:"pa-4",staticStyle:{"width":"100%"},attrs:{"headers":_vm.innerHeaders,"items":item.parts,"hide-default-footer":"","itemsPerPageOptions":[-1],"item-key":_vm.innerItemKey},scopedSlots:_vm._u([_vm._l((_vm.innerHeaders),function(h,i){return {key:("header." + (h.value)),fn:function(ref){return [_c('div',{key:i,staticClass:"main-data-table-header"},[_vm._t(("inner-header." + (h.value)),null,{"header":h})],2)]}}}),_vm._l((_vm.innerHeaders),function(h,i){return {key:("item." + (h.value)),fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{key:i},[_vm._t(("inner-item." + (h.value)),null,{"item":item,"index":index})],2)]}}})],null,true)})],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }