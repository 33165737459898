<template>
  <v-container fluid class="pa-4 pt-8">
    <v-row 
    dense
    no-gutters
    justify="space-between">
      <v-col 
      class="d-flex align-end"
      cols="1">
        <p class="text-input-label">
          {{$t('other.show')}}
        </p>
        <v-select
          v-model="size"
          :items="itemsPerPageOptions"
          style="width: 78px;"
          class="text-input text-input-border-color mb-3 mx-2 select-medium"
          hide-details="auto"
          solo
          flat
          dense
        ></v-select>
        <p class="text-input-label">
          {{$t('other.entries')}}
        </p>
      </v-col>
      <v-col 
      cols="1">
        <div style="position: relative; top: -12px;">
          <label class="text-input-label">
            {{$t('textFields.searchLabel')}}
          </label>
          <v-text-field
          class="text-input text-input-border-color select-medium"
          hide-details="auto"
          v-model="keyword"
          @keypress.enter="searchParts"
          solo
          flat
          dense
          />
        </div>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        number-pages
        :headers="headers"
        :items="items"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :onUpdateSize="tryToFetchPartsByCriteria"
        :total-elements="totalElements"
        hide-default-footer>
          <template v-slot:[`item.imageUrl`]="{ item }">
            <previewable-image
            v-model="item.imageUrl"
            height="50"
            width="100"
            contain
            class="py-2"/>
          </template>
          <template v-slot:[`item.serviceCategories`]="{ item }">
            <div
            class="d-flex justify-center align-center">
              <text-field-autocomplete-chips
              v-model="item.serviceCategories"
              :label="$t('textFields.serviceCategories')"
              :items="allServiceCategories"
              item-value="id"
              item-text="key"
              />

              <primary-btn
              :label="$t('btns.save')"
              color="#4285f4"
              class="ml-4"
              @click="tryToUpdateServiceCategoriesByPartId(item)"
              :loading="isLoading"
              :width="40"
              :height="30"/>
            </div>
          </template>

          <template v-slot:[`item.action`]="{ item }">
              <div
              class="d-flex justify-center align-center">
                <v-btn @click="routeTo(`edit/part/${item.id}`)" class="detail-btn rounded-0" width="70" height="30">
                  {{$t('btns.edit')}}
                </v-btn>
              </div>
          </template>
           <template v-slot:[`item.update-state`]="{ item }">
              <div
              class="d-flex justify-center align-center">
                <text-field-switch
                v-model="item.enabled"
                :loading="isLoading"
                @change="tryToUpdatePartState(item)"/>
              </div>
          </template>
        </main-data-table>
      </v-col>
    </v-row>
    <v-row 
    dense
    no-gutters
    class="mt-5"
    justify="space-between">
      <v-col 
      class="d-flex align-end"
      cols="3">
        <p class="text-input-label">
          {{$t('other.showing')}} {{((page - 1) * size) + 1}} {{$t('other.to')}} {{((page - 1) * size) + size > totalElements ? totalElements : ((page - 1) * size) + size}} {{$t('other.of')}} {{totalElements}} {{$t('other.entries')}}
        </p>
      </v-col>
      <v-col 
      class="d-flex justify-end"
      cols="6">
        <v-pagination
          ref="pag"
          v-model="page"
          @input="tryToFetchPartsByCriteria"
          :length="pageLength"
          :disabled="isLoading"
          color="#4285f4"
        ></v-pagination>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4 mt-4"
    name="export.title.parts"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import { routeFromRoot } from '@/services/routeService'
import { fetchPartsByCriteria, updatePartState } from '@/repositories/partRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { exportPartsXlsx } from '@/repositories/exportRepo'
import { fetchServiceCategoriesByKeys, updateServiceCategoriesByPartId } from '@/repositories/serviceCategoryRepo'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import MainDataTable from '@/components/common/MainDataTable'
import TextFieldSwitch from '@/components/text-fields/TextFieldSwitch'
import PreviewableImage from '@/components/common/PreviewableImage'
import TextFieldAutocompleteChips from '@/components/text-fields/TextFieldAutocompleteChips'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
export default {
  components: {
    MainDataTable,
    TextFieldSwitch,
    PreviewableImage,
    ExportXlsxRow,
    TextFieldAutocompleteChips,
    PrimaryBtn
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.image',
          align: 'start',
          sortable: true,
          value: 'imageUrl'
        },{
          text: 'headers.itemCategory',
          align: 'start',
          sortable: true,
          value: 'category'
        },{
          text: 'headers.partNumber',
          align: 'start',
          sortable: true,
          value: 'number'
        },{
          text: 'headers.model',
          align: 'start',
          sortable: true,
          value: 'model'
        }, {
          text: 'headers.manufacturer',
          align: 'start',
          sortable: true,
          value: 'manufacturer'
        }, {
          text: 'headers.description',
          align: 'start',
          sortable: true,
          value: 'description'
        }, {
          text: 'headers.serviceCategories',
          align: 'start',
          sortable: true,
          value: 'serviceCategories'
        }, {
          text: 'headers.action',
          align: 'start',
          sortable: true,
          value: 'action'
        }, {
          text: 'headers.enabledDisabled',
          align: 'start',
          sortable: true,
          value: 'update-state'
        }
      ],
      items: [],
      totalElements: 0,
      page: 1,
      pageLength: 0,
      size: 10,
      itemsPerPageOptions: [5, 10, 15, 20],
      isLoading: false,
      keyword: '',
      allServiceCategories: []
    }
  },
  computed: {
    getExporter() {
      return async () => await exportPartsXlsx(this.keyword)
    }
  },
  methods: {
    async tryToFetchPartsByCriteria() {
      const r = await execHttpRequestThrowable(async () => await fetchPartsByCriteria(this.keyword, this.size, this.page - 1),
      {},
      v => this.isLoading = v)
      const p = r.response.data
      this.totalElements = p.totalElements
      this.items = [...p.content]

      this.calcPageLength()
    },
    async searchParts() {
      this.page = 1
      await this.tryToFetchPartsByCriteria()
    },
    async tryToFetchServiceCategoriesByKeys() {
      const r = await execHttpRequestThrowable(async () => await fetchServiceCategoriesByKeys(['REPAIR', 'PARTS']))
      this.allServiceCategories = r.response.data
    },
    async tryToUpdateServiceCategoriesByPartId({id, serviceCategories}) {
      await execHttpRequestThrowable(async () => await updateServiceCategoriesByPartId(id, serviceCategories), {}, v => this.isLoading = v)
      this.$snackbar.success('snackbar.success.serviceCategoriesSavedSuccessfully')
    },
    calcPageLength() {
      this.pageLength = Math.ceil(this.totalElements / this.size)
    },
    routeTo(link) {
      routeFromRoot(link)
    },
    async tryToUpdatePartState({id, enabled}) {
      await execHttpRequestThrowable(async () => await updatePartState(id, enabled), {}, v => this.isLoading = v)
    }
  },
  async mounted() {
    await this.tryToFetchServiceCategoriesByKeys()
    await this.tryToFetchPartsByCriteria()
  },
  created() {
    this.$feedback.fill("Part List Page", this.$route.path)
  }
}
</script>
<style>
div.select-medium > div.v-input__control {
  min-height: 30px !important;
}

/* 
button.v-pagination__navigation {
  width: 80px;
}
*/
</style>