<template>
  <v-tooltip 
  bottom
  :disabled="!tip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        @click="$emit('click')"
        :width="width"
        :height="height"
        elevation="5"
        outlined
        :loading="loading"
        :style="`background: ${innerColor ? innerColor: '#ffffff'}; border: 2px solid ${color ? color : '#4285f4'}; color: ${color ? color : '#4285f4'};`"
        :disabled="disabled"
        :class="`outlined-extended-btn rounded-0 outlined-extended-btn-font-medium py-6 ${innerClass}`"
        v-bind="attrs"
        v-on="on">
          <div class="d-flex">
            <v-icon 
            v-if="icon && icon.alignment === 'left'"
            :size="icon.size">
              {{icon.value}}
            </v-icon>
            <span class="mx-2">
              {{label}}
            </span>
            <v-icon 
            v-if="icon && icon.alignment === 'right'"
            :size="icon.size">
              {{icon.value}}
            </v-icon>
          </div>
          <div class="d-flex justify-center">
            <span style="font-size: 15px; text-transform: none;">
              {{subLabel}}
            </span>
          </div>
        </v-btn>
      </template>
      <span
      v-if="tip">
        {{$t(tip)}}
      </span>
    </v-tooltip>
</template>
<script>
export default {
  props: {
    label: String,
    width: Number,
    height: Number,
    disabled: Boolean,
    color: String,
    innerColor: String,
    loading: Boolean,
    icon: {
      type: Object,
      default: () => {
        return null
      }
    },
    subLabel: String,
    tip: {
      type: String,
      default: () => {
        return null
      }
    },
    innerClass: String
  }
}
</script>
<style>
.outlined-extended-btn-font-medium.v-btn {
  font-weight: 400;
  font-size: 1.1rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
.outlined-extended-btn-font-small.v-btn {
  font-weight: 400;
  font-size: 0.6rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
.outlined-extended-btn-font-small.v-btn {
  font-weight: 400;
  font-size: 0.6rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}

.outlined-extended-btn .v-btn__content {
  display: table;
}

.outlined-extended-btn-font-medium.v-btn.v-btn--disabled{
  font-weight: 400;
  font-size: 1.1rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
.outlined-extended-btn-font-small.v-btn.v-btn--disabled {
  font-weight: 400;
  font-size: 0.6rem;
  letter-spacing: 0px;
  font-family: 'Titillium Web', sans-serif;
}
</style>