<template>
  <v-row 
  justify="space-between"
  align="center"
  >
    <v-col
    :cols="removeBtn ? 0 : 3">
      <primary-btn
      v-if="!removeBtn"
      @click="openFileWindow"
      :label="$t('btns.chooseFile')"
      color="#4285f4"
      :width="120"
      :height="35"
      />
      <input type="file" class="d-none" :id="id">
    </v-col>
    <v-col
    :cols="removeBtn ? 12 : 8">
      <v-text-field
      v-model="filesValue"
      :rules="rules"
      @click="removeBtn ? openFileWindow() : ''"
      readonly
      class="sts-web-system-label"
      :label="label"/>
    </v-col>
  </v-row>
</template>
<script>
import PrimaryBtn from '@/components/btns/PrimaryBtn'
export default {
  components: {
    PrimaryBtn
  },
  props: {
    id: String,
    icon: Boolean,
    required: Boolean,
    label: String,
    removeBtn: Boolean
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true
      ],
      fileInput: null,
      filesValue: '',
      files: []
    }
  },
  methods: {
    openFileWindow() {
      this.fileInput.click()
    },
    setFilesValue() {
      this.filesValue = ''
      for (const f of this.files) {
        this.filesValue = this.filesValue.concat(f.name)
      }
    },
    getFiles() {
      return this.files
    }
  },
  mounted() {
    this.fileInput = document.getElementById(this.id)

    this.fileInput.addEventListener('change', (e) => {
      if (e.target.files.length === 0) return
      this.files = []
      for (const f of e.target.files) this.files.push(f)
      this.setFilesValue()

      this.$emit('input', this.files)
    })
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>