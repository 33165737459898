export default [{
  component: 'Repair_DetailsTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.details',
  statuses: ['NEW'],
  state: 'DISABLED'
}, {
  component: 'Repair_ShippingDetailsTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.shippingDetails',
  statuses: ['IN_SHIPPING_DETAILS'],
  state: 'DISABLED'
}, {
  component: 'Repair_WaitingForPickUpTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.waitingForPickUp',
  statuses: ['SETTING_TRACKING_NUMBER', 'IN_WAITING_FOR_PICK_UP', 'IN_PICK_UP_ARRANGED'],
  state: 'DISABLED'
}, {
  component: 'Repair_InTransitTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.inTransit',
  statuses: ['IN_TRANSIT'],
  state: 'DISABLED'
}, {
  component: 'Repair_WaitingDiagnosticsTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.waitingDiagnostics',
  statuses: ['IN_WAITING_DIAGNOSTICS'],
  state: 'DISABLED'
}, {
  component: 'Repair_DiagnosticsTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.diagnostics',
  statuses: ['IN_DIAGNOSTICS'],
  state: 'DISABLED'
}, {
  component: 'Repair_RepairApprovalTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.repairApproval',
  statuses: ['IN_REPAIR_APPROVAL'],
  state: 'DISABLED'
}, {
  component: 'Repair_RepairTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.repair',
  statuses: ['IN_SERVICE'],
  state: 'DISABLED'
}, {
  component: 'Repair_TransitBackTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
  label: 'pages.repair.tabs.transitBack',
  statuses: ['IN_TRANSIT_BACK'],
  state: 'DISABLED'
}, {
  component: 'Repair_CompletedTab',
  label: 'pages.repair.tabs.completed',
  state: 'DISABLED',
  items: [
    {
      roles: ['ADMIN', 'SUPERVISOR', 'SERVICE'],
      statuses: ['IN_WAITING_COMPLETED'],
    }, {
      roles: ['CLIENT', 'SUSTAINABILITY_MANAGER'],
      statuses: ['IN_WAITING_COMPLETED', 'COMPLETED', 'REJECTED'],
    }
  ]
}, {
  component: 'Repair_HistoryTab',
  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE'],
  label: 'pages.repair.tabs.history',
  statuses: ['COMPLETED', 'REJECTED'],
  state: 'ENABLED-UNCHANGABLE'
}]