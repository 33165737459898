<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-col>
        <report-filter @filter="tryToFilterRepairs"/>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="mt-5">
      <v-col>
        <main-data-table
        number-pages
        :headers="headers"
        :items="items"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :onUpdateSize="tryToFetchRepairs"
        :onPerviousBtnClick="tryToFetchRepairs"
        :onNextBtnClick="tryToFetchRepairs"
        :total-elements="totalElements"
        class="pa-4">
          <template v-slot:[`header.createdAt`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.updatedAt`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>

          <template v-slot:[`item.number`]="{ item }">
              <span>
                {{item.number}}
              </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
              <span>
                {{$t(getStatus(item.status))}}
              </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.createdAt)}}
              </span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.updatedAt)}}
              </span>
          </template>
          <template v-slot:[`item.clientUsername`]="{ item }">
              <span>
                {{item.clientUsername}}
              </span>
          </template>
          <template v-slot:[`item.serviceUsername`]="{ item }">
              <span>
                {{item.serviceUsername}}
              </span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <route-btn
            :href="`process-repair/${item.id}`"
            target="blank"
            label="btns.viewDetails"
            :width="120"
            :height="30"/>
          </template>
        </main-data-table>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col class="d-flex justify-end align-center pr-4">
        <primary-btn 
        @click="tryToExportGeneralReportXlsx"
        :disabled="items.length === 0"
        :loading="isLoading"
        :width="150"
        :height="50"
        color="#4285f4"
        :label="$t('btns.exportXLSX')"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getRepairStatusByKey } from '@/utils/statusesUtil'
import { fetchRepairsByFilter } from '@/repositories/repairRepo'
import { exportGeneralReportXlsx } from '@/repositories/exportRepo'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import ReportFilter from '@/components/report/ReportFilter'
import DateUtil from '@/utils/DateUtil'
import { execHttpRequestThrowable } from '@/services/http'
import { saveAs } from 'file-saver'
import MainDataTable from '@/components/common/MainDataTable'
import RouteBtn from '@/components/btns/RouteBtn'
export default {
  components: {
    PrimaryBtn,
    ReportFilter,
    MainDataTable,
    RouteBtn
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.requestId',
          align: 'start',
          sortable: true,
          value: 'number'
        },{
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        }, {
          text: 'headers.creationDateTime',
          align: 'start',
          sortable: true,
          value: 'createdAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.lastChangeDateTime',
          align: 'start',
          sortable: true,
          value: 'updatedAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.client',
          align: 'start',
          sortable: true,
          value: 'clientUsername'
        }, {
          text: 'headers.service',
          align: 'start',
          sortable: true,
          value: 'serviceUsername'
        }, {
          text: 'headers.details',
          align: 'start',
          sortable: true,
          value: 'details'
        }
      ],
      items: [],
      totalElements: 0,
      size: 5,
      page: 1,
      filterData: null,
      isLoading: false
    }
  },
  methods: {
    async tryToFilterRepairs(f) {
      this.filterData = f
      this.page = 1
      await this.tryToFetchRepairs()
    },
    async tryToFetchRepairs() {
      const data = {
        clientId: this.filterData.client ? this.filterData.client.id : null,
        serviceId: this.filterData.service ? this.filterData.service.id : null,
        partId: this.filterData.part ? this.filterData.part.id : null,
        categoryId: this.filterData.category ? this.filterData.category.id : null,
        status: this.filterData.status ? this.filterData.status.key : null,
        requestNumber: this.filterData.requestNumber,
        serialNumber: this.filterData.serialNumber,
        serviceTag: this.filterData.serviceTag,
        createdFrom: this.filterData.createdFrom ? new Date(this.filterData.createdFrom).toISOString().replaceAll('.000Z', '') : null,
        createdTo: this.filterData.createdTo ? new Date(this.filterData.createdTo).toISOString().replaceAll('.000Z', '') : null
      }
      const r = await execHttpRequestThrowable(async () => await fetchRepairsByFilter(data, this.size, this.page - 1),
      {},
      v => this.isLoading = v)
      const p = r.response.data
      this.totalElements = p.totalElements
      this.items = [...p.content]
    },
    async tryToExportGeneralReportXlsx() {
      const data = {
        clientId: this.filterData.client ? this.filterData.client.id : null,
        serviceId: this.filterData.service ? this.filterData.service.id : null,
        partId: this.filterData.part ? this.filterData.part.id : null,
        categoryId: this.filterData.category ? this.filterData.category.id : null,
        status: this.filterData.status ? this.filterData.status.key : null,
        requestNumber: this.filterData.requestNumber,
        serialNumber: this.filterData.serialNumber,
        serviceTag: this.filterData.serviceTag,
        createdFrom: this.filterData.createdFrom ? new Date(this.filterData.createdFrom).toISOString().replaceAll('.000Z', '') : null,
        createdTo: this.filterData.createdTo ? new Date(this.filterData.createdTo).toISOString().replaceAll('.000Z', '') : null
      }
      const r = await execHttpRequestThrowable(async () => await exportGeneralReportXlsx(data), {}, v => this.isLoading = v)
      saveAs(r.response.data, `${this.$t('export.title.generalReport')}.xlsx`)
    },
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    getStatus(k) {
      return getRepairStatusByKey(k)
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  created() {
    this.$feedback.fill("General Report Page", this.$route.path)
  }
}
</script>
<style>
.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
</style>