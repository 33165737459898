<template>
  <v-container 
  v-if="isDataReady"
  fluid>
    <div 
    v-if="$parent.isRepairStatus(['IN_WAITING_FOR_PICK_UP'])"
    class="d-flex justify-start align-center pb-10">
      <h4>
        {{$t('pages.repair.waitingForPickUpDescription')}}
      </h4>
    </div>
    <v-row dense>
        <v-col>
          <p class="process-repair-tab-section-title">{{$t('pages.repair.pickUpDetails')}}</p>
        </v-col>
    </v-row>
    <v-form
    ref="form"
    v-model="isFormValid">
      <v-row dense>
        <v-col class="mx-2">
          <text-field-value 
          :label="$t('textFields.courierName')"
          :disabled="!$parent.isRepairStatus(['IN_WAITING_FOR_PICK_UP']) || disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          v-model="newData.data.courierName" 
          required/>
        </v-col>
        <v-col class="mx-2">
          <text-field-date 
          :label="$t('textFields.pickUpDate')"
          :disabled="!$parent.isRepairStatus(['IN_WAITING_FOR_PICK_UP']) || disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          v-model="newData.data.pickUpDate" 
          :max="newData.data.estimateDeliveryDate"
          required/>
        </v-col>
        <v-col class="mx-2">
          <text-field-date 
          :label="$t('textFields.estimateDeliveryDate')"
          :disabled="!$parent.isRepairStatus(['IN_WAITING_FOR_PICK_UP']) || disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          v-model="newData.data.estimateDeliveryDate"
          :min="newData.data.pickUpDate"
          required/>
        </v-col>
        <v-col class="mx-2">
          <text-field-value 
          :label="$t('textFields.trackingNumber')"
          :disabled="!$parent.isRepairStatus(['IN_WAITING_FOR_PICK_UP']) || disabled || $parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])"
          v-model="newData.data.trackingNumber"/>
        </v-col>
      </v-row>
      <v-row
      v-if="$parent.isRole(['SERVICE'])">
        <v-col
        cols="3"
        class="mx-2">
          <text-area
          no-resize
          label="textFields.note"
          :max="1024"
          :disabled="!$parent.isRepairStatus(['IN_WAITING_FOR_PICK_UP']) || disabled"
          v-model="newData.data.note"/>
        </v-col>
      </v-row>
    </v-form>
    <v-row 
    dense 
    class="my-10">
      <v-col>
        <p class="text-center"
        style="font-weight: bold">
          {{$t('common.pressContinueWhenReady')}}
        </p>
        <process-repair-quick-btns 
        v-if="$parent.isRole(['ADMIN', 'SERVICE']) && $parent.isRepairStatus(['IN_WAITING_FOR_PICK_UP'])" 
        :loading.sync="isLoading"
        on-success-repair-status-message="dialogs.messages.success.toTransportArranged"
        :config="getQuickBtnsConfigOnInWaitingForPickUp"/>
        <process-repair-quick-btns 
        v-else-if="$parent.isRole(['ADMIN', 'SERVICE', 'CLIENT']) && $parent.isRepairStatus(['IN_PICK_UP_ARRANGED'])" 
        :loading.sync="isLoading"
        on-success-repair-status-message="dialogs.messages.success.toTransit"
        :config="getQuickBtnsConfigOnInPickUpArranged"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import TextFieldDate from '@/components/text-fields/TextFieldDate'
import TextArea from '@/components/text-fields/TextArea'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import { getUserRole } from '@/services/authService'
import { updatePickUpShippingData } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import StringUtil from '@/utils/StringUtil'
import { copyObj, cloneObj, compareObjects } from '@/utils/StringUtil'
export default {
  name: 'WaitingForPickUpTabRepair',
  components: {
    TextFieldValue,
    TextFieldDate,
    ProcessRepairQuickBtns,
    TextArea
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      isFormValid: false,
      isLoading: false,
      newData: null,
      isDataReady: false
    }
  },
  methods: {
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.transportArrangingDateSavedSuccessfully'
      }

      const r = await execHttpRequestThrowable(async () => await updatePickUpShippingData(this.$route.params.id, this.newData.data),
      messages,
      v => this.isLoading = v)
      cloneObj(this.newData.data, this.repairProp.pickUpShippingDetails)
      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItems() {
      const oldObj = this.repairProp.pickUpShippingDetails
      const newObj = this.newData.data
      const props = [{
        field: 'textFields.courierName',
        prop: 'courierName'
      }, {
        field: 'textFields.pickUpDate',
        prop: 'pickUpDate'
      }, {
        field: 'textFields.estimateDeliveryDate',
        prop: 'estimateDeliveryDate'
      }, {
        field: 'textFields.trackingNumber',
        prop: 'trackingNumber'
      }, {
        field: 'textFields.note',
        prop: 'note'
      }]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(oldObj, newObj, props),
        this.tryToSaveData,
        false
      )
    }
  },
  computed: {
    getRole() {
      return getUserRole()
    },
    repairProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfigOnInWaitingForPickUp() {
      return [
        {
          name: 'Save',
          events: {
            'click': this.setChangedDialogItems
          },
          props: {
            'disabled': this.disabled || !this.isFormValid
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    },
    getQuickBtnsConfigOnInPickUpArranged() {
      return [
        {
          name: 'PackagePickedUp',
          props: {
            'disabled': this.disabled || !this.$parent.isRepairStatus(['IN_PICK_UP_ARRANGED'])
          },
          events: 'AsContinue'
        }
      ]
    }
  },
  mounted() {
    const data = this.repairProp.pickUpShippingDetails
    data.pickUpDate = data.pickUpDate ? data.pickUpDate.replaceAll(/T.*$/g, '') : ''
    data.estimateDeliveryDate = data.estimateDeliveryDate ? data.estimateDeliveryDate.replaceAll(/T.*$/g, '') : ''
    
    this.newData = {
      isAllowedToContinue: this.repairProp.isAllowedToContinue,
      data: copyObj(data)
    }

    if (this.$parent.isRole(['SUPERVISOR', 'CLIENT'])) {
      StringUtil.ifEmpty(this.newData.data.courierName, () => this.newData.data.courierName = this.$t('textFields.notSetYet'))
      StringUtil.ifEmpty(this.newData.data.pickUpDate, () => this.newData.data.pickUpDate = this.$t('textFields.notSetYet'))
      StringUtil.ifEmpty(this.newData.data.estimateDeliveryDate, () => this.newData.data.estimateDeliveryDate = this.$t('textFields.notSetYet'))
      StringUtil.ifEmpty(this.newData.data.trackingNumber, () =>  this.newData.data.trackingNumber = this.$t('textFields.notSetYet'))
    }

    this.isDataReady = true
  }
}
</script>
<style>

</style>