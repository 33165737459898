<template>
  <v-container fluid>
    <v-row dense>
        <v-col>
          <p class="process-repair-tab-section-title">{{$t('pages.repair.startDiagnosticDate')}}</p>
          <p class="text-start"
          style="font-weight: bold">
            {{$t('common.pressContinueWhenReady')}}
          </p>
        </v-col>
    </v-row>
    <v-row
    v-if="$parent.isRole(['ADMIN', 'SERVICE'])"
    dense>
      <v-col cols="3">
        <text-field-date 
        :label="$t('textFields.diagnosticDate')"
        v-model="newData.diagnosticStartDate" 
        :disabled="disabled"/>
      </v-col>
      <v-col cols="4">
        <process-repair-quick-btns
        :loading.sync="isLoading"
        on-success-repair-status-message="dialogs.messages.success.toDiagnostics"
        :config="getQuickBtnsConfig"/>
      </v-col>
    </v-row>
    <v-row v-else-if="$parent.isRole(['SUPERVISOR', 'CLIENT', 'SUSTAINABILITY_MANAGER'])" dense>
      <v-col>
        <v-card 
        class="pa-10"
        width="100%"
        elevation="3">
          <p 
          class="in-transit-tab-header">
            {{$t('pages.repair.estimatedDiagnosticsDateBelow')}}
          </p>

          <text-field-value 
          :label="$t('textFields.diagnosticStartDate')"
          v-model="newData.diagnosticStartDate" 
          disabled/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextFieldDate from '@/components/text-fields/TextFieldDate'
import TextFieldValue from '@/components/text-fields/TextFieldValue'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import { updateDiagnosticStartDate } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import StringUtil from '@/utils/StringUtil'
import { compareObjects } from '@/utils/StringUtil'
export default {
  components: {
    TextFieldDate,
    TextFieldValue,
    ProcessRepairQuickBtns
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      isLoading: false,
      newData: {
        diagnosticStartDate: null
      }
    }
  },
  methods: {
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.diagnosticDateSavedSuccessfully'
      }
      const r = await execHttpRequestThrowable(async () => await updateDiagnosticStartDate(this.$route.params.id, this.newData.diagnosticStartDate),
      messages,
      v => this.isLoading = v)
      this.repairProp.diagnosticStartDate = String(this.newData.diagnosticStartDate)
      this.newData.isAllowedToContinue = r.response.data
    },
    setChangedDialogItems() {
      const old = {
        diagnosticStartDate: this.repairProp.diagnosticStartDate
      }
      const _new = {
        diagnosticStartDate: this.newData.diagnosticStartDate
      }
      const props = [
        {
          field: 'textFields.diagnosticDate',
          prop: 'diagnosticStartDate'
        }
      ]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    }
  },
  computed: {
    repairProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'DiagnosticDate',
          props: {
            'disabled': this.disabled || !this.newData.diagnosticStartDate
          },
          events: {
            'click': this.setChangedDialogItems
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    }
  },
  mounted() {
    const d = this.repairProp
    d.diagnosticStartDate = d.diagnosticStartDate ? d.diagnosticStartDate.replaceAll(/T.*$/g, '') : ''
    this.newData = {
      isAllowedToContinue: d.isAllowedToContinue,
      diagnosticStartDate: String(d.diagnosticStartDate)
    }
    if (this.$parent.isRole(['SUPERVISOR', 'CLIENT'])) {
      StringUtil.ifEmpty(this.newData.diagnosticStartDate, () => this.newData.diagnosticStartDate = this.$t('textFields.notSetYet'))
    }
  }
}
</script>
<style>

</style>