<template>
  <v-container fluid>
      <v-row
      dense>
          <v-col>
            <p class="process-repair-tab-section-title">{{$t(`pages.remoteSupport.${$parent.isRepairStatus(['WAITING_COMPLETION']) ? 'repairWaitingCompletion' : 'repairCompleted'}`)}}</p>
          </v-col>
      </v-row>
      <v-row 
      dense 
      class="my-10">
        <v-col class="d-flex justify-center align-center">
          <process-repair-quick-btns
          :config="getQuickBtnsConfig"
          :loading.sync="isLoading"
          on-success-repair-status-message="dialogs.messages.success.completeRemoteSupportRequest"/>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
export default {
  name: 'WaitingCompletionTabRemoteSupport',
  components: {
    ProcessRepairQuickBtns
  },
  data() {
    return {
      isLoading: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  methods: {
  },
  computed: {
    getQuickBtnsConfig() {
      return [
        {
          name: 'Complete',
          props: {
            'disabled': this.disabled || this.$parent.isNotRole(['ADMIN', 'SERVICE'])
          },
          events: 'AsContinue'
        }
      ]
    }
  }
}
</script>
<style>

</style>