var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDataReady)?_c('v-container',{attrs:{"fluid":""}},[(_vm.$parent.isRole(['SUPERVISOR', 'CLIENT']))?_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"process-repair-tab-section-subtitle text-center"},[_vm._v(_vm._s(_vm.$t('pages.repair.partsAreInServiceOptional', {'0': _vm.$parent.isRole(['SUPERVISOR']) ? 'the' : 'your'})))])])],1):_vm._e(),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[(_vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('v-col',[_c('p',{staticClass:"process-repair-tab-section-title"},[_vm._v(_vm._s(_vm.$t('pages.repair.partsInService')))])]):(_vm.$parent.isRole(['SUPERVISOR', 'CLIENT']))?_c('v-col',[_c('p',{staticClass:"process-repair-tab-section-title"},[_vm._v(_vm._s(_vm.$t('pages.repair.partsInfo')))])]):_vm._e()],1),_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.newData.repairParts,"number-pages":"","page":_vm.page,"size":-1,"items-per-page-options":[-1],"total-elements":-1,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('previewable-image',{staticClass:"py-2",attrs:{"height":"65","width":"50"},model:{value:(item.imageUrl),callback:function ($$v) {_vm.$set(item, "imageUrl", $$v)},expression:"item.imageUrl"}})]}},{key:"item.repairStatus",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && !_vm.isDiagnosticStatus(item, ['IT_IS_WORKING']) && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-autocomplete',{attrs:{"normal":"","itemText":"value","itemValue":"key","label":_vm.$t('textFields.selectStatus'),"items":_vm.repairStatuses,"disabled":_vm.disabled || item.diagnosticInfo.status === 'IT_IS_WORKING' || item.repairStatus === 'REJECTED',"required":""},model:{value:(item.repairStatusObj),callback:function ($$v) {_vm.$set(item, "repairStatusObj", $$v)},expression:"item.repairStatusObj"}}):_c('span',[_vm._v(" "+_vm._s(item.repairStatusObj ? _vm.$t(item.repairStatusObj.value) : '')+" ")])]}},(_vm.$parent.isRole(['ADMIN', 'SERVICE']))?{key:"item.repairComment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[(!_vm.disabled && !_vm.isDiagnosticStatus(item, ['IT_IS_WORKING']) && _vm.isRepairApprovalStatus(item, ['APPROVED']))?_c('text-area',{attrs:{"solo-normal":"","disabled":item.diagnosticInfo.status === 'IT_IS_WORKING',"no-resize":""},model:{value:(item.repairComment),callback:function ($$v) {_vm.$set(item, "repairComment", $$v)},expression:"item.repairComment"}}):_c('span',[_vm._v(" "+_vm._s(item.repairComment)+" ")])],1)]}}:null,(_vm.$parent.isRole(['ADMIN', 'SERVICE']))?{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isRepairApprovalStatus(item, ['APPROVED']) && !_vm.isDiagnosticStatus(item, ['IT_IS_WORKING']))?_c('div',{staticClass:"d-flex justify-start"},[_c('primary-btn',{staticClass:"mx-2",attrs:{"loading":_vm.isLoading,"label":_vm.$t('btns.save'),"color":"#4285f4","disabled":_vm.disabled || !item.repairStatusObj,"small-text":"","width":50,"height":30},on:{"click":function($event){return _vm.setChangedDialogItemsBySave(item)}}})],1):_vm._e()]}}:null],null,true)})],1)],1)],1),(_vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('v-row',{staticClass:"mt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('p',{staticClass:"text-center",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t('common.pressContinueWhenReady'))+" ")]),_c('process-repair-quick-btns',{attrs:{"loading":_vm.isLoading,"config":_vm.getQuickBtnsConfig,"on-success-repair-status-message":"dialogs.messages.success.toTransitBackStep"},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('primary-btn',{attrs:{"loading":_vm.isLoading,"width":120,"height":50,"color":"#4285f4","disabled":_vm.disabled || !_vm.isFormValid,"label":_vm.$t('btns.saveAll')},on:{"click":_vm.setChangedDialogItemsBySaveAll}})],1)],1):_vm._e(),_c('export-xlsx-row',{staticClass:"px-4 mt-4",attrs:{"width":150,"height":50,"disabled":_vm.newData.repairParts.length === 0,"loading":_vm.isLoading,"name":"export.title.repairTab","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }