<template>
  <v-select
  v-model="model"
  :items="langs"
  item-text="label"
  item-value="key"
  return-object
  >
    <template v-slot:item="{item}">
      <v-row dense>
        <v-col cols="10">
          {{item.label}}
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center">
          <v-img :src="item.src" width="50" contain/>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<script>
export default {
  props: {
    value: Object
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      langs: [
        {
          label: 'English',
          key: 'en',
          src: require('@/assets/flags/usa.png')
        }, {
          label: 'Español',
          key: 'es',
          src: require('@/assets/flags/spain.png')
        }
      ]
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>