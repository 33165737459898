var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('main-data-table',{attrs:{"number-pages":"","headers":_vm.getHeaders(),"items":_vm.items,"page":_vm.page,"size":_vm.size,"loading":_vm.isLoading,"onUpdateSize":_vm.tryToFetchRemoteSupport,"onPerviousBtnClick":_vm.tryToFetchRemoteSupport,"onNextBtnClick":_vm.tryToFetchRemoteSupport,"total-elements":_vm.totalElements},on:{"update:page":function($event){_vm.page=$event},"update:size":function($event){_vm.size=$event}},scopedSlots:_vm._u([{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.updatedAt",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, {'0': _vm.getTimeZoneInNumber(new Date())}))+" ")]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.contact.phone)+" ")])]}},(_vm.getRole !== 'CLIENT')?{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.clientName)+" ")])]}}:null,{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.contact.firstName.concat(' ').concat(item.contact.lastName))+" ")])]}},{key:"item.email",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({class:("main-data-table-row " + ((index % 2) === 0 ? 'main-data-table-row-color-fill' : '')),staticStyle:{"cursor":"pointer"},on:{"click":function($event){item.isEmailExpanded = !item.isEmailExpanded}}},'td',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(item.contact.email)+" ")]),(item.isEmailExpanded)?_c('div',_vm._l((item.contact.backupEmails),function(e,i){return _c('v-row',{key:i,attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('span',[_vm._v(_vm._s(e.value))])])],1)}),1):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.isEmailExpanded ? 'pages.remoteSupport.toolTipClickToCollapse' : 'pages.remoteSupport.toolTipClickToExpand')))])])]}},{key:"item.description",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({class:("main-data-table-row " + ((index % 2) === 0 ? 'main-data-table-row-color-fill' : '')),staticStyle:{"cursor":"pointer"},on:{"click":function($event){item.isDescriptionExpanded = !item.isDescriptionExpanded}}},'td',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(item.isDescriptionExpanded ? item.description : item.shortDescription)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.isDescriptionExpanded ? 'pages.remoteSupport.toolTipClickToCollapse' : 'pages.remoteSupport.toolTipClickToExpand')))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.createdAt))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateNewYorkFormat(item.updatedAt))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.statusObj.value))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('route-btn',{attrs:{"label":"btns.viewDetails","href":("/remote-support/process/" + (item.id))}})]}}],null,true)})],1)],1),_c('export-xlsx-row',{staticClass:"px-4 mt-4",attrs:{"width":150,"height":50,"disabled":_vm.items.length === 0,"loading":_vm.isLoading,"name":"export.title.remoteSupport","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }