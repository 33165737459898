<template>
  <div
  :class="`d-flex justify-center align-center ${box ? `${getColor} lighten-4` : ''}`"
  :style="box ? 'height: 50px; width: 100%;' : ''">
    <span v-if="status === 'APPROVED'" :class="`${getColor}--text`" style="font-weight: 300;">
      {{$t('initialStatuses.approved')}}
    </span>
    <span v-else-if="status === 'REJECTED'" :class="`${getColor}--text`" style="font-weight: 300;">
      {{$t('initialStatuses.rejected')}}
    </span>
    <span v-else-if="status === 'WAITING_APPROVAL'" :class="`${getColor}--text`" style="font-weight: 300;">
      {{$t('initialStatuses.waitingApproval')}}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    },
    box: {
      type: Boolean,
      default: () => {
        return null
      }
    }
  },
  computed: {
    getColor() {
      switch(this.status) {
        case 'REJECTED': return 'red'
        case 'WAITING_APPROVAL': return 'amber'
        default: return 'green'
      }
    }
  }
}
</script>
<style>

</style>