<template>
  <div>
    <v-row v-for="(be, i) in emails" 
    :key="i"  
    dense 
    no-gutters 
    class="px-8 mt-4">
      <v-col>
        <text-field-email
        :label="$t('textFields.email')"
        v-model="be.value"
        required
        @click:append="$emit('remove', be)"
        append-icon="mdi-minus"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <v-btn
        @click="$emit('add')"
        icon>
        {{ $t('btns.addEmail') }}
          <v-icon color="blue">
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TextFieldEmail from '@/components/text-fields/TextFieldEmail'
export default {
  components: {
    TextFieldEmail
  },
  props: {
    emails: {
      type: Array,
      required: true
    }
  }
}
</script>
<style>

</style>