<template>
  <div 
  class="d-flex justify-start">  
    <primary-btn
    @click="emitClick('REJECTED')"
    :label="$t('btns.reject')"
    color="#ff3547"
    class="mx-2"
    :disabled="disabled || currentStatus === 'REJECTED'"
    small-text
    :loading="loading"
    :width="100"
    :height="30"/>
    <primary-btn
    @click="emitClick('APPROVED')"
    :label="$t('btns.approve')"
    color="#4285f4"
    class="mx-2"
    :disabled="disabled || currentStatus === 'APPROVED'"
    small-text
    :loading="loading"
    :width="100"
    :height="30"/>
  </div>
</template>
<script>
import PrimaryBtn from '@/components/btns/PrimaryBtn'
export default {
  components: {
    PrimaryBtn
  },
  props: {
    currentStatus: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  methods: {
    emitClick(s) {
      this.$emit('selected', s)
    }
  }
}
</script>
<style>

</style>