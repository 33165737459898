<template>
  <v-text-field
  v-model="model"
  :rules="rules"
  :disabled="disabled"
  @update:error="(e) => errorProp = e"
  hide-details="auto"
  :class="`sts-web-system-label ${disabled ? 'v-input--is-readonly' : ''}`"
  :append-icon="appendIcon"
  :readonly="readonly"
  :label="label"
  @keypress.enter="$emit('enter')">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
    <template #append>
      <v-btn 
      v-if="appendIcon"
      @click="$emit('click:append')"
      icon>
        <v-icon
        color="blue">
          {{appendIcon}}
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    label: String,
    icon: Boolean,
    required: Boolean,
    value: String,
    disabled: Boolean,
    error: Boolean,
    appendIcon: String,
    readonly: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    },
    errorProp: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('update:error', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true,
        (v) => {
          if(v && !!v && v.length > 0) {
            if (new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(v)) {
              return true
            }
            else return this.$t('invalidValue.invalidEmail')
          }

          return true
        }
      ]
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>