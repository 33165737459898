import SignIn from '@/pages/SignIn'
import StsDescription from '@/pages/StsDescription'
import StsDisclaimer from '@/pages/StsDisclaimer'
import ActiveRepairs from '@/pages/repair/ActiveRepairs'
import ClosedRepairs from '@/pages/repair/ClosedRepairs'
import ActionRequried from '@/pages/repair/ActionRequried'
import NewRepair from '@/pages/repair/NewRepair'
import GeneralReport from '@/pages/reports/GeneralReport'
import ContactList from '@/pages/contacts/ContactList'
import ProcessContact from '@/pages/contacts/ProcessContact'
import MailNotificationList from '@/pages/MailNotificationList'
import PartList from '@/pages/parts/PartList'
import ProcessPart from '@/pages/parts/ProcessPart'
import ProcessRepair from '@/pages/repair/ProcessRepair'
import NewPartRequest from '@/pages/part-request/NewPartRequest'
import PartRequests from '@/pages/part-request/PartRequests'
import NewRemoteSupport from '@/pages/remote-support/NewRemoteSupport'
import RemoteSupportList from '@/pages/remote-support/RemoteSupportList'
import ResetPassword from '@/pages/ResetPassword'
import NewAccountRequest from '@/pages/account-request/NewAccountRequest'
import AccountRequestDecision from '@/pages/account-request/AccountRequestDecision'
import ProcessPartRequest from '@/pages/part-request/ProcessPartRequest'
import ProcessRemoteSupport from '@/pages/remote-support/ProcessRemoteSupport'

export const navigationRoutes = [
  {
    path: 'login',
    name: 'SignIn',
    component: SignIn
  }, {
    path: 'sts-description',
    name: 'StsDescription',
    component: StsDescription
  }, {
    path: 'sts-disclaimer',
    name: 'StsDisclaimer',
    component: StsDisclaimer
  }, {
    path: 'active-repairs',
    name: 'ActiveRepairs',
    component: ActiveRepairs
  }, {
    path: 'closed-repairs',
    name: 'ClosedRepairs',
    component: ClosedRepairs
  }, {
    path: 'action-required',
    name: 'ActionRequried',
    component: ActionRequried
  }, {
    path: 'new-repair',
    name: 'NewRepair',
    component: NewRepair
  }, {
    path: 'reports/general',
    name: 'GeneralReport',
    component: GeneralReport
  }, {
    path: 'contacts',
    name: 'ContactList',
    component: ContactList
  }, {
    path: 'create/contact',
    name: 'ProcessContact',
    component: ProcessContact
  }, {
    path: 'edit/contact/:username',
    name: 'ProcessContact',
    component: ProcessContact
  }, {
    path: 'profile',
    name: 'ProcessContact',
    component: ProcessContact
  },{
    path: 'mail-notification',
    name: 'MailNotificationList',
    component: MailNotificationList
  }, {
    path: 'parts',
    name: 'PartList',
    component: PartList
  }, {
    path: 'create/part',
    name: 'ProcessPart',
    component: ProcessPart
  }, {
    path: 'edit/part/:id',
    name: 'ProcessPart',
    component: ProcessPart
  }, {
    path: 'process-repair/:id',
    name: 'ProcessRepair',
    component: ProcessRepair
  }, {
    path: 'part-request/new',
    name: 'NewPartRequest',
    component: NewPartRequest
  }, {
    path: 'part-request/list',
    name: 'PartRequests',
    component: PartRequests
  }, {
    path: 'remote-support/new',
    name: 'NewRemoteSupport',
    component: NewRemoteSupport
  }, {
    path: 'remote-support/list',
    name: 'RemoteSupportList',
    component: RemoteSupportList
  }, {
    path: 'remote-support/process/:id',
    name: 'ProcessRemoteSupport',
    component: ProcessRemoteSupport
  },{ 
    path: 'reset-password/:id',
    name: 'ResetPassword',
    component: ResetPassword
  }, {
    path: 'request-account/request',
    name: 'NewAccountRequest',
    component: NewAccountRequest
  }, {
    path: 'request-account/:decision/:id',
    name: 'AccountRequestDecision',
    component: AccountRequestDecision
  }, {
    path: 'process-part-request/:id',
    name: 'ProcessPartRequest',
    component: ProcessPartRequest
  }
]