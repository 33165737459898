<template>
  <v-text-field
  v-model="model"
  :rules="rules"
  :disabled="disabled"
  hide-details="auto"
  :readonly="readonly"
  :class="`sts-web-system-label ${disabled ? 'v-input--is-readonly' : ''}`"
  :label="$t('textFields.phone')">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    required: Boolean,
    value: String,
    disabled: Boolean,
    readonly: Boolean
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true,
        (v) => new RegExp(/^\+?[\d\s]{10,}$/).test(v) || this.$t('invalidValue._10DigitsAtLeast'),
      ]
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>