var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDataReady)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 mb-4",attrs:{"dense":"","no-gutters":""}},[_c('v-col',[(_vm.$parent.isRole(['SUPERVISOR']))?_c('p',{staticClass:"process-repair-tab-section-subtitle text-center"},[_vm._v(" "+_vm._s(_vm.$t('pages.repair.partsAreInService'))+" ")]):(_vm.$parent.isRole(['CLIENT', 'SUSTAINABILITY_MANAGER']))?_c('div',[_c('p',{staticClass:"process-repair-tab-section-subtitle text-center"},[_vm._v(" "+_vm._s(_vm.$t('pages.repair.partsAreInDiagnostic'))+" ")]),_c('p',{staticClass:"text-h6 text-end mr-10"},[_vm._v(" "+_vm._s(_vm.$t('headers.totalPrice'))+": "),_c('span',[_vm._v(_vm._s(_vm.getTotalPriceOfAll))])])]):_vm._e()])],1),(_vm.$parent.isRole(['SUPERVISOR']))?_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"process-repair-tab-section-title"},[_vm._v(_vm._s(_vm.$t('pages.repair.partsInfo')))])])],1):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[(_vm.$parent.isNotRole(['SUSTAINABILITY_MANAGER']))?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.isDataReady)?_c('main-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.newData.repairParts,"number-pages":"","set-image":"","page":_vm.page,"size":-1,"items-per-page-options":[-1],"total-elements":-1,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.serialNumber",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-value',{attrs:{"normal":""},model:{value:(item.serialNumber),callback:function ($$v) {_vm.$set(item, "serialNumber", $$v)},expression:"item.serialNumber"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.serialNumber)+" ")]):_vm._e()]}},{key:"item.serviceTag",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-value',{attrs:{"normal":""},model:{value:(item.serviceTag),callback:function ($$v) {_vm.$set(item, "serviceTag", $$v)},expression:"item.serviceTag"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.serviceTag)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-autocomplete',{attrs:{"label":_vm.$t('textFields.selectStatus'),"items":_vm.serviceStatuses,"normal":"","itemText":"value","itemValue":"key"},model:{value:(item.diagnosticInfo.statusObj),callback:function ($$v) {_vm.$set(item.diagnosticInfo, "statusObj", $$v)},expression:"item.diagnosticInfo.statusObj"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.statusObj ? _vm.$t(item.diagnosticInfo.statusObj.value) : '')+" ")]):_vm._e()]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-area',{attrs:{"solo-normal":"","width":"22rem","no-resize":""},model:{value:(item.diagnosticInfo.comment),callback:function ($$v) {_vm.$set(item.diagnosticInfo, "comment", $$v)},expression:"item.diagnosticInfo.comment"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.comment)+" ")]):_vm._e()],1)]}},{key:"item.diagnosticPrice",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-number',{attrs:{"normal":"","double":""},on:{"input":function($event){return _vm.calcTotalPriceOfItem(item.diagnosticInfo)}},model:{value:(item.diagnosticInfo.prices.diagnosticPrice),callback:function ($$v) {_vm.$set(item.diagnosticInfo.prices, "diagnosticPrice", $$v)},expression:"item.diagnosticInfo.prices.diagnosticPrice"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.prices.diagnosticPrice)+" ")]):_vm._e()]}},{key:"item.repairPrice",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-number',{attrs:{"normal":"","double":""},on:{"input":function($event){return _vm.calcTotalPriceOfItem(item.diagnosticInfo)}},model:{value:(item.diagnosticInfo.prices.repairPrice),callback:function ($$v) {_vm.$set(item.diagnosticInfo.prices, "repairPrice", $$v)},expression:"item.diagnosticInfo.prices.repairPrice"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.prices.repairPrice)+" ")]):_vm._e()]}},{key:"item.partsPrice",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-number',{attrs:{"normal":"","double":""},on:{"input":function($event){return _vm.calcTotalPriceOfItem(item.diagnosticInfo)}},model:{value:(item.diagnosticInfo.prices.partsPrice),callback:function ($$v) {_vm.$set(item.diagnosticInfo.prices, "partsPrice", $$v)},expression:"item.diagnosticInfo.prices.partsPrice"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.prices.partsPrice)+" ")]):_vm._e()]}},{key:"item.totalCost",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled && _vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('text-field-number',{attrs:{"normal":"","readonly":"","double":""},model:{value:(item.diagnosticInfo.totalCost),callback:function ($$v) {_vm.$set(item.diagnosticInfo, "totalCost", $$v)},expression:"item.diagnosticInfo.totalCost"}}):(_vm.disabled || _vm.$parent.isRole(['SUPERVISOR']))?_c('span',[_vm._v(" "+_vm._s(item.diagnosticInfo.totalCost)+" ")]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.$parent.isNotRole(['CLIENT']))?_c('v-row',{staticClass:"mt-5",attrs:{"dense":""}},[_c('v-col',{staticClass:"mr-13"},[_c('p',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.$t('headers.diagnosticPriceTotal'))+": "),_c('strong',[_vm._v(_vm._s(_vm.getTotalOfPrices('diagnosticPrice')))])]),_c('p',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.$t('headers.repairPriceTotal'))+": "),_c('strong',[_vm._v(_vm._s(_vm.getTotalOfPrices('repairPrice')))])]),_c('p',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.$t('headers.partsPriceTotal'))+": "),_c('strong',[_vm._v(_vm._s(_vm.getTotalOfPrices('partsPrice')))])]),_c('p',{staticClass:"text-h6 text-end"},[_vm._v(" "+_vm._s(_vm.$t('headers.totalPrice'))+": "),_c('span',[_vm._v(_vm._s(_vm.getTotalPriceOfAll))])])])],1):_vm._e(),(_vm.$parent.isRole(['ADMIN', 'SERVICE']))?_c('v-row',{staticClass:"mt-5",attrs:{"dense":""}},[_c('v-col',[_c('process-repair-quick-btns',{attrs:{"loading":_vm.isLoading,"config":_vm.getQuickBtnsConfig,"on-success-repair-status-message":"dialogs.messages.success.proceedingToNextStepSuccessfully"},on:{"update:loading":function($event){_vm.isLoading=$event}}})],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.$parent.isNotRole(['SUSTAINABILITY_MANAGER']))?_c('export-xlsx-row',{staticClass:"px-4 mt-4",attrs:{"width":150,"height":50,"disabled":_vm.newData.repairParts.length === 0,"loading":_vm.isLoading,"name":"export.title.repairDiagnosticsTab","exporter":_vm.getExporter},on:{"update:loading":function($event){_vm.isLoading=$event}}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }