<template>
  <v-container fluid>
      <v-row
      v-if="$parent.isRole(['SUPERVISOR', 'ADMIN']) && $parent.isRepairStatus(['DONE'])"
      dense>
          <v-col>
            <p class="process-repair-tab-section-title">{{$t('pages.partRequest.repairCompleted')}}</p>
          </v-col>
      </v-row>
      <v-row
      v-else>
        <v-col>
          <p class="process-repair-tab-section-title">{{$t('pages.partRequest.completedRepairDetails')}}</p>
        </v-col>
      </v-row>
      <v-row
      v-if="$parent.isNotRole(['SUPERVISOR', 'ADMIN'])"
      dense>
          <v-col>
            
            <p class="process-repair-tab-section-subtitle">{{$t('pages.repair.expectDeliveryConfirmation', {'0': getDateNewYorkFormat(getEstimateDeliveryDate)})}}</p>
            <p 
            v-if="$parent.isRole(['CLIENT'])"
            class="process-repair-tab-section-subtitle font-weight-bold">
              {{$t('pages.repair.pleaseConfirmDeliveryCompletedTab')}}
            </p>
          </v-col>
      </v-row>
      <v-row 
      dense 
      class="my-10">
        <v-col class="d-flex justify-center align-center">
          <process-repair-quick-btns
          :config="getQuickBtnsConfig"
          :loading.sync="isLoading"
          on-success-repair-status-message="dialogs.messages.success.confirmedPackageReceivedBackSuccessfully"/>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DateUtil from '@/utils/DateUtil'
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
export default {
  name: 'WaitingConfirmationPartRequest',
  components: {
    ProcessRepairQuickBtns
  },
  data() {
    return {
      isLoading: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  methods: {
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    openSuccessDialog(message) {
      this.$successDialog.open(null, message, null)
    },
    openFailedDialog(message) {
      this.$failedDialog.open(null, message, null)
    }
  },
  computed: {
    getEstimateDeliveryDate() {
      return this.partRequestProp.onewayShipping.estimateDeliveryDate.replace(/T.+/, '').concat('T00:00:00.000+00:00')
    },
    partRequestProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'ConfrimDelivery',
          props: {
            'disabled': this.disabled || this.$parent.isRole(['SUPERVISOR', 'SUSTAINABILITY_MANAGER'])
          },
          events: 'AsContinue'
        }
      ]
    }
  }
}
</script>
<style>

</style>