import { post, put } from '@/services/http'
import { getUserToken } from '@/services/authService'

export function updateServiceCategoriesByPartId(pId, categories) {
  return put(`/api/v1/parts/update-service-category-part-mapping?partId=${pId}`, categories, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function fetchServiceCategoriesByKeys(keys) {
  return post(`/api/v1/parts/fetch/service-categories`, keys, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}